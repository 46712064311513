<template>
  <div class="tsk m7">
    <div class="tskhead">
      <div class="tskleft">
        <b-checkbox v-model="person.isChecked" v-if="showCheckBox"></b-checkbox>
        <div class="icon">
          <font-awesome-icon
            :icon="['fal', personTypeIcon]"
            size="lg"
            :class="iconClass"
          ></font-awesome-icon>
        </div>
      </div>
      <div class="tskbody isPointer"  @click="personOpen()">
        <span>
          <div v-if="person.persontype !== 'Team'" class="txtsm">
            <span v-if="person.personrole.contact" class="primary"
              >• Contact
            </span>
            <span v-if="person.personrole.friend" class="secondary"
              >• Friend
            </span>
            <span v-if="person.personrole.prospect" class="success"
              >• Prospect
            </span>
            <span v-if="person.personrole.client" class="danger"
              >• Client
            </span>
            <span v-if="person.personrole.supplier" class="warning"
              >• Supplier
            </span>
          </div>
          <p v-if="person.persontype === 'Team'" class="txtsm">
            <span class="primary">• Team</span>
          </p>
        </span>
        <h2 v-if="person.persontype === 'Person'">
          {{ person.firstname }} {{ person.lastname }}
        </h2>
        <h2 v-if="person.persontype === 'Organisation'">
          {{ person.firstname }}
        </h2>
        <h2 v-if="person.persontype === 'Team'">{{ person.firstname }}</h2>
        <p v-if="person.oneliner !== ''" class="txtsm">{{ person.oneliner }}</p>
        <p v-if="person.oneliner === ''" class="txtsm">
          {{ person.occupation }}
        </p>
      </div>
      <div class="tskright">
        <!-- <div v-if="showOpen" class="iconbutton" @click="personOpen()">
          <font-awesome-icon
            :icon="['fal', 'eye']"
            size="lg"
          ></font-awesome-icon>
        </div> -->
        <div v-if="showSelect" class="iconbutton" @click="personSelect()">
          <font-awesome-icon
            :icon="['fal', 'arrow-alt-right']"
            size="lg"
          ></font-awesome-icon>
        </div>
      </div>
    </div>
    <div class="tskrow">
      <div></div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "../event-bus.js";
import store from "../store";
export default {
  name: "PeopleList",
  store,
  props: {
    person: Object,
    pindex: Number,
    showOpen: Boolean,
    showSelect: Boolean,
    showCheckBox: Boolean,
    callBus: String
  },
  data() {
    return {
      personTypeIcon: "user-circle",
      user: store.state.user,
      isSelf: false,
      iconClass: "icon-person"
    };
  },
  created() {
    if (this.person.persontype === "") {
      this.person.persontype = "Person";
    }
    // user-crown
    switch (this.person.persontype) {
      case "Organisation":
        this.personTypeIcon = "building";
        this.iconClass = "icon-org";
        break;
      case "Team":
        this.personTypeIcon = "users";
        this.iconClass = "icon-team";
        break;
      default:
        this.personTypeIcon = "user-circle";
        this.iconClass = "icon-person";
        break;
    }
    // if (this.person.original === this.user.sub) {
    //   this.isSelf = true;
    //   if (this.person.firstname === "") {
    //     this.person.firstname = this.user.subname;
    //   }
    //   this.personTypeIcon = "user-crown";
    //   this.iconClass = "icon-self";
    // }
  },
  methods: {
    personOpen() {
      EventBus.$emit("person_open", this.person);
    },
    personSelect() {
      EventBus.$emit(this.callBus, this.person);
    }
  }
};
</script>

<style>
.icon-person {
  color: yellowgreen;
}
.icon-org {
  color: teal;
}
.icon-team {
  color: peru;
}
.icon-self {
  color: red;
}
</style>
