import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: {
      sub: "",
      subname: "",
      email: "",
      admin: ""
    },
    team: {
      id: '',
      name: 'Private',
      list: [],
      self: {}
    },
    settings: {
      daystart: 500,
      dayend: 1800
    },
    signedIn: false,
    naviShow: true,
    openPersonIndex: -1,
  },
  getters: {},
  mutations: {
    upUser(state) {
      
    }
  },
  actions: {
    setSignedIn(context, payload) {
      context.state.signedIn = payload;
    },
    setUser(context, payload) {
      context.state.user = payload;
    },
    setTeam(context, payload) {
      context.state.team = payload;
    },
    setNavi(context, payload) {
      context.state.naviShow = payload;
    }
  }
});
