<template>
  <div class="contentheader">
    <div>
      <div class="iconbutton white" @click="showHideNavi()">
        <font-awesome-icon
          :icon="['fal', 'bars']"
          size="sm"
        ></font-awesome-icon>
      </div>
    </div>
    <div class="head">{{ HeadTitle }}</div>
    <div class="navbuttons isFlex isFlexAlign isFlexJustify">
      <div class="iconbutton mr11 isPointer" @click="taskOpenHead()" v-if="activeTask">
        <font-awesome-icon :icon="['fal', 'stop-circle']" size="lg"></font-awesome-icon>
      </div>
      <router-link tag="div" to="/inbox" class="navi-button">
      <div class="isFlex isFlexAlign">
        <font-awesome-icon
            :icon="['fal', 'inbox']"
            size="lg"></font-awesome-icon>
          <div v-if="unreadmess !== 0" class="rounded50p redbg ml11">{{ unreadmess }}</div>
      </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import store from "../store";
import { EventBus } from "../event-bus.js";
import ModalTask from "@/modals/ModalTask.vue";
// import { AmplifyEventBus } from "aws-amplify-vue";
import { windowSplit2 } from "../mixins/windowSplit.js";
// import { dbFunctions } from "../mixins/dbMixins.js";
export default {
  name: "Header",
  mixins: [windowSplit2],
  data() {
    return {
      TeamLive: localStorage.getItem("TeamLive"),
      TeamLiveName: localStorage.getItem("TeamLiveName"),
      TeamLiveColour: localStorage.getItem("TeamLiveColour"),
      TeamList: [],
      HeadTitle: "DNE",
      signedIn: false,
      activeTask: false,
      unreadmess: 0,
      ActiveTask: {},
    };
  },
  async created() {
    EventBus.$on("show_alert", data => {
      this.showAlert(data);
    });
    EventBus.$on("unread_count", data => {
      this.unreadmess = data;
    });
    EventBus.$on("has_active_task", data => {
      if (data !== null) {
        this.activeTask = true;
        this.ActiveTask = data;
      } else {
        this.activeTask = false;
        this.ActiveTask = {};
      }
    });
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
   },
  methods: {
    ...mapActions([
      "setTeam" // map `this.increment()` to `this.$store.dispatch('increment')`
    ]),
    async loadTeams() {
      // this.TeamList = await this.getTeams();
    },

    showHideNavi() {
      EventBus.$emit("showHideNavi", {});
    },
    changeTeam(obj) {
      localStorage.setItem("TeamLive", obj._id);
      localStorage.setItem("TeamLiveName", obj.firstname);
      this.TeamLive = obj._id;
      this.TeamLiveName = obj.firstname;
      const team = {
        id: obj._id,
        name: obj.firstname,
        list: [],
        self: {}
      };
      for (const t of this.TeamList) {
        if (t._id === obj._id) {
          team.list = t.inteam;
        }
        for (const ita of t.inteam) {
          if (ita.userid === store.state.user.sub) {
            team.self = ita;
          }
        }
      }

      this.$store.dispatch("setTeam", team);
      EventBus.$emit("team_changed", this.task);
    },
    JoinTeam1() {
      this.$buefy.dialog.prompt({
        message: `Invite e-mail...`,
        confirmText: "Proceed",
        inputAttrs: {
          type: "email",
          placeholder: "e.g. you@youremail.com"
        },
        onConfirm: value => this.JoinTeam2(value)
      });
    },
    JoinTeam2(email) {
      this.$buefy.dialog.prompt({
        message: `Invite Code...`,
        confirmText: "Join Team",
        inputAttrs: {
          placeholder: "xxxxxx-xxxxxxxxx-xxxxxx"
        },
        onConfirm: value => {
          this.doInvite(email, value);

          // this.$buefy.toast.open(`Your name is: ${value}`)
        }
      });
    },

    async doInvite(email, code) {
      let sendq = true;
      const data = {
        query: {
          useremail: email,
          inviteid: code
        }
      };
      const sendinv = await this.livePostPromise(
        "acceptinvite",
        "notify",
        data
      );
      if (sendinv.status === "success") {
        EventBus.$emit("show_alert", {
          title: "Success",
          message: "You have successfully join the team",
          type: "is-success",
          hasIcon: true
        });
      } else {
        EventBus.$emit("show_alert", {
          title: "Error",
          message:
            "An error has occured. Please try again or contact the team admin",
          type: "is-danger",
          hasIcon: true
        });
      }
    },

    showAlert(obj) {
      // this.showAlert({title: 'Alert', message: 'My message', type: 'is-info', hasIcon: true, icon:'exclamation-triangle', iconPack:'fa'})
      // this.showAlert({title: 'Error', message: 'My message', type: 'is-danger', hasIcon: true, icon:'times-circle', iconPack:'fa'})
      if (!obj.type) {
        obj.type = "is-info";
      }
      if (!obj.icon) {
        obj.icon = "exclamation-triangle";
      }
      if (!obj.iconPack) {
        obj.iconPack = "fa";
      }
      if (!obj.hasIcon) {
        obj.hasIcon = false;
      }
      this.$buefy.dialog.alert({
        title: obj.title,
        message: obj.message,
        type: obj.type,
        hasIcon: obj.hasIcon,
        icon: obj.icon,
        iconPack: obj.iconPack
      });
    },

    async taskOpenHead() {
      const task = {
        _id: this.ActiveTask.taskid,
      }
      let useFull = true;
      let useCanCancel = false;
      const inw = document.body.clientWidth;
      if (inw > 750) {
        useFull = false;
        useCanCancel = true;
      }
      this.modal = this.$buefy.modal.open({
        parent: this,
        component: ModalTask,
        hasModalCard: true,
        fullScreen: useFull,
        canCancel: useCanCancel,
        width: 700,
        props: {
          data: task, // this is the row data that gets sent to the modal
          callBus: "Modal_Goals_Task" // name of the listner for the data coming in from the modal
        },
        events: {
          taskUpdated: value => {}
        }
      });
    },

    beforeDestroy() {}
  }
};
</script>
