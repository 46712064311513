import store from "../store";
import { EventBus } from "../event-bus.js";
import ObjectID from "bson-objectid";
import ModalPeopleList from "@/components/ModalPeopleList.vue";
import { dbFunctions } from "../mixins/dbMixins.js";
import array from "lodash/array";
import debounce from "lodash/debounce";

export const goalsMixins = {
  mixins: [dbFunctions],
  data() {
    return {
      user: store.state.user,
      GoalList: [],
      ShowCompleted: false,
      searchField: "",
      ActiveGoal: {},
      ActiveGoalIndex: -1,
      ActiveGoalIndex2: -1,
      ActiveGoallevel: "parent",
      GoalMode: "edit",
      TeamLive: localStorage.getItem("TeamLive"),
      TeamLiveName: localStorage.getItem("TeamLiveName"),
      TeamLiveColour: localStorage.getItem("TeamLiveColour"),
      expanded: "",
      displayMode: "goal",

      TaskMode: "edit",
      TaskLevel: "first",
      ActiveTask: {},

      newGoal: {},
      newGoalRow: {},

      sub: localStorage.getItem("sub"),
    };
  },

  created() {
    EventBus.$on("goals_close_right", data => {
      this.closeRight(data);
    });
    EventBus.$on("goals_reload", data => {
      const ind = data;
      if (this.GoalMode === "new") {
        const newrow = {
          _id: ind._id,
          goaltitle: ind.goaltitle,
          availableto: ind.availableto,
          client: ind.client,
          clientid: ind.clientid,
          duedate: ind.duedate,
          goalcolor: ind.goalcolor,
          goaltype: ind.goaltype,
          expanded: false,
          loading: false,
          useStyle: { borderLeft: `5px solid ${ind.goalcolor}` }
        };
        this.GoalList = [];
        this.GoalList[this.ActiveGoalIndex].children.push(newrow);
      }
      if (this.ActiveGoallevel === "parent") {
        this.GoalList[this.ActiveGoalIndex].goaltitle = ind.goaltitle;
        this.GoalList[this.ActiveGoalIndex].availableto = ind.availableto;
        this.GoalList[this.ActiveGoalIndex].client = ind.client;
        this.GoalList[this.ActiveGoalIndex].clientid = ind.clientid;
        this.GoalList[this.ActiveGoalIndex].duedate = ind.duedate;
        this.GoalList[this.ActiveGoalIndex].goalcolor = ind.goalcolor;
        this.GoalList[this.ActiveGoalIndex].goaltype = ind.goaltype;
        this.GoalList[this.ActiveGoalIndex].useStyle = {
          borderLeft: `5px solid ${ind.goalcolor}`
        };
      }
      if (this.ActiveGoallevel === "child") {
        this.GoalList[this.ActiveGoalIndex].children[
          this.ActiveGoalIndex2
        ].goaltitle = ind.goaltitle;
        this.GoalList[this.ActiveGoalIndex].children[
          this.ActiveGoalIndex2
        ].availableto = ind.availableto;
        this.GoalList[this.ActiveGoalIndex].children[
          this.ActiveGoalIndex2
        ].client = ind.client;
        this.GoalList[this.ActiveGoalIndex].children[
          this.ActiveGoalIndex2
        ].clientid = ind.clientid;
        this.GoalList[this.ActiveGoalIndex].children[
          this.ActiveGoalIndex2
        ].duedate = ind.duedate;
        this.GoalList[this.ActiveGoalIndex].children[
          this.ActiveGoalIndex2
        ].goalcolor = ind.goalcolor;
        this.GoalList[this.ActiveGoalIndex].children[
          this.ActiveGoalIndex2
        ].goaltype = ind.goaltype;
        this.GoalList[this.ActiveGoalIndex].children[
          this.ActiveGoalIndex2
        ].useStyle = { borderLeft: `5px solid ${ind.goalcolor}` };
      }
      // this.GoalList = [];
      // this.getGoals();
    });
    EventBus.$on("goals_task_saved", data => {
      if (this.TaskMode === "edit") {
        const itd = data.data;
        this.ActiveTask.tasktext = itd.tasktext;
        this.ActiveTask.grindminutes = itd.grindminutes;
        this.ActiveTask.workflow = itd.workflow;
        this.ActiveTask.taskstartdate = itd.taskstartdate;
        this.ActiveTask.tasktilldate = itd.tasktilldate;
      }
    });
    this.getGoals();
  },

  methods: {
    async getGoals() {
      this.GoalList = [];
      const glin = await this.axiosget("/goals/parents");
      const gl = glin.rows;

      const childarray = [];
      for (const g of gl) {
        // set style
        if (g.goalcolor === "") {
          g.goalcolor = "#CCCCCC";
        }
        g.ismine = false;
        if (g.sub === this.sub) {
          g.ismine = true;
        } else {
          const usertrue = array.findLastIndex(g.availableto, { '_id': this.sub });
          if (usertrue !== -1) {
            g.ismine = true;
          }
        }
        g.loading = false;
        g.expanded = false;
        g.deleted = false;
        g.children = [];
        g.tasks = [];
        g.useStyle = { borderLeft: `5px solid ${g.goalcolor}` };
        // processes parents
        if (g.childof === "") {
          this.GoalList.push(g);
        } else {
          childarray.push(g);
        }
      }
    },

    doSearch: debounce(function() {
      this.getGoals();
    }, 2000),

    createGoal(mode, type, row, index) {
      if (mode === "inline") {
        this.newGoalRow = this.GoalList[index];
      }
      let insat = -1;
      let level = "parent";
      this.$buefy.dialog.prompt({
        message: `Quick descripion`,
        inputAttrs: {
          placeholder: "Fix the water shortage in the world"
        },
        trapFocus: true,
        onConfirm: async value => {
          this.newGoal = { ...this.goal };
          const id = new ObjectID();
          const idout = id.str;
          this.newGoal._id = idout;
          this.newGoal.sub = this.user.sub;
          this.newGoal.subname = this.user.subname;
          this.newGoal.goaltitle = value;
          this.newGoal.tasks = [];
          this.newGoal.children = [];
          this.newGoal.goaltype = type;
          if (mode === "inline") {
            this.newGoal.childof = row._id;
            this.newGoal.availableto = row.availableto;
            this.newGoal.goalcolor = row.goalcolor;
            this.newGoal.client = row.client;
            this.newGoal.clientid = row.clientid;
            this.newGoal.useStyle = {
              borderLeft: `5px solid ${this.newGoal.goalcolor}`
            };
            level = "child";
            this.newGoalRow.children.push(this.newGoal) - 1;
          } else {
            if (this.TeamLive !== "") {
              const newAvail = {
                _id: this.TeamLive,
                firstname: this.TeamLiveName
              };
              this.newGoal.availableto = [];
              insat = this.newGoal.availableto.push(newAvail);
            }
            this.newGoal.useStyle = { borderLeft: `5px solid #CCCCCC` };
            insat = this.GoalList.push(this.newGoal);
          }
          await this.updata("goals", this.newGoal, "none", {}, false);
        }
      });
    },

    createGoalOld(mode, type, row, index) {
      this.$buefy.dialog.prompt({
        message: `Quick descripion`,
        inputAttrs: {
          placeholder: "Fix the water shortage in the world"
        },
        trapFocus: true,
        onConfirm: async value => {
          const newGoal = { ...this.goal };
          const id = new ObjectID();
          const idout = id.str;
          newGoal._id = idout;
          newGoal.sub = this.user.sub;
          newGoal.subname = this.user.subname;
          newGoal.goaltitle = value;
          // newGoal.goaltitle = '';
          newGoal.goaltype = type;
          if (mode === "inline") {
            newGoal.childof = row._id;
            newGoal.availableto = row.availableto;
            newGoal.goalcolor = row.goalcolor;
            newGoal.client = row.client;
            newGoal.clientid = row.clientid;
          } else {
            if (this.TeamLive !== "") {
              const newAvail = {
                _id: this.TeamLive,
                firstname: this.TeamLiveName
              };
              newGoal.availableto = [];
              newGoal.availableto.push(newAvail);
            }
          }
        }
      });
    },

    openGoal(row, index, index2, mode, level) {
      this.GoalMode = mode;
      this.ActiveGoal = row;
      this.ActiveGoalIndex = index;
      this.ActiveGoalIndex2 = index2;
      this.ActiveGoallevel = level;
      this.displayMode = "goal";
      this.w.right = true;
    },

    async openChildren2(row, i) {
      row.loading = true;
      // get children: start
      row.children = [];
      row.tasks = [];
      const query = { deleted: false, childof: row._id };
      const options = {
        fields: {
          goaltitle: 1,
          duedate: 1,
          availableto: 1,
          goalcolor: 1,
          goaltype: 1,
          clientid: 1,
          client: 1,
          childof: 1,
          childorder: 1
        },
        sort: [["childorder", "asc"]]
      };
      const gl = await this.getData(
        "goals",
        query,
        options,
        "none",
        "none",
        "searchextended"
      );
      for (const g of gl) {
        if (g.goalcolor === "") {
          g.goalcolor = "#CCCCCC";
        }
        g.loading = false;
        g.expanded = false;
        g.deleted = false;
        g.tasks = [];
        g.useStyle = { borderLeft: `5px solid ${g.goalcolor}` };
        row.children.push(g);
      }
      // get children: end

      // get tasks: start
      let tq = {
        $and: [
          { deleted: false },
          { goalid: row._id },
          { workflow: { $ne: "Completed" } }
        ]
      };
      if (this.ShowCompleted) {
        tq = {
          $and: [{ deleted: false }, { goalid: row._id }]
        };
      }
      const opts = {
        fields: {
          tasktext: 1,
          durantiontype: 1,
          goalid: 1,
          deleted: 1,
          estblocks: 1,
          estminutes: 1,
          grindhours: 1,
          grindminutes: 1,
          grindmonths: 1,
          grindweeks: 1,
          grindyears: 1,
          grinddays: 1,
          estblocks: 1,
          esthours: 1,
          estminutes: 1,
          workflow: 1,
          useicon: 1,
          tasktilldate: 1,
          taskstartdate: 1,
          tasktilldatets: 1,
          taskstartdatets: 1,
          childorder: 1,
          workflow: 1,
          steps: 1,
          goalid: 1,
          goalname: 1,
          goalcolor: 1
        },
        sort: [["childorder", "asc"]]
      };
      const TaskList = await this.getData(
        "tasks",
        tq,
        opts,
        "tasklist",
        "none",
        "searchextended"
      );
      for (const t of TaskList) {
        t.taskTypeIcon = "check-square";
        if (t.durantiontype === "daily") {
          t.taskTypeIcon = "repeat";
        }
        t.editWorkFlow = false;
        t.editBlocks = false;
        row.tasks.push(t);
      }
      // get tasks: end

      row.loading = false;
      row.expanded = true;
    },

    closeChildren2(row, i) {
      row.expanded = false;
    },

    async openSubChildren2(row, i) {
      row.tasks = [];
      row.loading = true;
      // get tasks: start
      let tq = {
        $and: [
          { deleted: false },
          { goalid: row._id },
          { workflow: { $ne: "Completed" } }
        ]
      };
      if (this.ShowCompleted) {
        tq = {
          $and: [{ deleted: false }, { goalid: row._id }]
        };
      }
      const opts = {
        fields: {
          tasktext: 1,
          durantiontype: 1,
          goalid: 1,
          deleted: 1,
          estblocks: 1,
          estminutes: 1,
          grindhours: 1,
          grindminutes: 1,
          grindmonths: 1,
          grindweeks: 1,
          grindyears: 1,
          grinddays: 1,
          esthours: 1,
          workflow: 1,
          useicon: 1,
          childorder: 1,
          steps: 1,
          goalname: 1,
          goalcolor: 1
        },
        sort: [["childorder", "asc"]]
      };
      const TaskList = await this.getData(
        "tasks",
        tq,
        opts,
        "tasklist",
        "none",
        "searchextended"
      );
      for (const t of TaskList) {
        t.taskTypeIcon = "check-square";
        if (t.durantiontype === "daily") {
          t.taskTypeIcon = "repeat";
        }
        row.tasks.push(t);
      }
      row.loading = false;
      row.expanded = true;
    },

    async closeSubChildren(row, i) {
      this.$set(row, "expanded", false);
      // row.expanded = false;
    },

    async createTask(ingoal) {
      this.$buefy.dialog.prompt({
        message: `Quick descripion`,
        inputAttrs: {
          placeholder: "Fix the water shortage in the world"
        },
        trapFocus: true,
        onConfirm: async value => {
          const newTsk = { ...this.task };
          const id = new ObjectID();
          const idout = id.str;
          newTsk._id = idout;
          newTsk.sub = this.user.sub;
          newTsk.tasktext = value;
          newTsk.subname = this.user.subname;
          newTsk.taskTypeIcon = "check-square";
          newTsk.goalid = ingoal._id;
          newTsk.goalname = ingoal.goaltitle;
          newTsk.goalcolor = ingoal.goalcolor;
          newTsk.availableto = ingoal.availableto;
          newTsk.children = [];
          newTsk.tasks = [];
          ingoal.tasks.push(newTsk);
          const nt = await this.updata(
            "tasks",
            newTsk,
            "none",
            "none",
            {},
            false
          );
        }
      });
    },

    openTask2(row) {
     this.displayMode = "task";
      if (row.dataid) {
        row.deleted = false;
        row.goalcolor = "#EFEFEF";
        row.goalid = row.parent;
        row.goalname = row.parenttext;
        row.tasktext = row.title;
        row._id = row.dataid;
      }
      this.ActiveTask = row;
      this.openRight();
    },

    async deleteGoal(row) {
      // check for children
      this.$buefy.dialog.confirm({
        title: "",
        message:
          "Are you sure you want to <b>delete</b> this goal/project? This action cannot be undone.",
        confirmText: "Delete",
        type: "is-danger",
        hasIcon: true,
        icon: 'exclamation',
        onConfirm: async () => {
          row.deleted = true;
          this.$buefy.toast.open("Goal Removed!");
          await this.updata("goals", row, "none", "none", {}, false);
        }
      });
    },

    async deleteTask2(goal, task) {
      // goal: the goal it belongs to
      // task: the actual task you want to delete
      // index: index of the record in the goal tasks array
      this.$buefy.dialog.confirm({
        title: "",
        message: "Are you sure you want to <b>delete</b> this task?",
        confirmText: "Delete Task",
        type: "is-danger",
        hasIcon: true,
        icon: 'exclamation',
        onConfirm: async () => {
          task.deleted = true;
          await this.updata("tasks", task, "none", "none", {}, false);
          this.$buefy.toast.open("Task Deleted");
        }
      });
    },

    async sortList(evt, list, model) {
      const newlist = [];
      for (let i = 0; i < list.length; i++) {
        const p = list[i];
        const newitem = {
          _id: p._id,
          childorder: i
        };
        newlist.push(newitem);
      }
      await this.DataSend("post", "/goals/sort", newlist);
    },

    async sortListTasks(evt, list, model) {
      const newlist = [];
      for (let i = 0; i < list.length; i++) {
        const p = list[i];
        const newitem = {
          _id: p._id,
          childorder: i
        };
        newlist.push(newitem);
      }
      await this.DataSend("post", "/tasks/sort", newlist);
    },

    // meetings: start
    addMeeting(day, type = 'meeting') {
      this.newMeetingMode = "new";
      this.ActiveCol = day;
      this.showMeeting = true;
      this.newMeeting = { ...this.dayobj };
      const id = new ObjectID();
      const idout = id.str;
      this.newMeeting.calid = idout;
      this.newMeeting.hourstart = 15;
      this.newMeeting.minutestart = 30;
      this.newMeeting.meetingminutes = 45;
      this.newMeeting.duration = "once";
      this.newMeeting.type = type;
    },

    openMeeting(day, row) {
      this.newMeetingMode = "edit";
      this.ActiveCol = day;
      this.showMeeting = true;
      (this.goalSelected = {
        _id: row.parent,
        goaltitle: row.parenttext,
        goalcolor: row.colour
      }),
        (this.newMeeting = row);
    },

    closeMeeting() {
      (this.goalSelected = {
        _id: "all",
        goaltitle: "No Goal Selected",
        goalcolor: "#CCC"
      }),
        (this.showMeeting = false);
    },

    selectPerson() {
      const that = this;
      this.modalA = this.$buefy.modal.open({
        parent: this,
        component: ModalPeopleList,
        hasModalCard: true,
        props: {
          showOpen: false,
          showSelect: true,
          showCheckBox: false,
          hideHeader: false,
          hideRefresh: true,
          hideFilter: false,
          hideAdd: false,
          hideFooter: true,
          listPersonType: "",
          listClients: false,
          listSuppliers: false,
          listProspects: false,
          listContacts: false,
          listFriends: false,
          callBus: "Work_Meeting_Person",
          gl: that.gl
        }
      });
    },
    setPerson(data) {
      this.newMeeting.people.push(data);
    },
    deletePerson(p, index) {
      this.$buefy.dialog.confirm({
        title: "",
        message:
        "Are you sure you want to <b>delete</b> this? This cannot be undone.",
        confirmText: "Delete",
        type: "is-danger",
        hasIcon: true,
        icon: 'exclamation',
        onConfirm: () => {
          this.newMeeting.people.splice(index, 1);
        }
      });
    },
    setMeetingGoal() {
      if (this.goalSelected._id !== "all") {
        this.newMeeting.parent = this.goalSelected._id;
        this.newMeeting.parenttext = this.goalSelected.goaltitle;
        this.newMeeting.colour = this.goalSelected.goalcolor;
      }
    },
    saveMeeting() {
      let totalmins = 0;
      totalmins += this.newMeeting.meetinghours * 60;
      totalmins += this.newMeeting.meetingminutes;
      totalmins += this.newMeeting.travelhours * 60;
      totalmins += this.newMeeting.travelminutes;
      const totalseconds = totalmins * 60;
      const timeback = this.secondsToHours(totalseconds);
      this.newMeeting.hours = timeback.hours;
      this.newMeeting.minutes = timeback.minutes;
      const tblocks = totalmins / 15;
      this.newMeeting.blocks = tblocks;
      if (this.newMeeting.title === "") {
        this.newMeeting.title = "Meeting";
      }
      if (this.newMeetingMode === "new") {
        this.ActiveCol.events.push(this.newMeeting);
      }
      this.saveCol(this.ActiveCol);
      this.closeMeeting();
      // calculate
    }
    // meetings:end

    // end
  }
};
