<template>
  <div class="contentleft-inner">
    <div class="contentleft-header" v-if="!hideHeader">
      <div class="contentleft-header-searchbox">
        <b-input placeholder="Search" type="search" icon="search" v-model="searchField" @input="doSearch()" ></b-input>
      </div>
      <!-- <div class="iconbutton mt5" @click="reload()" v-if="!hideRefresh">
        <font-awesome-icon :icon="['fal', 'sync-alt']" size="lg" ></font-awesome-icon>
      </div> -->
      <!-- <div class="iconbutton mt5" @click="showHideFilter()" v-if="!hideFilter">
        <font-awesome-icon :icon="['fal', 'filter']" size="lg" ></font-awesome-icon>
      </div> -->
      <div class="ml3">
        <b-button icon-left="plus" @click="personQuickAddToggle()" v-if="!hideAdd" type="is-info">New</b-button>
      </div>
    </div>
    <div class="contentleft-header isFlexAlign">
      <!-- <div class=" mr11">
        <b-field>
          <b-select placeholder="Select..." v-model="searchPersonType" @input="loadPeople()" >
            <option v-for="opt in optPersonTypeSearch" :value="opt.value" :key="opt.label" >{{ opt.label }}</option >
          </b-select>
        </b-field>
      </div> -->
      <div>
        <div class="block">
          <b-checkbox v-model="searchPersonRoles.contact" @input="doSearch">Contacts</b-checkbox>
          <b-checkbox v-model="searchPersonRoles.friend" @input="doSearch">Friends</b-checkbox>
          <b-checkbox v-model="searchPersonRoles.prospect" @input="doSearch">Prospects</b-checkbox>
          <b-checkbox v-model="searchPersonRoles.client" @input="doSearch">Clients</b-checkbox>
          <b-checkbox v-model="searchPersonRoles.supplier" @input="doSearch">Suppliers</b-checkbox>
        </div>
      </div>
    </div>

    <div class="contentleft-body scrolly scrollx">
      <!-- add task: start -->
      <div class="contentleft-body-inner" v-if="showQuickAdd">
        <div style="width: 100%;">
          <b-field label="People Type">
            <b-select
              placeholder="Select..."
              expanded
              v-model="quick.persontype"
              @input="personQuickType()"
            >
              <option
                v-for="tit in optPersonType"
                :value="tit.value"
                :key="tit.value"
                >{{ tit.label }}</option
              >
            </b-select>
          </b-field>
          <b-field :label="fields.firstname">
            <b-input v-model="quick.firstname"></b-input>
          </b-field>
          <b-field :label="fields.lastname" v-if="quick.persontype !== 'Team'">
            <b-input v-model="quick.lastname"></b-input>
          </b-field>
          <b-field label="One Liner">
            <b-input v-model="quick.oneliner"></b-input>
          </b-field>
          <b-field label="E-mail" v-if="quick.persontype !== 'Team'">
            <b-input v-model="quick.email"></b-input>
          </b-field>
          <b-field label="Mobile" v-if="quick.persontype !== 'Team'">
            <b-input v-model="quick.mobile"></b-input>
          </b-field>
          <div style="margin: 11px 0 11px 5px;">
            <div class="block">
              <b-checkbox v-model="quick.personrole.contact"
                >Contacts</b-checkbox
              >
              <b-checkbox v-model="quick.personrole.friend">Friends</b-checkbox>
              <b-checkbox v-model="quick.personrole.prospect"
                >Prospects</b-checkbox
              >
              <b-checkbox v-model="quick.personrole.client">Clients</b-checkbox>
              <b-checkbox v-model="quick.personrole.supplier"
                >Suppliers</b-checkbox
              >
            </div>
          </div>
          <div>
            <b-button @click="personQuickAdd(false)" style="width: 100%"
              >Add</b-button
            >
          </div>
          <div style="margin: 8px 0;">
            <b-button @click="personQuickAdd(true)" style="width: 100%"
              >Add &amp; Open</b-button
            >
          </div>
        </div>
      </div>
      <!-- add task: end -->
      <!-- task: start -->
      <div class="tsk lgreybg p11 m11" v-if="PeopleList.length === 0">No contacts created</div>
      <PersonListing
        :person="person"
        :pindex="index"
        :showOpen="showOpen"
        :showSelect="showSelect"
        :showCheckBox="showCheckBox"
        :callBus="callBus"
        v-for="(person, index) in PeopleList"
        :key="person._id"
      />
      <!-- task: end -->
    </div>
    <!-- <div class="contentleft-foot" v-if="!hideFooter">
      <div>
        <b-field>
          <b-select
            placeholder="Select Goal"
            rounded
            v-model="searchGoal"
            @input="loadPeople()"
          >
            <option v-for="goal in GoalList" :value="goal" :key="goal._id">{{
              goal.goaltitle
            }}</option>
          </b-select>
        </b-field>
      </div>
    </div> -->
  </div>
</template>

<script>
import ObjectID from "bson-objectid";
import { Utils } from "../mixins/utils.js";
import { EventBus } from "../event-bus.js";
import store from "../store";
import debounce from "lodash/debounce";
import { ToastProgrammatic as Toast } from "buefy";
import { windowSplit2 } from "../mixins/windowSplit.js";
import { dbFunctions } from "../mixins/dbMixins.js";
import PersonListing from "@/components/PersonListing.vue";
export default {
  mixins: [Utils, windowSplit2, dbFunctions],
  name: "PeopleList",
  store,

  components: {
    PersonListing
  },

  props: {
    showOpen: Boolean,
    showSelect: Boolean,
    showCheckBox: Boolean,
    hideHeader: Boolean,
    hideRefresh: Boolean,
    hideFilter: Boolean,
    hideAdd: Boolean,
    hideFooter: Boolean,
    listPersonType: String,
    listClients: Boolean,
    listSuppliers: Boolean,
    listProspects: Boolean,
    listContacts: Boolean,
    listFriends: Boolean,
    callBus: String
  },

  data() {
    return {
      PeopleList: [],
      GoalList: [],
      showFilters: false,
      showQuickAdd: false,
      searchField: "",
      searchPersonType: "",
      searchGoal: { _id: "all", goaltitle: "All Projects", goalcolor: "" },
      searchGoalId: "",
      searchGoalCol: "",
      searchPersonRoles: {
        contact: false,
        friend: false,
        prospect: false,
        client: false,
        supplier: false
      },
      quick: {
        persontype: "Person",
        firstname: "",
        lastname: "",
        oneliner: "",
        email: "",
        mobile: "",
        personrole: {
          contact: true,
          friend: false,
          prospect: false,
          client: false,
          supplier: false
        }
      },
      fields: {
        firstname: "First Name",
        lastname: "Last Name"
      },
      ActivePerson: {},
      TeamLive: localStorage.getItem("TeamLive"),
      TeamLiveName: localStorage.getItem("TeamLiveName"),
      TeamLiveColour: localStorage.getItem("TeamLiveColour")
    };
  },

  async created() {
    EventBus.$on("do_search", data => {
      this.doSearch();
    });
    EventBus.$on("team_changed", data => {
      this.TeamLive = localStorage.getItem("TeamLive");
      this.TeamLiveName = localStorage.getItem("TeamLiveName");
      this.TeamLiveColour = localStorage.getItem("TeamLiveColour");
      this.loadPeople();
    });
    EventBus.$on("person_added", data => {
      Toast.open("Person Added");
      this.ActivePerson = data.data;
      this.PeopleList.unshift(this.ActivePerson);
      if (data.openAfterAdd) {
        EventBus.$emit("person_open", this.ActivePerson);
      }
    });
    EventBus.$on("person_deleted", data => {
      const arrkey = this.getArrayId(this.PeopleList, "_id", data._id);
      this.PeopleList.splice(arrkey, 1);
      EventBus.$emit("close_right", {});
      Toast.open("Person Deleted");
    });

    EventBus.$on("person_saved", data => {
      if (this.showOpen) {
        const arrkey = this.getArrayId(this.PeopleList, "_id", data._id);
        this.PeopleList[arrkey].firstname = data.data.firstname;
        this.PeopleList[arrkey].lastname = data.data.lastname;
        this.PeopleList[arrkey].persontype = data.data.persontype;
        this.PeopleList[arrkey].personrole = data.data.personrole;
        this.PeopleList[arrkey].oneliner = data.data.oneliner;
        this.PeopleList[arrkey].occupation = data.data.occupation;
        this.PeopleList[arrkey].original = data.data.original;
        Toast.open("Person Saved");
      }
    });
    // this.getGoals();
    this.loadPeople();
  },

  methods: {
    getTeam() {
      this.TeamLive = localStorage.getItem("TeamLive");
      this.TeamLiveName = localStorage.getItem("TeamLiveName");
      this.TeamLiveColour = localStorage.getItem("TeamLiveColour");
      // this.getGoals();
    },

    async getGoals() {
      let query = { deleted: false };
      if (this.TeamLive !== "") {
        let query = { deleted: false, "availableto._id": this.TeamLive };
      }
      const options = { fields: { goaltitle: 1, goalcolor: 1 } };
      const ingl = await this.DataGet('/goals/select');
      this.GoalList = ingl;
      this.GoalList.unshift({
        _id: "all",
        goaltitle: "All Projects",
        goalcolor: ""
      });
    },

    showHideFilter() {
      if (this.showFilters) {
        this.showFilters = false;
      } else {
        this.showFilters = true;
      }
    },

    reload() {
      EventBus.$emit("close_right", {});
      this.loadPeople();
    },

    async loadPeople() {
      const senddata = {
        deleted: false,
        searchfield: this.searchField,
        personrole_contact: false,
        personrole_friend: false,
        personrole_prospect: false,
        personrole_client: false,
        personrole_supplier: false,
        persontype: this.searchPersonType,
        goalid: this.searchGoal._id
      }
      if (this.searchPersonRoles.contact || this.listContacts) { senddata.personrole_contact = true; }
      if (this.searchPersonRoles.friend || this.listFriends) { senddata.personrole_friend = true; }
      if (this.searchPersonRoles.prospect || this.listContacts) { senddata.personrole_prospect = true; }
      if (this.searchPersonRoles.client || this.listProspects) { senddata.personrole_client = true;}
      if (this.searchPersonRoles.supplier || this.listSuppliers) { senddata.personrole_supplier = true; }

      let query = { deleted: false };
      if (this.listPersonType !== "") { this.searchPersonType = this.listPersonType; }
      if (this.searchField !== "") {
        const qstr = `.*${this.searchField}.*`;
        query = {
          $and: [
            {
              $or: [
                { firstname: { $regex: qstr, $options: "i" } },
                { lastname: { $regex: qstr, $options: "i" } }
              ]
            },
            { deleted: false }
          ]
        };
        
        
      } else {
        if (this.searchPersonRoles.client || this.listClients) {
          query["personrole.client"] = true;
        }
        if (this.searchPersonRoles.contact || this.listContacts) {
          query["personrole.contact"] = true;
        }
        if (this.searchPersonRoles.friend || this.listFriends) {
          query["personrole.friend"] = true;
        }
        if (this.searchPersonRoles.prospect || this.listProspects) {
          query["personrole.prospect"] = true;
        }
        if (this.searchPersonRoles.supplier || this.listSuppliers) {
          query["personrole.supplier"] = true;
        }
        if (this.searchPersonType !== "") {
          query["persontype"] = this.searchPersonType;
        }
      }

      if (this.searchGoal._id !== "all") {
        query.goalid = this.searchGoal._id;
      }
      const options = {
        select: 'firstname lastname persontype personrole oneliner occupation original contactnumbers emails',
      };
      Toast.open("Fetching people...");

      this.PeopleList = await this.getData(
        "people",
        query,
        options,
        "peoplelist",
        "none",
        "getAllPeople"
      );
      Toast.open("People loaded...");
    },

    doSearch: debounce(function() {
      this.loadPeople();
    }, 2000),

    personQuickAddToggle() {
      if (this.showQuickAdd) {
        this.showQuickAdd = false;
      } else {
        this.showQuickAdd = true;
      }
    },

    personQuickType() {
      if (this.quick.persontype === "Person") {
        this.fields.firstname = "First Name";
        this.fields.lastname = "Last Name";
      }
      if (this.quick.persontype === "Organisation") {
        this.fields.firstname = "Org Name";
        this.fields.lastname = "T/A";
      }
      if (this.quick.persontype === "Team") {
        this.fields.firstname = "Team Name";
        this.fields.lastname = "";
      }
    },

    personQuickAdd(openAfterAdd) {
      if (this.quick.firstname === "") {
        this.$buefy.snackbar.open({
          message: "Please enter a name",
          position: "is-top"
        });
        return;
      }
      const nP = { ...this.person };
      const id = this.getUUID(true);
      nP._id = id;
      nP.persontype = this.quick.persontype;
      nP.firstname = this.quick.firstname;
      nP.lastname = this.quick.lastname;
      nP.oneliner = this.quick.oneliner;
      nP.personrole = this.quick.personrole;
      this.updata(
        "people",
        nP,
        "person_added",
        { openAfterAdd },
        false
      );
      (this.quick = {
        persontype: "Person",
        firstname: "",
        lastname: "",
        oneliner: "",
        email: "",
        mobile: "",
        personrole: {
          contact: true,
          friend: false,
          prospect: false,
          client: false,
          supplier: false
        }
      })
    }
  }
};
</script>
