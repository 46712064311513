<template>
  <div class="contentbody isFlexCol">
    <div class="homeNavi bluebg1" v-if="w.navi">
      <div class="tab" v-bind:class="{ tabactive: tabs.today }" @click="switchTabs('today')">Today</div>
      <!-- <div class="tab" v-bind:class="{ tabactive: tabs.feed }" @click="switchTabs('feed')">Feed</div> -->
      <div class="tab" v-bind:class="{ tabactive: tabs.updates }" @click="switchTabs('updates')" >Updates</div>
      <div class="tab isFlexGrow"></div>
    </div>
    <div class="homeBody isFlex isFlexGrow">
      <div class="homeTasks" v-if="tabs.today">
        <div class="homeHeader calcol-head bluebg2">
          <div style="border-radius: 50%; background-color: #e8e8f0; width: 26px; text-align: center; margin: 2px; font-weight: bold; color: #1b1464;">
            {{ NowDay.dateDay }}
          </div>
          <div class="isFlexGrow ml3">{{ NowDay.dateDayTxt }}</div>
          <div
            class="mr9">
            <b-button size="is-small" icon-left="plus" type="is-info" @click="taskAddOpen('day', NowDay)">New Task</b-button>
          </div>
          <div class="mr9">
            <b-button size="is-small" icon-left="handshake" type="is-info" @click="addMeeting(NowDay)">New Meeting</b-button>
          </div>
          <div class="mr9">
            <b-button size="is-small" icon-left="sync-alt" type="is-info" @click="setDay(NowDay.dateDB, 0)">Refresh</b-button>
          </div>
        </div>
        <div class="homeContent">
          <b-loading :active.sync="isLoading" :can-cancel="false" is-full-page></b-loading>
          <!-- instructions: start -->
          <div v-if="NowDay.events.length === 0">
            <!-- <div style="padding-right: 10px;" class="red isFlex isFlexEnd">
              <font-awesome-icon :icon="['fal', 'arrow-up']" size="lg" style="margin-right: 18px;"></font-awesome-icon>
              <font-awesome-icon :icon="['fal', 'arrow-up']" size="lg" style="margin-right: 7px;"></font-awesome-icon>
            </div>
            <div class="p5" style="text-align: right; height: 65px; font-size: 0.9em;">Use the <span class="red">plus</span> to create a task for today and the <span class="red">handshake</span> to create a meeting for today.</div>
            <div class="isFlex"  style="height: 60px;">
              <div class="red pt3"><font-awesome-icon :icon="['fal', 'arrow-left']" size="lg"></font-awesome-icon></div>
              <div class="isFlexGrow pl11" style="font-size: 0.9em;">Even better, go create a <span class="bluetxt10">Goal</span>.</div>
            </div>
            <div class="isFlex">
              <div class="red pt3"><font-awesome-icon :icon="['fal', 'arrow-left']" size="lg"></font-awesome-icon></div>
              <div class="isFlexGrow pl11" style="font-size: 0.9em;">Or choose a <span class="bluetxt10">Blueprint</span> to help you create you first Goal.</div>
            </div> -->
            <div class="tsk bluebg5 white p11 m11">Either you have not scheduled any tasks or you are a super preformer.</div>
          </div>
          <!-- instructions: end -->

          <draggable
            class="homeScroller p7"
            :list="NowDay.events"
            group="tasks"
            handle=".handle"
            @change="colChanged($event, NowDay, 0)"
          >
            <div v-for="(element, key) in NowDay.events" :key="key">
              <!-- divider block start -->
              <div v-if="element.type === 'divider'" class="tsk bluebg5 isFlex mb7 white">
                <div class="txtbold mr5">{{element.duration}}</div>
                <div class="isFlexGrow">{{element.title}}</div>
                <div class="iconbutton handle" v-if="DayGoal === ''">
                  <font-awesome-icon :icon="['fal', 'arrows']" size="sm"></font-awesome-icon>
                </div>
              </div>
              <!-- divider block end -->
              <!-- task block: start -->
              <div class="tsk vlgreybg mb7 isFlex isFlexCol lgreyborder" v-if="element.type !== 'divider'" v-bind:class="{ completed: element.completed }" v-bind:style="{ marginLeft: '0px', borderLeft: `5px solid ${element.colour} !important`, minHeight: `${element.blockHeight}px`}">
                <!-- header -->
                <div class="isFlex">
                  <div class="isFlexGrow">
                    <div  class="txtbold isPointer" v-if="element.type === 'task'" @click="openTask(element, 0, NowDay)">
                      {{ element.title }}
                    </div>

                    <div class="isPointer" v-if="element.type === 'meeting'" @click="(element.expanded) ? element.expanded=false:element.expanded=true">
                      <div class="txtbold">{{ element.hourstart }}:{{ element.minutestart }} Meeting</div>
                      {{ element.title }}
                    </div>

                    <div class="isPointer" v-if="element.type === 'availability'" @click="(element.expanded) ? element.expanded=false:element.expanded=true">
                      <div class="txtbold">{{ element.hourstart }}:{{ element.minutestart }} {{ element.slottype }} Session</div>
                      {{ element.title }}
                    </div>

                    <div class="txtsm txtitalic">
                      {{ element.parenttext }}
                    </div>
                    <div class="txtmd">
                      {{ element.description }}
                    </div>
                  </div>
                  <div v-if="element.type === 'project'" class="project">P</div>
                  <div v-if="element.type === 'task'" class="task">T</div>
                  <div v-if="element.type === 'meeting'" class="meeting">M</div>
                  <div v-if="element.type === 'availability'" class="availability">M</div>
                  <div style="padding: 2px 2px 0 5px;" v-if="element.ismine && !element.demo">
                    <b-checkbox size="is-small" v-model="element.completed" @input="updateWorkflow(element, NowDay, true)"></b-checkbox>
                  </div>
                  <div style="padding: 2px 2px 0 5px;" v-if="element.demo">
                    <b-checkbox size="is-small" v-model="element.completed" @input="updateDemo(element, NowDay)"></b-checkbox>
                  </div>
                </div>
                <!-- body -->
                <div class="isFlexGrow">

                  <!-- meeting:start -->
                    <div v-if="(element.type === 'meeting' || element.type === 'availability') && element.expanded" style="border-top: 1px solid #EFEFEF; margin-top: 3px; padding: 5px;">
                      <div style="font-size: 0.9em; margin-bottom: 3px; font-weight: bold;">
                        Attendees
                      </div>
                      <div class="tsk lgreybg mb3 txtsm" v-if="element.people.length === 0">
                        No attendees
                      </div>
                      <div class="tsk lgreybg mb3" v-for="(p, pi) in element.people" :key="pi">
                        <div class="txtbold">
                          {{ p.firstname }} {{ p.lastname }} <span v-if="p.confirmed" class="bluetxt8">(confirmed)</span>
                        </div>
                        <div><a :href="`mailto:${p.email}`">{{p.email}}</a></div>
                        <div><a :href="`tel:${p.email}`">{{p.mobile}}</a></div>
                      </div>
                      <div class="mt11 mb5 txtbold">
                        {{element.where}}
                      </div>
                      <div class="tsk lgreybg mb3 txtsm" v-if="element.where === 'Away'">
                        <div class="txtitalic">Travel time is: {{ element.travelhours }}h {{ element.travelminutes }}</div>
                        <div>{{ element.address }}</div>
                      </div>
                      <div class="tsk lgreybg mb3" v-if="element.where === 'Online External'">
                        <div class="txtmd txtbold">Url</div>
                        <div style="overflow: hidden;"><a :href="element.confurl" target="_blank">{{element.confurl}}</a></div>
                        <div class="txtmd txtbold">ID/User/Room</div>
                        <div>{{element.confuser}}</div>
                        <div class="txtmd txtbold">Pin</div>
                        <div>{{element.confpin}}</div>
                      </div>
                    </div>
                    <!-- meeting:end -->

                  <!-- main: tasks -->
                  <!-- task footer -->
                </div>

                <!-- main footer -->
                <div v-if="element.tasks.length !== 0" style="border-bottom: 1px solid #EFEFEF; margin: 2px 0 5px 0;"></div>
                <div>
                  <div v-if="element.editPeople">
                    <div v-for="(p, index) in element.people" :key="p._id" :index="index">
                      <div class="isFlex tsk lgreybg mt3 isFlexSpace">
                        <div>{{ getInitials(p.username) }}</div>
                        <div class="txtsm">{{p.workflow}}</div>
                      </div>
                    </div>
                  </div>
                  <div v-if="element.type === 'task' && element.editWorkFlow && element.ismine && !element.demo">
                    <b-select placeholder="Select" size="is-small" expanded v-model="element.workflow" @input="updateWorkflow(element, NowDay, false)">
                      <option v-for="w in workflow" :value="w.value" :key="w.value">
                        {{ w.label }}
                      </option>
                    </b-select>
                  </div>
                  <div v-if="element.editBlocks" class="isFlex isFlexAlign" style="padding: 7px 3px;">
                    <div class="isFlex isFlexGrow">
                      <!-- <b-numberinput controlsPosition="compact" v-model="element.newblocks" size="is-small" @input="updateTime(element)"/> -->
                      <b-numberinput controlsPosition="compact" v-model="element.hours" size="is-small" step="1" min="0" max="23"/>
                      <b-numberinput controlsPosition="compact" v-model="element.minutes" size="is-small" step="15" min="0" max="45"/>
                    </div>
                    <div>
                      <b-button @click="updateBlocks(element, NowDay)" size="is-small">Update</b-button>
                    </div>
                  </div>
                  <!-- <div>
                    {{ element.people }}
                  </div> -->
                  <div class="isFlex">
                    <!-- mainfoot -->
                    <div class="isFlexGrow isFlex isFlexAlign pl9">
                      
                      <div @click="editBlocksExpand(element)" style="font-weight: bold; cursor: pointer;">
                        <span v-if="element.hours !== 0">{{ element.hours }}h </span>{{ element.minutes }}m&nbsp;
                      </div>
                    

                      <div v-if="element.type === 'task' && element.people.length > 1 || element.ismine === false" class="iconbutton bluetxt10" style="width: 45px;" @click="peopleExpand(element)">
                        <font-awesome-icon :icon="['fal', 'users']" size="sm"></font-awesome-icon>&nbsp; {{element.people.length}}
                      </div>
                      <div v-if="element.type === 'task'" class="iconbutton" style="width: 29px;" @click="workflowExpand(element)">
                        <font-awesome-icon :icon="['fal', 'sparkles']" size="sm"></font-awesome-icon>
                      </div>
                      <div v-if="element.type === 'task'" class="txtsm isFlexGrow">
                        {{ element.workflow }}
                      </div>
                    </div>
                    
                    <div v-if="element.type === 'meeting'" class="iconbutton" style="width: 29px;" @click="openMeeting(NowDay, element)">
                      <font-awesome-icon :icon="['fal', 'edit']" size="sm"></font-awesome-icon>
                    </div>
                    <div v-if="element.type === 'task'" class="iconbutton bluetxt10" style="width: 29px;" @click="openTask(element, 0, NowDay)">
                      <font-awesome-icon :icon="['fal', 'eye']" size="sm"></font-awesome-icon>
                    </div>
                    <div class="iconbutton" @click="removeColItem(NowDay, element, 0, key)">
                      <font-awesome-icon :icon="['fal', 'trash']" size="sm"></font-awesome-icon>
                    </div>
                    <div class="iconbutton handle">
                      <font-awesome-icon :icon="['fal', 'arrows']" size="sm"></font-awesome-icon>
                    </div>
                  </div>
                </div>
              </div>
              <!-- task block: end -->
            </div>
          </draggable>
        </div>
        <!-- <div class="homeFooter p3 bluebg2">
          <span
            ><strong>{{ NowDay.totalblocks }}</strong
            >/24 | <strong>{{ NowDay.totalhours }}</strong
            >h {{ NowDay.totalminutes }}</span
          >
        </div> -->
      </div>
      <!-- feed:start -->
      <div class="homeCols" v-if="tabs.feed">
        <div class="homeHeader bluebg2">Thoughts &amp; Questions</div>
        <div class="bluebg1 p11">
          <div>
            <b-field>
              <b-input
                type="textarea"
                minlength="20"
                maxlength="240"
                rows="3"
                v-model="newth.thought"
                placeholder="Share a #thought or ask a #question..."
              >
              </b-input>
            </b-field>
          </div>
          <div class="mb7">
            <b-input
              placeholder="https://fulladdress.com"
              v-model="newth.link"
            ></b-input>
          </div>
          <!-- image: start -->
          <div>
            <div class="isFlex isFlexJustify mt3 mb7" v-if="newth.image !== ''">
              <img :src="newth.image" style="width: 100%;" />
            </div>
          </div>
          <!-- image: end -->
          <div class="isFlex">
            <div class="isFlexGrow isFlex">
              <!-- <div class="iconbutton tsk whitebg mr5" @click="changeImage()">
                <font-awesome-icon :icon="['fal', 'link']" size="lg"></font-awesome-icon>
              </div> -->
              <div class="iconbutton tsk whitebg mr5" @click="changeImage()">
                <font-awesome-icon
                  :icon="['fal', 'image']"
                  size="lg"
                ></font-awesome-icon>
              </div>
            </div>
            <div>
              <b-button size="is-small" @click="sharethought('')"
                >Share</b-button
              >
            </div>
          </div>
        </div>
        <div class="homeContent">
          <div class="homeScroller">
            <!-- thought list -->
            <div
              class="p11 isFlex"
              style="border-bottom: 1px solid #EFEFEF;"
              v-for="(t, ti) in thoughts"
              :key="ti"
            >
              <div class="mr7">
                <img
                  v-if="t.author.photo !== ''"
                  :src="t.author.photo"
                  class="thumb thumb-xsm thumb-circle"
                />
                <img
                  v-else
                  src="/dummy/120x120.png"
                  class="thumb thumb-xsm thumb-circle"
                />
              </div>
              <div class="isFlexGrow">
                <div class="txtbold txtsm">
                  {{ t.author.firstname }} {{ t.author.lastname }}
                </div>
                <div>{{ t.thought }}</div>
                <div class="txtsm mt5 isFlex">
                  <div class="isFlexGrow">
                    <font-awesome-icon
                      :icon="['fal', 'comment']"
                      size="sm"
                    ></font-awesome-icon>
                    0
                  </div>
                  <div class="isPointer mr5">Reply</div>
                  <div>Report</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="homeFooter">col foot</div> -->
      </div>
      <!-- feed:end -->
      <!-- updates:start -->
      <div class="homeCols" v-if="tabs.updates">
        <div class="homeHeader bluebg2">Updates</div>
        <div class="homeContent">
          <div class="homeScroller">
            <!-- <div class="tsk bluebg1 m7 p11 video-responsive">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/puS8fsMKg2E"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div> -->

            <div class="tsk bluebg1 m7 p11">
              Feedback &amp; Support:<br />
              Please go to our Facebook page
              <a href="https://www.facebook.com/groups/dne.life/" target="_blank">https://www.facebook.com/groups/dne.life/</a>
            </div>

            <div class="tsk bluebg8 m7 p11 white">
              <div class="txtsm">Note to Previous Beta users:</div>
              <div>
                Due to the complete rewrite of the underlying engine all your sub goals have become upper level goals. The new engine works on one goal and unlimited tasks.
              </div>
            </div>

            <div class="tsk vlgreybg m7 p11">
              <div class="txtsm">Currently working on</div>
              <div>Blueprints market place. </div>
            </div>

            <div class="tsk vlgreybg m7 p11">
              <div class="txtsm">Next up</div>
              <div>
                Enabling the blueprints marketplace.
              </div>
            </div>

          </div>
        </div>
      </div>
      <!-- updates:start -->
    </div>
    <!-- popups: start -->

    <b-modal :active.sync="showTask" :width="400" scroll="clip" has-modal-card>
      <div class="card modal-card roundedtop roundedbottom">
        <div class="card-header roundedtop bluebg10">
          <div class="card-header-title white">Task</div>
        </div>
        <div class="modal-card-body">
          <div class="content">
            <b-field label="Task title">
              <b-input type="textarea" rows="1" v-model="newTask.tasktext"></b-input>
            </b-field>
            <b-field label="Description">
              <b-input type="textarea" rows="2" v-model="newTask.taskdescription"></b-input>
            </b-field>
            <div>Estimated time to complete</div>
            <div class="isFlex tsk bluebg1">
              <div style="width: 50%; padding-right: 10px;">
                <b-field label="Hours">
                  <b-numberinput size="is-small" min="0" max="23" v-model="newTask.esthours"></b-numberinput>
                </b-field>
              </div>
              <div style="width: 50%; padding-left: 10px;">
                <b-field label="Minutes">
                  <b-numberinput size="is-small" v-model="newTask.estminutes" step="15" min="0" max="45"></b-numberinput>
                </b-field>
              </div>
            </div>
            <b-field label="Assign to goal" class="mt7">
              <b-select placeholder="Select Goal" expanded v-model="goalSelected" @input="setTaskGoal()">
                <option v-for="gc in GoalList" :value="gc" :key="gc._id">{{ gc.goaltitle }}</option>
              </b-select>
            </b-field>
          </div>
        </div>
        <footer class="card-footer roundedbottom">
          <div class="card-footer-item roundedbottomleft isPointer bluebg1 bluetxt8" @click="taskClose">Cancel</div>
          <div class="card-footer-item roundedbottomright isPointer bluebg10 white txtbold" @click="taskAdd()">Save</div>
        </footer>
      </div>
    </b-modal>

    <b-modal :active.sync="showMeeting" :width="400" scroll="clip" has-modal-card>
      <div class="card modal-card roundedtop roundedbottom">
        <div class="card-header roundedtop bluebg10">
          <div class="card-header-title white" v-if="newMeeting.type === 'meeting'">Meeting</div>
          <div class="card-header-title white" v-else>Availability Slot</div>
        </div>
        <div class="modal-card-body">
          <div class="content">
            <b-select placeholder="Change type" v-model="newMeeting.type" expanded>
              <option value="meeting">Meeting</option>
              <option value="availability">Availability</option>
            </b-select>
            <b-field label="Description">
              <b-input type="textarea" rows="2" v-model="newMeeting.title"></b-input>
            </b-field>
            <!-- attendees: start -->
            <template>
              <div class="mv11 tsk bluebg1">
                <template v-if="newMeeting.type === 'availability'">
                  <b-field label="What type of slot is this?">
                    <b-select placeholder="Slot type" v-model="newMeeting.slottype" expanded>
                      <option value="One to One Session">One to One Session</option>
                      <option value="Group Session">Group Session</option>
                      <option value="Meeting Slot">Meeting Slot</option>
                    </b-select>
                  </b-field>
                  <div style="padding-bottom: 10px;" v-if="newMeeting.slottype === 'Group Session'">
                    <b-field label="How many people can join the slot?">
                      <b-numberinput size="is-small" min="0" v-model="newMeeting.attendees"></b-numberinput>
                    </b-field>
                  </div>
                  <b-field label="Payment model" v-if="newMeeting.slottype !== 'Meeting Slot'">
                    <b-select placeholder="Free or billed" v-model="newMeeting.slotbill" expanded>
                      <option value="Free">Free</option>
                      <option value="per Individual">per Individual</option>
                      <option value="per Group">per Group</option>
                    </b-select>
                  </b-field>
                  <b-field label="Cost" v-if="newMeeting.slottype !== 'Meeting Slot' && newMeeting.slotbill !== 'Free'">
                    <b-input type="number" v-model="newMeeting.slotcost"></b-input>
                  </b-field>
                  <b-field label="Terms" v-if="newMeeting.slottype !== 'Meeting Slot' && newMeeting.slotbill !== 'Free'">
                    <b-input placeholder="Please pay 3 days before the event" v-model="newMeeting.slotbillterm"></b-input>
                  </b-field>
                </template>
                <div class="isFlex">
                  <div class="isFlexGrow">Attendees</div>
                  <div class="iconbutton" @click="selectPerson()" v-if="newMeeting.type === 'meeting'">
                    <font-awesome-icon :icon="['fal', 'id-card']" size="sm"></font-awesome-icon>
                  </div>
                </div>
                <div class="mve tsk" v-if="newMeeting.people && newMeeting.people.length === 0">No attendees</div>

                <div class="isFlex mv3 tsk" v-for="(p, index) in newMeeting.people" :key="index">
                  <div class="isFlexGrow">
                    <div>{{ p.firstname }} {{ p.lastname }}</div>
                    <div><a :href="`mailto:${p.email}`">{{p.email}}</a></div>
                    <div><a :href="`tel:${p.email}`">{{p.mobile}}</a></div>
                  </div>
                  <div class="iconbutton" @click="deletePerson(p, index)">
                    <font-awesome-icon :icon="['fal', 'trash']" size="sm"></font-awesome-icon>
                  </div>
                </div>
              </div>
            </template>
            <!-- attendees: end -->

            <!-- when: start -->
            <div v-if="newMeeting.type === 'meeting'">Meeting Time</div>
            <div v-else>What time are you available?</div>
            <div class="isFlex tsk bluebg1">
              <div style="width: 50%; padding-right: 10px;">
                <b-field label="Hour">
                  <b-numberinput size="is-small" min="0" max="23" v-model="newMeeting.hourstart"></b-numberinput>
                </b-field>
              </div>
              <div style="width: 50%; padding-left: 10px;">
                <b-field label="Minutes">
                  <b-numberinput size="is-small" v-model="newMeeting.minutestart" step="5" min="0" max="55"></b-numberinput>
                </b-field>
              </div>
            </div>
            <!-- when: end -->

            <!-- duration: start -->
            <div style="height: 20px;"></div>
            <div v-if="newMeeting.type === 'meeting'">Meeting Duration</div>
            <div v-else>How long is this slot for?</div>
            <div class="isFlex tsk bluebg1">
              <div style="width: 50%; padding-right: 10px;">
                <b-field label="Hour">
                  <b-numberinput size="is-small" min="0" max="23" v-model="newMeeting.meetinghours"></b-numberinput>
                </b-field>
              </div>
              <div style="width: 50%; padding-left: 10px;">
                <b-field label="Minutes">
                  <b-numberinput size="is-small" v-model="newMeeting.meetingminutes" step="15" min="0" max="45">
                  </b-numberinput>
                </b-field>
              </div>
            </div>
            <!-- duration: end -->
            <!-- where: start -->
            <b-field label="Where?">
              <b-select placeholder="Select a name" v-model="newMeeting.where" expanded>
                <option value="Office">Office</option>
                <option value="Away">Away</option>
                <option value="Online External">Online External</option>
              </b-select>
            </b-field>
            <b-field label="Online url" message="Please enter the url for the meeting (Zoom, Hangouts, Teams) plus pins or passwords if needed" v-if="newMeeting.where === 'Online External'">
              <b-input  v-model="newMeeting.confurl"></b-input>
            </b-field>
            <b-field label="Online username or room number" v-if="newMeeting.where === 'Online External'">
              <b-input  v-model="newMeeting.confuser"></b-input>
            </b-field>
            <b-field label="Online PIN" v-if="newMeeting.where === 'Online External'">
              <b-input  v-model="newMeeting.confpin"></b-input>
            </b-field>
            <b-field label="Location" v-else>
              <b-input type="textarea" rows="2" v-model="newMeeting.address"></b-input>
            </b-field>
            <!-- where: end -->
            <!-- travel time: start -->
            <template v-if="newMeeting.where === 'Away'">
              <div>Travel Time</div>
              <div class="isFlex tsk bluebg1">
                <div style="width: 50%; padding-right: 10px;">
                  <b-field label="Hour">
                    <b-numberinput size="is-small" min="0" max="23" v-model="newMeeting.travelhours"></b-numberinput>
                  </b-field>
                </div>
                <div style="width: 50%; padding-left: 10px;">
                  <b-field label="Minutes">
                    <b-numberinput size="is-small" v-model="newMeeting.travelminutes" step="15" min="0" max="45"></b-numberinput>
                  </b-field>
                </div>
              </div>
            </template>
            <!-- travel time: end -->
            <!-- goal: start -->
            <b-field label="Assign to goal" class="mt7">
              <b-select placeholder="Select Goal" expanded v-model="goalSelected" @input="setMeetingGoal()">
                <option v-for="gc in GoalList" :value="gc" :key="gc._id">{{ gc.goaltitle }}</option>
              </b-select>
            </b-field>
            <!-- goal: end -->
          </div>
        </div>
        <footer class="card-footer roundedbottom">
          <div class="card-footer-item roundedbottomleft isPointer bluebg1 bluetxt8" @click="closeMeeting">Cancel</div>
          <div class="card-footer-item roundedbottomright isPointer bluebg10 white txtbold" @click="saveMeeting()">Save</div>
        </footer>
      </div>
    </b-modal>
    <!-- popups: end -->
  </div>
</template>

<script>
// @ is an alias to /src
import findLastIndex from "lodash/findLastIndex";
import store from "../store";
import ObjectID from "bson-objectid";
import { EventBus } from "../event-bus.js";
import { windowSplit2 } from "../mixins/windowSplit.js";
import { dbFunctions } from "../mixins/dbMixins.js";
import { goalsMixins } from "@/mixins/goalsMixins.js";
import { Utils } from "@/mixins/utils.js";
import draggable from "vuedraggable";
import ModalGoals from "@/modals/ModalGoals.vue";
import ModalTask from "@/modals/ModalTask.vue";
import ModalImage from "@/components/ModalImageSquare.vue";

export default {
  mixins: [Utils, windowSplit2, dbFunctions, goalsMixins],
  name: "home",
  components: {
    draggable
  },
  data() {
    return {
      user: store.state.user,
      me: {},
      TodayRaw: new Date(),
      Today: "",
      TodayTS: 0,
      TodayTxt: "Sun",
      isLoading: true,
      w: {
        navi: true,
        left: true,
        right1: true,
        right2: true
      },
      tabs: {
        today: true,
        feed: true,
        updates: true
      },
      windowWidth: 0,
      windowHeight: 0,
      NowDay: {
        row: 0,
        dateDB: "",
        dateDay: 1,
        dateDayTxt: "Mon",
        dateDayTxtLong: "Monday",
        dateDayOfWeek: 1,
        dateDayOfYear: 1,
        dateTS: 0,
        _id: "",
        events: [],
        showMeeting: false,
        totalhours: 0,
        totalminutes: 0,
        totalblocks: 0,
        previousgoalid: '',
      },

      showTask: false,
      newTask: {},
      showMeeting: false,
      newMeeting: {},
      newMeetingMode: "new",

      goalSelected: {
        _id: "all",
        goaltitle: "No Goal Selected",
        goalcolor: "#CCC"
      },

      thoughts: [],
      newth: {
        thought: "",
        link: "",
        image: ""
      },
      meId: '',
      reloadlist: false,
    };
  },
  async created() {
    this.meId = await localStorage.getItem("sub");
    window.addEventListener("resize", this.getWindowWidth);
    EventBus.$on("task_saved", data => {
      // this.updateTasks(data);
    });
    EventBus.$on("Work_Meeting_Person", data => {
      this.setPerson(data);
    });
    EventBus.$on("set_image", data => {
      this.newth.image = data.image;
      this.modalI.close();
    });
    this.setDay();
    this.SelectGoalList = await this.GoalsGrouped();
  },
  mounted() {
    this.$nextTick(function() {
      window.addEventListener("resize", this.getWindowWidth);
      window.addEventListener("resize", this.getWindowHeight);

      //Init
      this.getWindowWidth();
      this.getWindowHeight();
    });
  },
  computed: {},
  methods: {
    async getme() {
      const query = { original: this.user.sub };
      const me = await this.getData("people", query, {}, "none", "none", "searchextended");
      this.me = me[0];
    },

    async setDay() {
      this.isLoading = true;
      const useDate = this.getDateFull(new Date(), "none", "none", 0);
      const data = {
        dateDB: useDate.db,
        completed: false,
        istoday: true,
        goalid: '',
      };
      const dayback = await this.DataSend("post", "/getday", data, "body");
      const dd = dayback;
      this.NowDay._id = dd._id;
      this.NowDay.dateDB = useDate.db;
      (this.NowDay.dateDay = useDate.daysingle),
        (this.NowDay.dateDayTxt = useDate.dayofweektxtshort);
      this.NowDay.dateDayTxtLong = useDate.dayofweektxtlong;
      this.NowDay.dateDayOfWeek = useDate.dayofweek;
      this.NowDay.dateDayOfYear = useDate.dayofyear;
      this.NowDay.dateTS = useDate.tsm;
      let thrs = 0;
      let tmin = 0;
      let tblk = 0;

      for (let i = 0; i < dd.events.length; i++) {
        dd.events[i].hide = false;
        dd.events[i].editWorkFlow = false;
        dd.events[i].editPeople = false;
        dd.events[i].editBlocks = false;
        dd.events[i].blockHeight = dd.events[i].blocks * 25;
        thrs += dd.events[i].hours;
        tmin += dd.events[i].hours * 60;
        tmin += dd.events[i].minutes;
        tblk += dd.events[i].blocks;
        dd.events[i].newblocks = dd.events[i].blocks;
        dd.events[i].newhours = dd.events[i].hours;
        dd.events[i].newminutes = dd.events[i].minutes;
        if (!dd.events[i].useicon) {
          dd.events[i].useicon = "sparkles";
        }
        dd.events[i].ismine = true;
        let count = -1;
        let mypos = -1;
        for (const p of dd.events[i].people) {
          count += 1;
          if(p._id === this.meId) {
            mypos = count;
            dd.events[i].mypos = mypos;
            if (p.workflow === undefined) {
              dd.events[i].workflow = 'New';
            } else {
              dd.events[i].workflow = p.workflow;
            }
            if (p.workflow === 'Completed') {
              dd.events[i].completed = true;
            }
          }
        }
        if (dd.parent !== '' && mypos === -1 && dd.events[i].type === 'task') {
          dd.events[i].ismine = false;
        }
      }

      const tsec = tmin * 60;
      const timeback = this.secondsToHours(tsec);
      this.NowDay.totalblocks = tblk;
      this.NowDay.totalhours = timeback.hours;
      this.NowDay.totalminutes = timeback.minutes;
      this.NowDay.events = dd.events;
      this.isLoading = false;
    },

    getWindowWidth(event) {
      this.windowWidth = document.documentElement.clientWidth;
      if (this.windowWidth < 1028) {
        this.w.navi = true;
        this.tabs.today = true;
        this.tabs.feed = false;
        this.tabs.updates = false;
      } else {
        this.w.navi = false;
        this.tabs.today = true;
        this.tabs.feed = false;
        this.tabs.updates = true;
      }
    },
    getWindowHeight(event) {
      this.windowHeight = document.documentElement.clientHeight;
    },
    switchTabs(newtab) {
      for (const t in this.tabs) {
        this.tabs[t] = false;
      }
      this.tabs[newtab] = true;
    },

    async openTask(task, index, col, edit = false) {
      // this.changeTaskSelected(task, index);
      this.ActiveCol = index;
      let useFull = true;
      let useCanCancel = false;
      const inw = document.body.clientWidth;
      if (inw > 750) {
        useFull = false;
        useCanCancel = false;
      }
      if (task.dataid) {
        task.deleted = false;
        task.goalcolor = "#EFEFEF";
        task.goalid = task.parent;
        task.goalname = task.parenttext;
        task.tasktext = task.title;
        task._id = task.dataid;
        this.TaskActive = task;
      } else {
        this.TaskActive = task;
      }
      const that = this;
      this.modal = this.$buefy.modal.open({
        parent: this,
        component: ModalTask,
        hasModalCard: true,
        fullScreen: useFull,
        canCancel: useCanCancel,
        width: 700,
        props: {
          data: that.TaskActive, // this is the row data that gets sent to the modal
          edit,
          callBus: "Modal_Goals_Task" // name of the listner for the data coming in from the modal
        },
        events: {
          taskUpdated: value => {
            if (value.type === "delete") {
              // delete functions
            } else if (value.type === "new") {
              // new here
              const tmd = this.goalProcessAvailable(value.data.assignedto);
              value.data.tbs = tmd.rettbs;
              value.data.tbscnt = tmd.rettbscnt;
              this.Columns[this.ActiveCol].list.push(value.data);
            } else {
              if (task.dataid) {
                task.title = value.data.tasktext;
                this.saveCol(col);
              } else {
                that.TaskActive.tasktext = value.data.tasktext;
                that.TaskActive.taskcount = value.data.taskcount;
                that.TaskActive.assignedto = value.data.assignedto;
                that.TaskActive.deleted = value.data.deleted;
              }
              
              // const tmd = this.goalProcessAvailable(value.data.assignedto);
              // that.TaskActive.tbs = tmd.rettbs;
              // that.TaskActive.tbscnt = tmd.rettbscnt;
            }
          }
        }
      });
    },

    // cal:start
    colExpand(row, id) {
      row.expanded = true;
    },
    colClose(row, id) {
      row.expanded = false;
      row.editWorkFlow = false;
      row.editBlocks = false;
    },
    
    peopleExpand(row) {
      if (row.editPeople) {
        row.editPeople = false;
      } else {
        row.editPeople = true;
      }
    },

    async workflowExpand(row) {
      if (row.editWorkFlow) {
        row.editWorkFlow = false;
      } else {
        if (row.parent === '') {
          this.workflow = [
            { value: 'New', label: 'New', icon: 'sparkles' },
            { value: 'Active', label: 'Active', icon: 'sparkles' },
            { value: 'Completed', label: 'Completed', icon: 'sparkles' }
          ]
        } else {
          const getw = await this.DataGet(`/goal/workflow/${row.parent}`);
          this.workflow = getw;
        }
        row.editWorkFlow = true;
      }
    },
    editBlocksExpand(row) {
      if (row.editBlocks) {
        row.editBlocks = false;
      } else {
        row.editBlocks = true;
      }
    },

    async updateCalTasks(inrow) {
      let useid = "";
      if (inrow._id) {
        useid = inrow._id;
      }
      if (inrow.dataid) {
        useid = inrow.dataid;
      }
      const inwf = inrow.workflow;
      let useIcon = "haykal";
      const wfi = findLastIndex(this.workflow, { value: inwf });
      if (wfi !== -1) {
        useIcon = this.workflow[wfi].icon;
        inrow.useicon = this.workflow[wfi].icon;
      }
      if (inwf === "Completed") {
        inrow.completed = true;
      } else {
        inrow.completed = false;
      }
      const newdoc = {
        _id: useid,
        workflow: inwf,
        useicon: useIcon
      };
      const savetask = await this.updata(
        "tasks",
        newdoc,
        "none",
        "none",
        {},
        false
      );
      this.saveCol(this.NowDay);
      inrow.editWorkFlow = false;
    },

    updateDemo(row, col, index) {
      this.ActiveColIndex = index;
      if (row.completed) {
        row.workflow = 'Completed'
        this.reloadlist = true;
      } else {
        row.workflow = 'Active'
        this.reloadlist = false;
      }
      this.saveCol(col);
    },

    updateWorkflow(row, col, fromcheck) {
        let usewf = row.workflow;
        if (fromcheck) {
          if (row.completed) {
            if (row.duration === 'succession') {
              this.reloadlist = true;
            }
            usewf = 'Completed';
          } else {
            usewf = 'Active';
            this.reloadlist = false;
          }
          row.workflow = usewf;
          if (col !== null) { // cal task
            if (row.mypos !== -1) {
              row.people[row.mypos].workflow = usewf;
              const data = {
                day: col.dateDB,
                dataid: row.dataid,
                mypos: row.mypos,
                workflow: usewf
              }
              // this.DataSend('post', '/updateworkflow', data);
              this.saveCol(col, data);
            } else {
              this.saveCol(col);
              if(row.parent === '') {
                const hasrecord = array.findLastIndex(this.rogueTasks, { '_id': row.dataid });
                if (hasrecord !== -1) {
                  this.rogueTasks[hasrecord].completed = row.completed;
                  this.rogueTasks[hasrecord].workflow = row.workflow;
                  const tdata = {
                    _id: row.dataid,
                    completed: row.completed,
                    workflow: row.workflow
                  };
                  this.DataSend('post', '/task/updatefew', tdata);
                }
              }
            }
          } else { // rogue tasks
            const tdata = {
              _id: row._id,
              completed: row.completed,
              workflow: row.workflow
            };
            this.DataSend('post', '/task/updatefew', tdata);
            for (const r of this.calList) {
              const hasrecord = array.findLastIndex(r.events, { 'dataid': row._id });
              if (hasrecord !== -1) {
                const urow = r.events[hasrecord];
                urow.completed = row.completed;
                urow.workflow = row.workflow;
                this.saveCol(r);
              }
            }
          }
        } else {
          if (row.mypos !== -1) {
            row.people[row.mypos].workflow = usewf;
            const data = {
              day: col.dateDB,
              dataid: row.dataid,
              mypos: row.mypos,
              workflow: usewf
            }
            // this.DataSend('post', '/updateworkflow', data);
            if(usewf === 'Completed') {
              row.completed = true;
            } else {
              row.completed = false;
            }
            this.saveCol(col, data);
          } else {
            this.saveCol(col);
            if(row.parent === '') {
              const hasrecord = array.findLastIndex(this.rogueTasks, { '_id': row.dataid });
              if (hasrecord !== -1) {
                this.rogueTasks[hasrecord].completed = row.completed;
                this.rogueTasks[hasrecord].workflow = row.workflow;
                const tdata = {
                  _id: row.dataid,
                  completed: row.completed,
                  workflow: row.workflow
                };
                this.DataSend('post', '/task/updatefew', tdata);
              }
            }
          }
        }
        row.editWorkFlow = false;
    },

    async updateCalSteps(inrow, tskid) {
      const newWF = inrow.workflow;
      let newWFI = "haykal";
      const wfi = findLastIndex(this.workflow, { value: inrow.workflow });
      if (wfi !== -1) {
        newWFI = this.workflow[wfi].icon;
      }
      let tasksteps = [];

      let found = 0;
      for (const e of this.NowDay.events) {
        // main card
        if (e.dataid === tskid) {
          found++;
          for (const s of e.steps) {
            // if(s.steptext)
            if (s.steptext === inrow.steptext) {
              s.workflow = newWF;
              s.useicon = newWFI;
            }
          }
        }
        // tasks
        for (const t of e.tasks) {
          if (t._id === tskid) {
            for (const st of t.steps) {
              if (st.steptext === inrow.steptext) {
                st.workflow = newWF;
                st.useicon = newWFI;
              }
            }
            tasksteps = t.steps;
            found++;
          }
        }
      }
      if (found !== 0) {
        this.saveCol(this.NowDay);
      }

      // save the task
      const newdoc = {
        _id: tskid,
        workflow: newWF,
        useicon: newWFI,
        steps: tasksteps
      };
      const savedtask = await this.updata(
        "tasks",
        newdoc,
        "none",
        "none",
        {},
        false
      );
    },

    async saveCol(col, upw = null) {
      let thrs = 0;
      let tmin = 0;
      let tblk = 0;
      for (let i = 0; i < col.events.length; i++) {
        thrs += col.events[i].hours;
        tmin += col.events[i].hours * 60;
        tmin += col.events[i].minutes;
        tblk += col.events[i].blocks;
      }
      const tsec = tmin * 60;
      const timeback = this.secondsToHours(tsec);
      col.totalblocks = tblk;
      col.totalhours = timeback.hours;
      col.totalminutes = timeback.minutes;

      const newDoc = {
        _id: col._id,
        dayts: col.dateTS,
        events: col.events,
        totalblocks: col.totalblocks,
        totalhours: col.totalhours,
        totalminutes: col.totalminutes
      };
      await this.DataSend("post", "/savecal", newDoc, '', false);
      if(upw !== null) {
        this.DataSend('post', '/updateworkflow', upw, '', false);
      }
      if (this.reloadlist) {
        this.setDay(this.NowDay.dateDB, 0);
      }
    },

    removeColItem(col, element, index, key) {
      this.$buefy.dialog.confirm({
        title: "",
        message:
          "Are you sure you want to <b>delete</b> this item This action cannot be undone.",
        confirmText: "Delete",
        type: "is-danger",
        hasIcon: true,
        icon: 'exclamation',
        onConfirm: async () => {
          this.NowDay.events.splice(key, 1);
          this.saveCol(this.NowDay);
        }
      });
    },
    // cal:end

    // add a task: start
    taskAddOpen(area, day) {
      this.ActiveCol = day;
      this.newTaskArea = area;
      this.newTask = { ...this.task };
      const id = new ObjectID();
      const idout = id.str;
      this.newTask._id = 'new';
      this.newTask.estminutes = 15;
      this.newTask.sub = this.user.sub;
      this.newTask.subname = this.user.subname;
      this.newTask.parentid = '';
      this.showTask = true;
    },

    taskClose() {
      this.showTask = false;
    },

    setTaskGoal() {
      if (this.goalSelected._id !== "all") {
        this.newTask.goalid = this.goalSelected._id;
        this.newTask.goalname = this.goalSelected.goaltitle;
        this.newTask.goalcolor = this.goalSelected.goalcolor;
      }
    },

    colChanged(evt, col) {
      this.saveCol(col);
    },

    colClone(row) {
      const newcalobj = { ...this.dayobj };
      newcalobj.calid = this.getUUID(true);
      newcalobj.dataid = row._id;
      newcalobj.type = "task";
      newcalobj.duration = row.durantiontype;
      newcalobj.subtype = row.taskcat;
      newcalobj.title = row.tasktext;
      newcalobj.description = row.taskdescription;
      newcalobj.parent = row.goalid;
      newcalobj.parenttext = row.goalname;
      newcalobj.blocks = row.estblocks;
      newcalobj.hours = row.esthours;
      newcalobj.minutes = row.estminutes;
      newcalobj.steps = row.steps;
      newcalobj.tasks = [];
      newcalobj.workflow = row.workflow;
      const wfi = findLastIndex(this.workflow, { value: row.workflow });
      if (wfi !== -1) {
        newcalobj.useicon = this.workflow[wfi].icon;
      }
      newcalobj.colour = row.goalcolor;
      newcalobj.expanded = false;
      newcalobj.completed = false;
      return newcalobj;
    },

    async taskAdd() {
      if (this.newTask.tasktext === "") {
        this.$buefy.snackbar.open({
          message: "Please describe your task",
          position: "is-top"
        });
        return;
      }
      // process blocks
      const totalmins = this.newTask.esthours * 60 + this.newTask.estminutes;
      this.newTask.estblocks = totalmins / 15;
      // const newAss = {
      //   _id: this.user.sub,
      //   firstname: this.user.subname,
      //   role: "Doer",
      //   workflow: "New",
      //   completed: false
      // };
      // this.newTask.assignedto.push(newAss);

      let usegoalid = '';

      if (this.newTask.goalid !== "") {
        usegoalid = this.newTask.goalid;
        this.previousgoalid = this.newTask.goalid;
      } else {
        if (this.previousgoalid !== '') {
          usegoalid = this.previousgoalid;
        }
      }

      if (usegoalid !== "") {
        const query = { _id: usegoalid };
        const options = {};
        const getGoal = await this.DataGet(`/goal/${usegoalid}`)
        const usegoal = getGoal;
        this.newTask.goalcolor = usegoal.goalcolor;
        this.newTask.goalid = usegoalid;
        this.newTask.goalname = usegoal.goaltitle;
        this.newTask.availableto = usegoal.availableto;
        this.newTask.clientid = usegoal.clientid;
        this.newTask.client = usegoal.client;
      }

      const addt = await this.DataSend("put", "/task", this.newTask);
      const tsk = addt.rows;
      this.newTask._id = tsk._id;
      // if (this.newTask.goalid === "") {
      //   this.rogueTasks.unshift(this.newTask);
      // }
      if (this.newTaskArea === "day") {
        const newcalitem = this.colClone(this.newTask);
        this.ActiveCol.events.push(newcalitem);
        this.saveCol(this.ActiveCol);
      }
      this.taskClose();
    },
    // add a task: end

  },
  destroyed() {
    window.removeEventListener("resize", this.getWindowWidth);
  }
};
</script>

<style>
.homeNavi {
  display: flex;
}
.tab {
  border-bottom: 1px solid #efefef;
  padding: 5px 10px;
  cursor: pointer;
}
.tabactive {
  border-bottom: 1px solid #cc0000;
}
.contentbody {
  height: 100%;
}
.homeBody {
  height: 100%;
  display: flex;
}
.homeTasks {
  /* min-width: 40%; */
  min-width: 60%;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.homeCols {
  /* min-width: 30%; */
  min-width: 40%;
  display: flex;
  flex-direction: column;
  height: 100%;
  border-left: 1px solid #efefef;
}
.homeHeader {
  padding: 5px;
}
.homeContent {
  position: relative;
  overflow: hidden;
  flex-grow: 1;
  height: 100%;
}
.homeFooter {
}
.homeScroller {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
}
.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}
.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}
@media (max-width: 1028px) {
  .homeTasks {
    width: 100%;
  }
  .homeCols {
    width: 100%;
  }
}
</style>
