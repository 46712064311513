<template>
  <div class="navibar" v-if="naviShow">
    <div class="sidelogo">
      <img src="/logos/dne-80.png" />
    </div>
    <div class="navi-body">
      <router-link
        tag="p"
        to="/"
        class="navi-button"
        v-bind:class="selectedClass('home')"
      >
        <div>
          <font-awesome-icon
            :icon="['fal', 'home-alt']"
            size="2x"
          ></font-awesome-icon>
        </div>
        <div class="navlabel">Home</div>
      </router-link>

      <router-link
        tag="p"
        to="/work"
        class="navi-button"
        v-bind:class="selectedClass('work')"
      >
        <div>
          <font-awesome-icon
            :icon="['fal', 'calendar-week']"
            size="2x"
          ></font-awesome-icon>
        </div>
        <div class="navlabel">Calendar</div>
      </router-link>

      <router-link
        tag="p"
        to="/goals"
        class="navi-button"
        v-bind:class="selectedClass('goals')"
      >
        <div>
          <font-awesome-icon
            :icon="['fal', 'bullseye-arrow']"
            size="2x"
          ></font-awesome-icon>
        </div>
        <div class="navlabel">Projects</div>
      </router-link>

      <router-link tag="p" to="/tasks" class="navi-button" v-bind:class="selectedClass('tasks')">
        <div>
          <font-awesome-icon
            :icon="['fal', 'check-square']"
            size="2x"
          ></font-awesome-icon>
        </div>
        <div class="navlabel">Tasks</div>
      </router-link>
      
      <router-link tag="p" to="/blueprints" class="navi-button" v-bind:class="selectedClass('blueprints')">
        <div>
          <font-awesome-icon
            :icon="['fal', 'ruler-triangle']"
            size="2x"
          ></font-awesome-icon>
        </div>
        <div class="navlabel">Blueprint Store</div>
      </router-link>
      
      <router-link tag="p" to="/mindmaps" class="navi-button" v-bind:class="selectedClass('mindmaps')">
        <div>
          <font-awesome-icon
            :icon="['fal', 'chart-network']"
            size="2x"
          ></font-awesome-icon>
        </div>
        <div class="navlabel">Mind Maps</div>
      </router-link>

      <router-link tag="p" to="/trees" class="navi-button" v-bind:class="selectedClass('trees')">
        <div>
          <font-awesome-icon
            :icon="['fal', 'trees']"
            size="2x"
          ></font-awesome-icon>
        </div>
        <div class="navlabel">Trees</div>
      </router-link>

      <!-- <router-link tag="p" to="/docs" class="navi-button" v-bind:class="selectedClass('docs')">
        <div>
          <font-awesome-icon :icon="['fal', 'sticky-note']" size="2x"></font-awesome-icon>
        </div>
        <div class="navlabel">Docs</div>
      </router-link> -->

      <router-link tag="p" to="/notes" class="navi-button" v-bind:class="selectedClass('notes')">
        <div>
          <font-awesome-icon :icon="['fal', 'sticky-note']" size="2x"></font-awesome-icon>
        </div>
        <div class="navlabel">Notes</div>
      </router-link>

      <router-link tag="p" to="/compare" class="navi-button" v-bind:class="selectedClass('compare')">
        <div>
          <font-awesome-icon :icon="['fal', 'exchange-alt']" size="2x"></font-awesome-icon>
        </div>
        <div class="navlabel">Compare</div>
      </router-link>

      <router-link tag="p" to="/peed" class="navi-button" v-bind:class="selectedClass('peed')">
        <div>
          <font-awesome-icon :icon="['fal', 'toilet']" size="2x"></font-awesome-icon>
        </div>
        <div class="navlabel">PEED</div>
      </router-link>

      <router-link tag="p" to="/network" class="navi-button" v-bind:class="selectedClass('network')">
        <div>
          <font-awesome-icon :icon="['fal', 'people-arrows']" size="2x"></font-awesome-icon>
        </div>
        <div class="navlabel">Network</div>
      </router-link>

      <router-link tag="p" to="/people" class="navi-button" v-bind:class="selectedClass('people')">
        <div>
          <font-awesome-icon :icon="['fal', 'users']" size="2x"></font-awesome-icon>
        </div>
        <div class="navlabel">CRM</div>
      </router-link>

      <router-link tag="p" to="/teams" class="navi-button" v-bind:class="selectedClass('teams')">
        <div>
          <font-awesome-icon :icon="['fal', 'users']" size="2x"></font-awesome-icon>
        </div>
        <div class="navlabel">Teams</div>
      </router-link>
      <!-- <router-link tag="p" to="/auth" v-if="!signedIn">
        <a>Sign Up / Sign In</a>
      </router-link>-->
      <router-link tag="p" to="/settings/all" class="navi-button" v-bind:class="selectedClass('settings')">
        <div>
          <font-awesome-icon :icon="['fal', 'user-crown']" size="2x"></font-awesome-icon>
        </div>
        <div class="navlabel">Profile &amp; Settings</div>
      </router-link>

      <router-link tag="p" to="/account" class="navi-button" v-bind:class="selectedClass('account')">
        <div>
          <font-awesome-icon :icon="['fal', 'cogs']" size="2x"></font-awesome-icon>
        </div>
        <div class="navlabel">Account</div>
      </router-link>

      <div class="navi-button" @click="logout()">
        <div>
          <font-awesome-icon :icon="['fal', 'sign-out']" size="2x"></font-awesome-icon>
        </div>
        <div class="navlabel">Sign Out</div>
      </div>
    </div>
    <div class="navi-footer">
      
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mapActions } from "vuex";
import store from "../store";
// Vue.config.productionTip = false;
import { Utils } from '../mixins/utils.js';
import { EventBus } from "../event-bus.js";

export default {
  name: "Navi",
  mixins: [Utils],
  store,
  data() {
    return {
      naviShow: true,
      signedIn: false,
      userout: {
        sub: "",
        subname: "",
        email: "",
        admin: ""
      },
      currentroute: "home",
      isAdmin: false,
      sites: true,
      users: false,
      account: true,
      settings: false,
      workflow: false,
      usersadmin: false
    };
  },
  watch: {
    "$store.state.admin": function() {
      this.isAdmin = this.$store.state.admin;
    }
  },
  beforeCreate() {
    // if (localStorage.getItem("TeamLive") === null) {
    //       localStorage.setItem('TeamLive', '');
    //     }
    //     if (localStorage.getItem("TeamLiveName") === null) {
    //       localStorage.setItem('TeamLiveName', 'Self');
    //     }
    //     if (localStorage.getItem("TeamLiveColour") === null) {
    //       localStorage.setItem('TeamLiveColour', '');
    //     }
  },

  created() {
    this.checkuserlevel();
    this.currentroute = this.$router.currentRoute.name;
    EventBus.$on("showHideNavi", data => {
      if (this.naviShow) {
        this.naviShow = false;
        this.$store.dispatch("setNavi", false);
      } else {
        this.naviShow = true;
        this.$store.dispatch("setNavi", true);
      }
    });
    EventBus.$on("check_usr", data => {
      this.checkuserlevel();
    });
    this.routeWatcher = this.$watch(
      function() {
        return this.$route;
      },
      function(route) {
        this.checkSubscription();
        this.getUnreadCount();
        this.currentroute = route.name;
        this.setWidth();
      }
    );
    this.setWidth();
  },

  methods: {
    ...mapActions([
      "setUser", // map `this.increment()` to `this.$store.dispatch('increment')`
      "setNavi",
      "setAdmin",
      "setPermissions"
    ]),
    setWidth() {
      const inw = document.body.clientWidth;
      const inh = document.body.clientHeight;
      if (inw > 750) {
       this.naviShow = true;
      } else {
        this.naviShow = false;
      }
    },
    selectedClass: function(name) {
      return {
        // v-bind:class="selectedClass('home')"
        "navi-button-selected": this.currentroute === name
      };
    },
    checkuserlevel() {
      if (localStorage.getItem("isadmin") !== null) {
        if (localStorage.getItem("isadmin") === "true") {
          this.isAdmin = true;
          this.sites = true;
          this.users = true;
          this.account = true;
          this.settings = true;
          this.workflow = true;
          this.usersadmin = true;
        } else {
          const permsin = localStorage.getItem("permissions");
          const permissions = JSON.parse(permsin);
          for (const p of permissions) {
            this[p.area] = true;
          }
        }
      }
    },
    logout() {
      window.localStorage.clear();
      this.$store.dispatch("setAdmin", false);
      this.$store.dispatch("setPermissions", []);
      this.$router.push({ name: "login" });
    }
  }
};
</script>

<style>
.navibar {
  background-color: #0096e6;
  height: 100%;
  overflow-y: auto;
  min-width: 60px;
  max-width: 60px;
  text-align: center;
  display: flex;
  flex-direction: column;
}
.navi-body {
  flex-grow: 1;
}
.sidelogo {
  margin: 10px 5px 15px 5px;
  border-bottom: 0.5px dotted #bbb9d1;
}
.navi-row {
  margin-bottom: 10px;
}
.navi-button {
  background-color: unset;
  border: unset;
  color: #ffffff;
  font-size: 12px;
  margin-top: 7px;
  margin-bottom: 11px;
  cursor: pointer;
}
.navi-button-selected {
  color: #FF0000;
}
.navi-button:focus {
  color: #CC0000;
}
.navlabel {
  color: #cce6ff;
  margin-top: 5px;
  font-size: 11px;
}
.navibar .Button__button___1FrBC {
  min-width: unset;
  font-size: 10px;
}
</style>
