<template>
  <div class="outer">
    <div class="container-login100">
      <div class="wrap-login100 p-l-50 p-r-50 p-b-30">
        <form class="login100-form validate-form">
          <div class="position-relative image-side pt11">
            <a href="https://dne.life"><img src="/logos/dne-250.png" /></a>
          </div>
          <div class="txtc txtlg">The management tool created for entrepreneurs, leaders, planners, coaches, mentors, trainers and educators. All your business growth resources in one place.</div>

          <div class="txtc w-full mv15" style="font-size: 1.3em;">
            <div class="mb5"> Already a member? </div> <router-link tag="button" class="button is-danger is-fullwidth" to="/login">Go to login</router-link>
          </div>
          <div style="height: 20px;"></div>

          <span class="login100-form-title p-b-55 mb11">
            Register
          </span>

          <b-field label="First Name (Required)">
            <b-input v-model="firstname" expanded></b-input>
          </b-field>
          <b-field label="Last Name (Required)">
            <b-input v-model="lastname" expanded></b-input>
          </b-field>
          <b-field label="Email (Required)">
            <b-input v-model="email" type="email" required expanded></b-input>
          </b-field>
          <b-field label="Country (Required)">
            <b-select placeholder="Select a country" v-model="country" expanded>
                <option
                    v-for="(option, index) in countries"
                    :value="option.countryCode"
                    :key="index">
                    {{ option.label }}
                </option>
            </b-select>
        </b-field>
        <b-field label="Business Name">
            <b-input v-model="coname" expanded></b-input>
          </b-field>
          <b-field label="Contact No.">
            <b-input v-model="mobile" expanded></b-input>
          </b-field>
          <b-field label="Password (Required)">
            <b-input type="password" v-model="password" password-reveal>
            </b-input>
          </b-field>
          <!-- <div class="mv15">
            <a href="/user/forgot-password">Forget password?</a>
          </div> -->
          <div><b-button expanded @click="register">Proceed</b-button></div>

          
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions } from "vuex";
import store from "../store";
import { EventBus } from "../event-bus.js";
import { Utils } from "../mixins/utils.js";
export default {
  mixins: [Utils],
  name: "Login",
  store,
  data() {
    return {
      firstname: "",
      lastname: "",
      coname: "",
      mobile: "",
      email: "",
      password: "",
      country: "GB",
      showProceed: true,
      showPass: false,
      showTfa: false,
      countries: [
        { countryCode: 'GB', value: '44', label: 'UK (+44)' },
        { countryCode: 'US', value: '1', label: 'USA (+1)' },
        { countryCode: 'IN', value: '91', label: 'India (+91)' },
        { countryCode: 'ZA', value: '27', label: 'South Africa (+27)' },
        { countryCode: 'AU', value: '61', label: 'Australia (+61)' },
        { countryCode: 'CA', value: '1', label: 'Canada (+1)' },
        { countryCode: '', value: '', label: '---' },
        { countryCode: 'DZ', value: '213', label: 'Algeria (+213)' },
        { countryCode: 'AD', value: '376', label: 'Andorra (+376)' },
        { countryCode: 'AO', value: '244', label: 'Angola (+244)' },
        { countryCode: 'AI', value: '1264', label: 'Anguilla (+1264)' },
        { countryCode: 'AG', value: '1268', label: 'Antigua & Barbuda (+1268)' },
        { countryCode: 'AR', value: '54', label: 'Argentina (+54)' },
        { countryCode: 'AM', value: '374', label: 'Armenia (+374)' },
        { countryCode: 'AW', value: '297', label: 'Aruba (+297)' },
        { countryCode: 'AU', value: '61', label: 'Australia (+61)' },
        { countryCode: 'AT', value: '43', label: 'Austria (+43)' },
        { countryCode: 'AZ', value: '994', label: 'Azerbaijan (+994)' },
        { countryCode: 'BS', value: '1242', label: 'Bahamas (+1242)' },
        { countryCode: 'BH', value: '973', label: 'Bahrain (+973)' },
        { countryCode: 'BD', value: '880', label: 'Bangladesh (+880)' },
        { countryCode: 'BB', value: '1246', label: 'Barbados (+1246)' },
        { countryCode: 'BY', value: '375', label: 'Belarus (+375)' },
        { countryCode: 'BE', value: '32', label: 'Belgium (+32)' },
        { countryCode: 'BZ', value: '501', label: 'Belize (+501)' },
        { countryCode: 'BJ', value: '229', label: 'Benin (+229)' },
        { countryCode: 'BM', value: '1441', label: 'Bermuda (+1441)' },
        { countryCode: 'BT', value: '975', label: 'Bhutan (+975)' },
        { countryCode: 'BO', value: '591', label: 'Bolivia (+591)' },
        { countryCode: 'BA', value: '387', label: 'Bosnia Herzegovina (+387)' },
        { countryCode: 'BW', value: '267', label: 'Botswana (+267)' },
        { countryCode: 'BR', value: '55', label: 'Brazil (+55)' },
        { countryCode: 'BN', value: '673', label: 'Brunei (+673)' },
        { countryCode: 'BG', value: '359', label: 'Bulgaria (+359)' },
        { countryCode: 'BF', value: '226', label: 'Burkina Faso (+226)' },
        { countryCode: 'BI', value: '257', label: 'Burundi (+257)' },
        { countryCode: 'KH', value: '855', label: 'Cambodia (+855)' },
        { countryCode: 'CM', value: '237', label: 'Cameroon (+237)' },
        { countryCode: 'CA', value: '1', label: 'Canada (+1)' },
        { countryCode: 'CV', value: '238', label: 'Cape Verde Islands (+238)' },
        { countryCode: 'KY', value: '1345', label: 'Cayman Islands (+1345)' },
        { countryCode: 'CF', value: '236', label: 'Central African Republic (+236)' },
        { countryCode: 'CL', value: '56', label: 'Chile (+56)' },
        { countryCode: 'CN', value: '86', label: 'China (+86)' },
        { countryCode: 'CO', value: '57', label: 'Colombia (+57)' },
        { countryCode: 'KM', value: '269', label: 'Comoros (+269)' },
        { countryCode: 'CG', value: '242', label: 'Congo (+242)' },
        { countryCode: 'CK', value: '682', label: 'Cook Islands (+682)' },
        { countryCode: 'CR', value: '506', label: 'Costa Rica (+506)' },
        { countryCode: 'HR', value: '385', label: 'Croatia (+385)' },
        { countryCode: 'CU', value: '53', label: 'Cuba (+53)' },
        { countryCode: 'CW', value: '599', label: 'Curacao (+599)' },
        { countryCode: 'CY', value: '90392', label: 'Cyprus North (+90392)' },
        { countryCode: 'CY', value: '357', label: 'Cyprus South (+357)' },
        { countryCode: 'CZ', value: '42', label: 'Czech Republic (+42)' },
        { countryCode: 'DK', value: '45', label: 'Denmark (+45)' },
        { countryCode: 'DJ', value: '253', label: 'Djibouti (+253)' },
        { countryCode: 'DM', value: '1809', label: 'Dominica (+1809)' },
        { countryCode: 'DO', value: '1809', label: 'Dominican Republic (+1809)' },
        { countryCode: 'EC', value: '593', label: 'Ecuador (+593)' },
        { countryCode: 'EG', value: '20', label: 'Egypt (+20)' },
        { countryCode: 'SV', value: '503', label: 'El Salvador (+503)' },
        { countryCode: 'GQ', value: '240', label: 'Equatorial Guinea (+240)' },
        { countryCode: 'ER', value: '291', label: 'Eritrea (+291)' },
        { countryCode: 'EE', value: '372', label: 'Estonia (+372)' },
        { countryCode: 'ET', value: '251', label: 'Ethiopia (+251)' },
        { countryCode: 'FK', value: '500', label: 'Falkland Islands (+500)' },
        { countryCode: 'FO', value: '298', label: 'Faroe Islands (+298)' },
        { countryCode: 'FJ', value: '679', label: 'Fiji (+679)' },
        { countryCode: 'FI', value: '358', label: 'Finland (+358)' },
        { countryCode: 'FR', value: '33', label: 'France (+33)' },
        { countryCode: 'GF', value: '594', label: 'French Guiana (+594)' },
        { countryCode: 'PF', value: '689', label: 'French Polynesia (+689)' },
        { countryCode: 'GA', value: '241', label: 'Gabon (+241)' },
        { countryCode: 'GM', value: '220', label: 'Gambia (+220)' },
        { countryCode: 'GE', value: '7880', label: 'Georgia (+7880)' },
        { countryCode: 'DE', value: '49', label: 'Germany (+49)' },
        { countryCode: 'GH', value: '233', label: 'Ghana (+233)' },
        { countryCode: 'GI', value: '350', label: 'Gibraltar (+350)' },
        { countryCode: 'GR', value: '30', label: 'Greece (+30)' },
        { countryCode: 'GL', value: '299', label: 'Greenland (+299)' },
        { countryCode: 'GD', value: '1473', label: 'Grenada (+1473)' },
        { countryCode: 'GP', value: '590', label: 'Guadeloupe (+590)' },
        { countryCode: 'GU', value: '671', label: 'Guam (+671)' },
        { countryCode: 'GT', value: '502', label: 'Guatemala (+502)' },
        { countryCode: 'GN', value: '224', label: 'Guinea (+224)' },
        { countryCode: 'GW', value: '245', label: 'Guinea - Bissau (+245)' },
        { countryCode: 'GY', value: '592', label: 'Guyana (+592)' },
        { countryCode: 'HT', value: '509', label: 'Haiti (+509)' },
        { countryCode: 'HN', value: '504', label: 'Honduras (+504)' },
        { countryCode: 'HK', value: '852', label: 'Hong Kong (+852)' },
        { countryCode: 'HU', value: '36', label: 'Hungary (+36)' },
        { countryCode: 'IS', value: '354', label: 'Iceland (+354)' },
        { countryCode: 'IN', value: '91', label: 'India (+91)' },
        { countryCode: 'ID', value: '62', label: 'Indonesia (+62)' },
        { countryCode: 'IR', value: '98', label: 'Iran (+98)' },
        { countryCode: 'IQ', value: '964', label: 'Iraq (+964)' },
        { countryCode: 'IE', value: '353', label: 'Ireland (+353)' },
        { countryCode: 'IL', value: '972', label: 'Israel (+972)' },
        { countryCode: 'IT', value: '39', label: 'Italy (+39)' },
        { countryCode: 'JM', value: '1876', label: 'Jamaica (+1876)' },
        { countryCode: 'JP', value: '81', label: 'Japan (+81)' },
        { countryCode: 'JO', value: '962', label: 'Jordan (+962)' },
        { countryCode: 'KZ', value: '7', label: 'Kazakhstan (+7)' },
        { countryCode: 'KE', value: '254', label: 'Kenya (+254)' },
        { countryCode: 'KI', value: '686', label: 'Kiribati (+686)' },
        { countryCode: 'KP', value: '850', label: 'Korea North (+850)' },
        { countryCode: 'KR', value: '82', label: 'Korea South (+82)' },
        { countryCode: 'KW', value: '965', label: 'Kuwait (+965)' },
        { countryCode: 'KG', value: '996', label: 'Kyrgyzstan (+996)' },
        { countryCode: 'LA', value: '856', label: 'Laos (+856)' },
        { countryCode: 'LV', value: '371', label: 'Latvia (+371)' },
        { countryCode: 'LB', value: '961', label: 'Lebanon (+961)' },
        { countryCode: 'LS', value: '266', label: 'Lesotho (+266)' },
        { countryCode: 'LR', value: '231', label: 'Liberia (+231)' },
        { countryCode: 'LY', value: '218', label: 'Libya (+218)' },
        { countryCode: 'LI', value: '417', label: 'Liechtenstein (+417)' },
        { countryCode: 'LT', value: '370', label: 'Lithuania (+370)' },
        { countryCode: 'LU', value: '352', label: 'Luxembourg (+352)' },
        { countryCode: 'MO', value: '853', label: 'Macao (+853)' },
        { countryCode: 'MK', value: '389', label: 'Macedonia (+389)' },
        { countryCode: 'MG', value: '261', label: 'Madagascar (+261)' },
        { countryCode: 'MW', value: '265', label: 'Malawi (+265)' },
        { countryCode: 'MY', value: '60', label: 'Malaysia (+60)' },
        { countryCode: 'MV', value: '960', label: 'Maldives (+960)' },
        { countryCode: 'ML', value: '223', label: 'Mali (+223)' },
        { countryCode: 'MT', value: '356', label: 'Malta (+356)' },
        { countryCode: 'MH', value: '692', label: 'Marshall Islands (+692)' },
        { countryCode: 'MQ', value: '596', label: 'Martinique (+596)' },
        { countryCode: 'MR', value: '222', label: 'Mauritania (+222)' },
        { countryCode: 'YT', value: '269', label: 'Mayotte (+269)' },
        { countryCode: 'MX', value: '52', label: 'Mexico (+52)' },
        { countryCode: 'FM', value: '691', label: 'Micronesia (+691)' },
        { countryCode: 'MD', value: '373', label: 'Moldova (+373)' },
        { countryCode: 'MC', value: '377', label: 'Monaco (+377)' },
        { countryCode: 'MN', value: '976', label: 'Mongolia (+976)' },
        { countryCode: 'MS', value: '1664', label: 'Montserrat (+1664)' },
        { countryCode: 'MA', value: '212', label: 'Morocco (+212)' },
        { countryCode: 'MZ', value: '258', label: 'Mozambique (+258)' },
        { countryCode: 'MN', value: '95', label: 'Myanmar (+95)' },
        { countryCode: 'NA', value: '264', label: 'Namibia (+264)' },
        { countryCode: 'NR', value: '674', label: 'Nauru (+674)' },
        { countryCode: 'NP', value: '977', label: 'Nepal (+977)' },
        { countryCode: 'NL', value: '31', label: 'Netherlands (+31)' },
        { countryCode: 'NC', value: '687', label: 'New Caledonia (+687)' },
        { countryCode: 'NZ', value: '64', label: 'New Zealand (+64)' },
        { countryCode: 'NI', value: '505', label: 'Nicaragua (+505)' },
        { countryCode: 'NE', value: '227', label: 'Niger (+227)' },
        { countryCode: 'NG', value: '234', label: 'Nigeria (+234)' },
        { countryCode: 'NU', value: '683', label: 'Niue (+683)' },
        { countryCode: 'NF', value: '672', label: 'Norfolk Islands (+672)' },
        { countryCode: 'NP', value: '670', label: 'Northern Marianas (+670)' },
        { countryCode: 'NO', value: '47', label: 'Norway (+47)' },
        { countryCode: 'OM', value: '968', label: 'Oman (+968)' },
        { countryCode: 'PW', value: '680', label: 'Palau (+680)' },
        { countryCode: 'PA', value: '507', label: 'Panama (+507)' },
        { countryCode: 'PG', value: '675', label: 'Papua New Guinea (+675)' },
        { countryCode: 'PY', value: '595', label: 'Paraguay (+595)' },
        { countryCode: 'PE', value: '51', label: 'Peru (+51)' },
        { countryCode: 'PH', value: '63', label: 'Philippines (+63)' },
        { countryCode: 'PL', value: '48', label: 'Poland (+48)' },
        { countryCode: 'PT', value: '351', label: 'Portugal (+351)' },
        { countryCode: 'PR', value: '1787', label: 'Puerto Rico (+1787)' },
        { countryCode: 'QA', value: '974', label: 'Qatar (+974)' },
        { countryCode: 'RE', value: '262', label: 'Reunion (+262)' },
        { countryCode: 'RO', value: '40', label: 'Romania (+40)' },
        { countryCode: 'RU', value: '7', label: 'Russia (+7)' },
        { countryCode: 'RW', value: '250', label: 'Rwanda (+250)' },
        { countryCode: 'SM', value: '378', label: 'San Marino (+378)' },
        { countryCode: 'ST', value: '239', label: 'Sao Tome & Principe (+239)' },
        { countryCode: 'SA', value: '966', label: 'Saudi Arabia (+966)' },
        { countryCode: 'SN', value: '221', label: 'Senegal (+221)' },
        { countryCode: 'CS', value: '381', label: 'Serbia (+381)' },
        { countryCode: 'SC', value: '248', label: 'Seychelles (+248)' },
        { countryCode: 'SL', value: '232', label: 'Sierra Leone (+232)' },
        { countryCode: 'SG', value: '65', label: 'Singapore (+65)' },
        { countryCode: 'SX', value: '1', label: 'Sint Maarten (+1)' },
        { countryCode: 'SK', value: '421', label: 'Slovak Republic (+421)' },
        { countryCode: 'SI', value: '386', label: 'Slovenia (+386)' },
        { countryCode: 'SB', value: '677', label: 'Solomon Islands (+677)' },
        { countryCode: 'SO', value: '252', label: 'Somalia (+252)' },
        { countryCode: 'ZA', value: '27', label: 'South Africa (+27)' },
        { countryCode: 'ES', value: '34', label: 'Spain (+34)' },
        { countryCode: 'LK', value: '94', label: 'Sri Lanka (+94)' },
        { countryCode: 'SH', value: '290', label: 'St. Helena (+290)' },
        { countryCode: 'KN', value: '1869', label: 'St. Kitts (+1869)' },
        { countryCode: 'SC', value: '1758', label: 'St. Lucia (+1758)' },
        { countryCode: 'SD', value: '249', label: 'Sudan (+249)' },
        { countryCode: 'SR', value: '597', label: 'Suriname (+597)' },
        { countryCode: 'SZ', value: '268', label: 'Swaziland (+268)' },
        { countryCode: 'SE', value: '46', label: 'Sweden (+46)' },
        { countryCode: 'CH', value: '41', label: 'Switzerland (+41)' },
        { countryCode: 'SI', value: '963', label: 'Syria (+963)' },
        { countryCode: 'TW', value: '886', label: 'Taiwan (+886)' },
        { countryCode: 'TJ', value: '7', label: 'Tajikstan (+7)' },
        { countryCode: 'TH', value: '66', label: 'Thailand (+66)' },
        { countryCode: 'TG', value: '228', label: 'Togo (+228)' },
        { countryCode: 'TO', value: '676', label: 'Tonga (+676)' },
        { countryCode: 'TT', value: '1868', label: 'Trinidad & Tobago (+1868)' },
        { countryCode: 'TN', value: '216', label: 'Tunisia (+216)' },
        { countryCode: 'TR', value: '90', label: 'Turkey (+90)' },
        { countryCode: 'TM', value: '7', label: 'Turkmenistan (+7)' },
        { countryCode: 'TM', value: '993', label: 'Turkmenistan (+993)' },
        { countryCode: 'TC', value: '1649', label: 'Turks & Caicos Islands (+1649)' },
        { countryCode: 'TV', value: '688', label: 'Tuvalu (+688)' },
        { countryCode: 'UG', value: '256', label: 'Uganda (+256)' },
        { countryCode: 'UA', value: '380', label: 'Ukraine (+380)' },
        { countryCode: 'AE', value: '971', label: 'United Arab Emirates (+971)' },
        { countryCode: 'GB', value: '44', label: 'United Kingdom (+44)' },
        { countryCode: 'US', value: '1', label: 'United States of America (+1)' },
        { countryCode: 'UY', value: '598', label: 'Uruguay (+598)' },
        { countryCode: 'UZ', value: '7', label: 'Uzbekistan (+7)' },
        { countryCode: 'VU', value: '678', label: 'Vanuatu (+678)' },
        { countryCode: 'VA', value: '379', label: 'Vatican City (+379)' },
        { countryCode: 'VE', value: '58', label: 'Venezuela (+58)' },
        { countryCode: 'VN', value: '84', label: 'Vietnam (+84)' },
        { countryCode: 'VG', value: '84', label: 'Virgin Islands - British (+1284)' },
        { countryCode: 'VI', value: '84', label: 'Virgin Islands - US (+1340)' },
        { countryCode: 'WF', value: '681', label: 'Wallis and Futuna (+681)' },
        { countryCode: 'YE', value: '969', label: 'Yemen (North) (+969)' },
        { countryCode: 'YE', value: '967', label: 'Yemen (South) (+967)' },
        { countryCode: 'ZM', value: '260', label: 'Zambia (+260)' },
        { countryCode: 'ZW', value: '263', label: 'Zimbabwe (+263)' },
      ]
    };
  },
  created() {
    if (localStorage.getItem("token") !== null) {
      this.$router.push({ name: "home" });
    }
  },
  methods: {
    async register() {
      if (this.firstname === "") {
        this.$buefy.snackbar.open({
          message: "What is your name?",
          position: "is-top"
        });
        return;
      }
      if (this.lastname === "") {
        this.$buefy.snackbar.open({
          message: "What is your last name",
          position: "is-top"
        });
        return;
      }
      if (this.email === "") {
        this.$buefy.snackbar.open({
          message: "Please enter a valid email address",
          position: "is-top"
        });
        return;
      }
      if (this.password === "") {
        this.$buefy.snackbar.open({
          message: "Please enter a password",
          position: "is-top"
        });
        return;
      }
      try {
        let response = await axios.post(`${this.api}/register`, {
          uname: this.email,
          upass: this.password,
          ufirst: this.firstname,
          ulast: this.lastname,
          uconame: this.coname,
          umobile: this.mobile,
          ucountry: this.country
        });
        const indata = response.data;
        if (indata.status === 200) {
          this.$buefy.snackbar.open({
            message: indata.message,
            position: "is-top"
          });
          localStorage.setItem("token", indata.token);
          localStorage.setItem("sub", indata.sub);
          localStorage.setItem("firstname", indata.firstname);
          localStorage.setItem("lastname", indata.lastname);
          localStorage.setItem("isadmin", indata.admin);
          localStorage.setItem("demo", indata.demo);
          localStorage.setItem('activegoal', '');
          const inperms = "" + JSON.stringify(indata.permissions);
          localStorage.setItem("permissions", inperms);
          setTimeout(() => {
            // EventBus.$emit("check_usr", {});
            // this.$router.push({ name: "home" });
            if (localStorage.getItem("lastpage") !== null) {
              const pushto = localStorage.getItem("lastpage");
              this.$router.push({ path: pushto });
            } else {
              this.$router.push({ name: "home" });
            }
          }, 1000);
        } else {
          this.$buefy.snackbar.open({
            message: indata.message,
            position: "is-top"
          });
        }
      } catch (error) {
        this.$buefy.snackbar.open({ message: error, position: "is-top" });
      }
    }
  }
};
</script>
