<template>
  <div id="app">
    <div class="mainWrapper">
      <Navi />
      <div class="contentwrapper">
        <Header />
        <router-view />
        <!-- <div class="contentfooter" v-if="mainFoot">Footer</div> -->
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Navi from "@/components/Navi.vue";
import Header from "@/components/Header.vue";

export default {
  name: "app",
  components: {
    Navi,
    Header
  }
};
</script>
