export const Schemas = {
  data() {
    return {
      goalSchema: {
        _id: "new",
        sub: "",
        subname: "",
        availableto: [],
        clientid: "",
        client: "",
        invited: [],
        goaltype: "Manual",
        goaltitle: "",

        whatlist: [],
        whylist: [],

        description: "",

        why: "",
        outcome: "",
        worstcase: "",

        duedate: "",
        duedatets: 0,

        goalcolor: "#CCCCCC",
        goalstyle: "5px solid silver",
        childof: "",
        childorder: 9999,
        who: [],
        inteam: [],
        teammembers: [],
        editmode: false,

        oncal: "No",
        monday: false,
        mondaysettings: { hours: 0, minutes: 0 },
        tuesday: false,
        tuesdaysettings: { hours: 0, minutes: 0 },
        wednesday: false,
        wednesdaysettings: { hours: 0, minutes: 0 },
        thursday: false,
        thursdaysettings: { hours: 0, minutes: 0 },
        friday: false,
        fridaysettings: { hours: 0, minutes: 0 },
        saturday: false,
        saturdaysettings: { hours: 0, minutes: 0 },
        sunday: false,
        sundaysettings: { hours: 0, minutes: 0 },

        hourblocks: 0,
        blocksdaily: 0,
        minuteblocks: 0,
        goalorder: 0,
        completed: false,
        deleted: false,
        checked: false,
        selected: false,
        expanded: false,
        ondates: [],

        daystotal: 0,
        dayshours: 0,
        daysminutes: 0,

        comments: [],
        people: [],

        workflow: [],
        files: [],
        links: [],
        people: [],
        
        estdays: 0,
        esthours: 0,
        estminutes: 0,

        blueprintparent: '',
        blueprint: false,
        blueprintshare: 'Private',
        blueprintcosttype: 'Free', // Free, Paid, Monthly
        blueprintmonthlytext: '',
        blueprintduration: 0,
        blueprintcost: 0,
        blueprintmembers: 1,
        blueprintstatus: 'Draft',
        blueprintmessage: '',

        taskcount: 0,

        goalDays: [],

        lastsync: "",
        lastsyncdevice: "",
        notsynced: false
      },
      availabletoSchema: {
        _id: "",
        username: "",
        email: "",
        permission: "Can contribute"
      },
      permissionOptions: [
        { label: "Can contribute", value: "Can contribute" },
        { label: "Can comment", value: "Can comment" },
        { label: "Can read only", value: "Can read only" },
        { label: "Admin", value: "Admin" }
      ],
      notifySchema: {
        _id: "new",
        sub: "",
        subname: "",
        userid: "",
        useremail: "",
        username: "",
        type: "",
        subject: "",
        message: "",
        permission: "",
        goalid: "",
        taskid: "",
        personid: "",
        inviteid: "",
        status: "",
        dt: ""
      },
      workflow: [
        { value: "New", label: "New", icon: "haykal" },
        { value: "Active", label: "Active", icon: "user-hard-hat" },
        { value: "Completed", label: "Completed", icon: "check" }
      ],
      peedtypes: [
        { value: "Profile", label: "Profile", },
        { value: "Product", label: "Product", },
        { value: "Service", label: "Service" },
        { value: "Content", label: "Content" },
        { value: "Advert", label: "Advert" },
        { value: "Other", label: "Other" },
      ],
      peedagetypes: [
        { value: "Single", label: "Single", },
        { value: "Range", label: "Range", },
      ],
      peedgender: [
        { value: "Male", label: "Male", },
        { value: "Female", label: "Female", },
      ],
      peedsexual: [
        { value: "", label: "Select..." },
        { value: "Straight", label: "Straight" },
        { value: "Gay", label: "Gay" },
        { value: "Bi", label: "Bi" },
        { value: "Trans", label: "Trans" },
        { value: "custom", label: "Other..." },
      ],
      peedethnicity: [
        { value: "", label: "Select..." },
        { value: "White", label: "White" },
        { value: "Mixed", label: "Mixed" },
        { value: "Asian", label: "Asian" },
        { value: "Black", label: "Black" },
        { value: "Middle Eastern", label: "Middle Eastern" },
        { value: "custom", label: "Other..." },
      ],
      peedaccomodation: [
        { value: "", label: "Select..." },
        { value: "Home Owner", label: "Home Owner" },
        { value: "Rents", label: "Rents" },
        { value: "Shared Accomodation", label: "Shared Accomodation" },
        { value: "Lives at home", label: "Lives at home" },
        { value: "Boarding", label: "Boarding" },
        { value: "Homeless", label: "Homeless" },
        { value: "custom", label: "Other..." },
      ],
      peedlocations: [
        { value: "", label: "Select..." },
        { value: "London, England", label: "London, England" },
        { value: "New York", label: "New York" },
        { value: "Cape Town", label: "Cape Town" },
        { value: "Sao Paulo, Brazil", label: "Sao Paulo, Brazil" },
        { value: "custom", label: "Enter your desired location..." },
      ],
      peededucation: [
        { value: "", label: "Select..." },
        { value: "Primary School", label: "Primary School" },
        { value: "Secondary School", label: "Secondary School" },
        { value: "College", label: "College" },
        { value: "Univerity", label: "Univerity" },
        { value: "Home Schooling", label: "Home Schooling" },
        { value: "custom", label: "Other..." },
      ],
      peedemployment: [
        { value: "", label: "Select..." },
        { value: "Unemployed", label: "Unemployed" },
        { value: "Full time", label: "Full time" },
        { value: "Part time", label: "Part time" },
        { value: "Contractor", label: "Contractor" },
        { value: "Consultant", label: "Consultant" },
        { value: "Freelance", label: "Freelance" },
        { value: "Volunteer", label: "Volunteer" },
        { value: "custom", label: "Other..." },
      ],
      peedmorning: [
        { value: "", label: "Select..." },
        { value: "Gets up at 5am", label: "Gets up at 5am" },
        { value: "Gets up at 6am", label: "Gets up at 6am" },
        { value: "Makes breakfast for the family", label: "Makes breakfast for the family" },
        { value: "Goes for a run", label: "Goes for a run" },
        { value: "Goes to the Gym", label: "Goes to the Gym" },
        { value: "Leaves for work at 8am", label: "Leaves for work at 8am" },
        { value: "Starts work at 9am", label: "Starts work at 9am" },
        // { value: "custom", label: "Specify what they do..." },
      ],
      peedbrandrate: [
        { value: "", label: "Select..." },
        { value: "Not important", label: "Not important" },
        { value: "Mildly important", label: "Mildly important" },
        { value: "Important but will stray", label: "Important but will stray" },
        { value: "Very Important", label: "Very Important" },
        { value: "Absolute Brand Whore", label: "Absolute Brand Whore" },
        { value: "Not sure", label: "Not sure" },
      ],
      peedshopping: [
        { value: "", label: "Select..." },
        { value: "Buys in store only", label: "Buys in store only" },
        { value: "Supports local small businesses", label: "Supports local small businesses" },
        { value: "Buys from large retailers", label: "Buys from large retailers" },
        { value: "Impulse buyer", label: "Impulse buyer" },
        { value: "Buys online", label: "Buys online" },
        { value: "Researches online and buys in store", label: "Researches online and buys in store" },
        { value: "Uses price comparison sites", label: "Uses price comparison sites" },
        { value: "Only buys when sales are on", label: "Only buys when sales are on" },
      ],
      peedfeaturesprofile: [
        { value: "", label: "Select..." },
        { value: "Tall", label: "Tall" },
        { value: "Short", label: "Short" },
        { value: "Slender", label: "Slender" },
        { value: "Average body shape", label: "Average body shape" },
        { value: "Overweight", label: "Overweight" },
        { value: "Blue eyes", label: "Blue eyes" },
        { value: "Green eyes", label: "Green eyes" },
        { value: "Brown eyes", label: "Brown eyes" },
        { value: "Short hair", label: "Short hair" },
        { value: "Long hair", label: "Long hair" },
      ],
      peeddress: [
        { value: "", label: "Select..." },
        { value: "Relaxed", label: "Relaxed" },
        { value: "Formal", label: "Formal" },
        { value: "Conservative", label: "Conservative" },
        { value: "Urban", label: "Urban" },
        { value: "Grunge", label: "Grunge" },
        { value: "Casual", label: "Casual" },
        { value: "Confused", label: "Confused" },
      ],
      peedmediaoptions: [
        { value: "", label: "Select..." },
        { value: "Online Magazines", label: "Online Magazines" },
        { value: "Blogs", label: "Blogs" },
        { value: "Print Magazines", label: "Print Magazines" },
        { value: "News websites", label: "News websites" },
        { value: "Newspapers", label: "Newspapers" },
        { value: "Television", label: "Television" },
        { value: "Youtube", label: "Youtube" },
        { value: "Netflix", label: "Netflix" },
        { value: "Streaming service", label: "Streaming service" },
      ],
      peedsocial: [
        { value: "", label: "Select..." },
        { value: "Facebook", label: "Facebook" },
        { value: "Youtube", label: "Youtube" },
        { value: "instagram", label: "instagram" },
        { value: "qzone", label: "qzone" },
        { value: "weibo", label: "weibo" },
        { value: "twitter", label: "twitter" },
        { value: "TikTok", label: "TikTok" },
        { value: "reddit", label: "reddit" },
        { value: "pinterest", label: "pinterest" },
        { value: "ask.fm", label: "ask.fm" },
        { value: "tumbler", label: "tumbler" },
        { value: "flickr", label: "flickr" },
        { value: "google+", label: "google+" },
        { value: "LinkedIn", label: "LinkedIn" },
        { value: "VKontakte", label: "VKontakte (vk)" },
        { value: "odnoklaasnik", label: "odnoklaasnik" },
        { value: "meetup", label: "meetup" },
        { value: "other", label: "Other..." },
      ],
      taskSchema: {
        _id: "new",
        sub: "", // the user id
        subname: "",
        team: "",
        clientid: "",
        client: "",
        availableto: [],
        assignedto: [],
        tasktext: "", // the main text for the task, like the title
        taskdescription: "", // additional description
        tasknote: "", // note
        tasktype: "todo", // the type of task. See tasktypes for types
        taskcat: "Work",
        taskcatsub: "Do",
        taskcolor: "#666",
        taskauthor: "", // used for reading
        taskwho: "",
        taskwho_id: "",
        taskwho_tel: "",
        taskwho_email: "",
        taskmeeting_location: "address",
        taskaddress: "",

        goalid: "",
        goalcolor: "",
        goalname: "",

        parentid: "",
        
        icon: "",
        projectid: "",
        tasklink: "",
        taskplatform: "",
        durantiontype: "once",
        durantiondaily: "tillcompleted",

        monday: false,
        mondaysettings: { hours: 0, minutes: 0 },
        tuesday: false,
        tuesdaysettings: { hours: 0, minutes: 0 },
        wednesday: false,
        wednesdaysettings: { hours: 0, minutes: 0 },
        thursday: false,
        thursdaysettings: { hours: 0, minutes: 0 },
        friday: false,
        fridaysettings: { hours: 0, minutes: 0 },
        saturday: false,
        saturdaysettings: { hours: 0, minutes: 0 },
        sunday: false,
        sundaysettings: { hours: 0, minutes: 0 },

        workflow: "New",
        useicon: "haykal",

        grindhoursinday: 6,
        grinddaysinmonth: 21,
        grinddaysinweek: 5,
        grindweeksinyear: 52,
        grindyears: 0,
        grindmonths: 0,
        grindweeks: 0,
        grinddays: 0,
        grindhours: 0,
        grindminutes: 15,
        grindtotal: 0,

        estdays: 0,
        esthours: 0,
        estminutes: 15,
        estblocks: 1,

        hourblocks: 0,
        blocksdaily: 0,
        minuteblocks: 15,
        duration: 3,

        taskstartdate: "",
        taskstartdatets: 0,
        taskstarttime: "",
        taskstarttimets: 0,

        tasktilldate: "",
        tasktilldatets: 0,
        tasktilltime: "",
        tasktilltimets: 0,

        trackstart: 0,
        trackend: 0,

        taskdate: '',
        taskdatets: 0,
        tasktime: '',
        tasktimets: 0,
        importance: 0,
        ondates: [],
        ondatescompleted: [],
        content: [],
        worklog: [],
        steps: [],
        keywords: '',
        taskorder: 0,
        childorder: 0,
        completed: false,
        selected: false,
        checked: false,
        expanded: false,
        deleted: false,
        
        comments: [],

        questionstext: '',
        questions: [],
        automark: false,
        questionsactive: false,
        questionsshowpercentage: false,
        questionsdaily: false,
        answers: [],

        taskcount: 0,

        lastsync: "",
        lastsyncdevice: "",
        notsynced: false
      },
      treeSchema: {
        _id: "new",
        sub: "", // the user id
        subname: "",

        goalid: "",
        goalcolor: "",
        goaltitle: "",

        treetitle: "",
        data: [
          { name: 'new', children: [] }
        ]
      }
    };
  }
};
