<template>
  <div class="modal-card" :style="ModalWidth">
    <header class="modal-card-head whitebg" style="padding: 10px; width: 250px;"  v-if="tabs.loading"></header>
    <section class="whitebg roundedtop">
      <nav class="tabs is-centered" v-if="!tabs.loading">
        <ul>
          <li :class="{ 'is-active': tabs.desc }">
            <a @click="tabsChange('desc')">What</a>
          </li>
          <li :class="{ 'is-active': tabs.resources }">
            <a @click="tabsChange('resources')">Resources</a>
          </li>
          <li :class="{ 'is-active': tabs.response }" v-if="editMode">
            <a @click="tabsChange('response')">Response</a>
          </li>
          <li :class="{ 'is-active': tabs.response }" v-if="Task.questions.length !== 0 && !editMode">
            <a @click="tabsChange('response')">Response</a>
          </li>
          <li :class="{ 'is-active': tabs.who }" v-if="Task.goalid !== ''">
            <a @click="tabsChange('who')">Who</a>
          </li>
          <li :class="{ 'is-active': tabs.when }" v-if="editMode">
            <a @click="tabsChange('when')">When</a>
          </li>
          <li :class="{ 'is-active': tabs.log }" v-if="canEdit">
            <a @click="tabsChange('log')">Work Log</a>
          </li>
          <li :class="{ 'is-active': tabs.comments }">
            <a @click="tabsChange('comments')">Comments</a>
          </li>
          <!-- <li :class="{ 'is-active': tabs.more }" v-if="editMode">
            <a @click="tabsChange('more')">More</a>
          </li> -->
        </ul>
      </nav>
    </section>
    <section class="modal-card-body">
      <div class="tab-item" v-if="tabs.loading">
        <b-progress type="is-info"></b-progress>
      </div>
      <div v-if="tabs.desc && !tabs.loading">
        <div v-if="canEdit" class="isFlex p7 lgreybg" style="margin-left: -20px; margin-top: -20px; margin-right: -20px; ">
          <div class="isFlexGrow">
            <div v-if="!editMode" class="txtsm">
              <div v-if="Task.taskcount === 0">This will take <strong class="txth6">{{Task.estdays}}</strong> days <strong class="txth6">{{Task.esthours}}</strong> hours and <strong class="txth6">{{Task.estminutes}}</strong> minutes to complete.</div>
              <div v-if="Task.taskcount > 0">Based on the sub tasks this will take <strong class="txth6">{{Task.estdays}}</strong> days <strong class="txth6">{{Task.esthours}}</strong> hours and <strong class="txth6">{{Task.estminutes}}</strong> minutes to complete.</div>
            </div>
          </div>
          <div>
            <b-select placeholder="Select" size="is-small" v-model="Task.workflow">
              <option v-for="w in workflow" :value="w.value" :key="w.value">{{ w.label }}</option>
            </b-select>
          </div>
          <div>
            <b-button size="is-small" @click="saveWorkflow">Update</b-button>
          </div>
        </div>

        <template v-if="!editMode && !tabs.loading && tabs.desc">
          <template v-if="showVideo">
            <div style="background-color: white; margin-left: -20px; margin-right: -20px;">
              <video-player  class="video-player-box"
                ref="videoPlayer"
                :options="playerOptions"
                :playsinline="true"
                customEventName="customstatechangedeventname">
              </video-player>
            </div>
          </template>
        </template>

        <div>
          <div class="isFlex mb7">
            <div class="txth3 mt9 isFlexGrow">{{ Task.tasktext }}</div>
          </div>
        </div>
        <div v-if="editMode">
          <b-field label="Task" message="This is the title that appears in the listing 125 chars max">
          <b-input v-model="Task.tasktext" type="textarea" rows="2" maxlength="125"></b-input>
        </b-field>
        </div>
        
        <div v-if="!editMode">{{ Task.taskdescription }}</div>
        <div v-else>
          <b-field label="Blurb" message="A short description of what the task/section is about">
            <b-input v-model="Task.taskdescription" type="textarea" rows="2" maxlength="250"></b-input>
          </b-field>
        </div>

        
        <div v-if="editMode">
          <div>
            <div class="txtbold mb3">Associated with goal...</div>
            <b-select placeholder="Select Goal" expanded v-model="Task.goalid" @input="changeGoal">
              <option v-for="gc in GoalList" :value="gc._id" :key="gc._id">{{ gc.goaltitle }}</option>
            </b-select>
          </div>
          <div class="txth4 mt11">How long will this task take to complete?</div>
          <div class="isFlex" v-if="Task.taskcount === 0">
            <div style="width: 33%; padding-right: 10px;">
              <b-field label="Days">
                <b-numberinput size="is-small" min="0" v-model="Task.estdays"></b-numberinput>
              </b-field>
            </div>
            <div style="width: 33%; padding-right: 10px;">
              <b-field label="Hours">
                <b-numberinput size="is-small" min="0" max="23" v-model="Task.esthours"></b-numberinput>
              </b-field>
            </div>
            <div style="width: 34%; padding-left: 10px;">
              <b-field label="Minutes">
                <b-numberinput size="is-small" v-model="Task.estminutes" step="15" min="0" max="45"></b-numberinput>
              </b-field>
            </div>
          </div>

          <div v-if="Task.taskcount > 0">
            Calculated: {{Task.estdays}}d {{Task.esthours}}h {{Task.estminutes}}m 
          </div>
        </div>

        <div class="mt11" v-if="Task.goalid !== ''">
          <div style="height: 20px;" v-if="editMode"></div>
          <div class="txth4" v-if="editMode">Task indepth</div>
          <!-- <div class="txth4" v-if="Task.content.length !== 0 && !editMode">Task indepth</div> -->
          <!-- video: start -->
          <div v-if="editMode" class="mt11 mb11 tsk vlgreybg">
            <b-field label="Use a video as cover image">
              <b-select placeholder="Select a video" icon="video" v-model="Task.video">
                <option value="">Don't use video</option>
                  <optgroup label="From uploads" >
                    <template v-for="(gf, index) in Task.files" >
                      <option :value="gf.url" v-if="gf.mimetype === 'video/mp4'" :index="index" :key="index">{{ gf.title }}</option>
                    </template>
                  </optgroup>
              </b-select>
            </b-field>
          </div>
          <div v-if="editMode">

          </div>
          <!-- video: end -->
          <div>
            <draggable tag="div" :list="Task.content" handle=".handle"  @end="submitForm(false)">
              <section v-for="(ct, index) in Task.content" :index="index" :key="index">
                <div v-if="ct.type === 'heading' && !ct.edit" class="txth4 mt7 mb7">{{ ct.text }}</div>
                <div v-if="ct.type === 'paragraph'" class="mt7 mb7">
                  <p v-if="ct.text === ''" v-html="lorum"></p>
                  <p v-else v-html="ct.text" class="Formatted"></p>
                </div>
                <div v-if="ct.type === 'image'" class="mt7 mb7">
                  <img :src="image" :alt="ct.alt" v-if="ct.src === ''" />
                  <img :src="ct.src" :alt="ct.alt" v-else />
                </div>
                
                <div v-if="ct.edit && editMode">
                  <div>
                    <div><b-input v-model="ct.text"></b-input></div>
                    <div class="isFlex">
                      <div class="isFlexGrow"></div>
                      <div class="iconbutton" @click="ct.edit = false">
                        <font-awesome-icon :icon="['fal', 'check-square']" size="sm" ></font-awesome-icon>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-if="!ct.edit && editMode">
                  <div class="isFlex">
                    <div class="isFlexGrow"></div>
                    <div class="iconbutton mr7" @click="contentEdit(ct, index)">
                      <font-awesome-icon :icon="['fal', 'edit']" size="sm" ></font-awesome-icon>
                    </div>
                    <div class="iconbutton mr7" @click="contentDelete(index)">
                      <font-awesome-icon :icon="['fal', 'trash']" size="sm" ></font-awesome-icon>
                    </div>
                    <div class="iconbutton handle">
                      <font-awesome-icon :icon="['fal', 'sort']" size="sm" ></font-awesome-icon>
                    </div>
                  </div>
                </div>
              </section>
            </draggable>
            <div class="isFlex" v-if="editMode">
              <div class="isFlexGrow"><hr/></div>
              <div class="iconbutton2x tsk bluebg9 ml5 mr5 white" @click="addsection('heading')">
                <font-awesome-icon :icon="['fal', 'heading']" size="lg"></font-awesome-icon>
              </div>
              <div class="iconbutton2x tsk bluebg9 mr5 white" @click="addsection('paragraph')">
                <font-awesome-icon :icon="['fal', 'paragraph']" size="lg"></font-awesome-icon>
              </div>
              <div class="iconbutton2x tsk bluebg9 mr5 white" @click="addsection('image')">
                <font-awesome-icon :icon="['fal', 'image']" size="lg"></font-awesome-icon>
              </div>
              <div class="isFlexGrow"><hr/></div>
            </div>
          </div>

          <div v-if="editMode"><div class="field mt11 mb11" v-if="isadmin"><b-switch v-model="Task.demo">Demo content</b-switch></div></div>

          <div v-if="!editMode">
            <div style="height: 20px;"></div>
            <div class="txth4">Outline</div>

            <ul class="ul">
              <taskitem v-for="(task, index) in TreeData" :key="index" :item="task" :Active="ActiveTask"></taskitem>
            </ul>
          </div>
        </div>
      </div>
      <!-- comments: start -->
      <div v-if="tabs.comments && !tabs.loading">
        <div v-if="Task.comments.length === 0">No comments</div>
        <div class="tsk p7 m7 isFlex vlgreybg" v-for="(n, index) in Task.comments" :key="index">
          <div class="ml11 isFlexGrow">
            <div class="txtsm">{{ n.from }} Date: {{ n.date | displaydateFull }} </div>
            <div class="tskhead" v-html="txtformat(n.comment)"></div>
            <div class="isFlex isFlexEnd" v-if="n.fromid === sub">
              <b-button size="is-small" @click="commentDelete(index)">Delete</b-button>
            </div>
          </div>
        </div>
      </div>
      <!-- comments: end -->
      <!-- resources: Start -->
      <div v-if="tabs.resources && !tabs.loading">
        <!-- links heading -->
        <div class="isFlex blueborder2bottom">
          <div class="isFlexGrow txth3">Links</div>
          <div v-if="editMode"><b-button @click="linksAddToggle()"><span v-if="showLinksAdd">Close</span><span v-else>Add link</span></b-button></div>
        </div>

        <!-- links add: start -->
        <div class="tsk vlgreybg" v-if="showLinksAdd">
          <div class="txth3">Add link</div>
          <b-field label="Type of link">
            <b-select placeholder="Select..." v-model="linkNewType">
              <option value="Web page">Website or web page</option>
              <option value="Youtube video">Youtube video</option>
              <option value="Google doc">Google doc or sheet</option>
              <option value="Other">Other</option>
            </b-select>
          </b-field>
          <b-field label="Url">
            <b-input v-model="linkNewUrl" placeholder="https://addressoflink.com/page/etc" type="url"></b-input>
          </b-field>
          <b-field label="Title of link">
            <b-input v-model="linkNewTitle"></b-input>
          </b-field>
          <b-field label="Description of link">
            <b-input maxlength="500" type="textarea" v-model="linkNewDesc"></b-input>
          </b-field>
          <div>
            <button class="button" @click="linkAdd()">Add link</button>
          </div>
        </div>
        <!-- links add: end -->
        <div class="p11" v-if="Task.links.length === 0">You don't have any links defined.</div>

        <!-- links list: start -->
        <draggable tag="div" :list="Task.links" handle=".handle"  @end="submitForm(false)" >
          <section v-for="(gl, index) in Task.links" :index="index" :key="index">
            <div class="isFlex pt5 pb5 mb3 mt3 blueborder1bottom">
              <div class="iconbutton handle" v-if="editMode">
                <font-awesome-icon :icon="['fal', 'sort']" size="sm" ></font-awesome-icon>
              </div>
              <div class="isFlexGrow pl3 pr5" v-if="gl.edit">
                <div>
                  <b-select placeholder="Select..." v-model="gl.type">
                    <option value="Web page">Website or web page</option>
                    <option value="Youtube video">Youtube video</option>
                    <option value="Google doc">Google doc or sheet</option>
                    <option value="Other">Other</option>
                  </b-select>
                </div>
                <div><b-input v-model="gl.url" @blur="submitForm(false)"></b-input></div>
                <div><b-input v-model="gl.title" @blur="submitForm(false)"></b-input></div>
                <div><b-input v-model="gl.description" type="textarea" rows="3" @blur="submitForm(false)"></b-input></div>
              </div>
              <div class="isFlexGrow pl3 pr5" v-else>
                <div class="txtbold">{{ gl.title }}</div>
                <div>{{ gl.description }}</div>
                <div style="width: 60px;" v-if="gl.type === 'Youtube video'">
                  <b-button icon-right="play" @click="playVideo(gl, 'video/youtube')">Play Video</b-button>
                </div>
              </div>
              <div class="isFlex">
                <div class="iconbutton tsk redborder red mr5" @click="linkDelete(gl, index)" v-if="editMode">
                  <font-awesome-icon :icon="['fal', 'trash']" size="sm"></font-awesome-icon>
                </div>
                <div class="iconbutton tsk blueborder10 bluetxt10 mr5" @click="linkEdit(gl)" v-if="editMode">
                  <font-awesome-icon :icon="['fal', 'edit']" size="sm"></font-awesome-icon>
                </div>
                <a class="iconbutton tsk blueborder10 bluetxt10" :href="gl.url" target="_blank">
                  <font-awesome-icon :icon="['fal', 'external-link']" size="sm"></font-awesome-icon>
                </a>
              </div>
            </div>
          </section>
        </draggable>
        <!-- links list: end -->

        <!-- upload heading -->
        <div style="height: 20px;"></div>
        <div class="isFlex blueborder2bottom">
          <div class="isFlexGrow txth3">Files</div>
          <div v-if="editMode"><b-button @click="uploadToggle()"><span v-if="uploadShow">Close</span><span v-else>Upload</span></b-button></div>
        </div>
        <!-- upload window: start -->
        <div class="tsk vlgreybg" v-if="uploadShow">
          <div class="txth3">Upload file</div>
          <div class="mb11 red">Please note: you can only upload images (.jpg, .png), video (mp4) and documents (.pdf) files.</div>
          <form @submit.prevent="onSubmit" enctype="multipart/form-data">
            <b-field class="file">
              <b-upload v-model="file" ref="file" @input="onSelect">
                <a class="button is-primary">
                  <b-icon icon="upload"></b-icon>
                  <span>Select file</span>
                </a>
              </b-upload>
              <span class="file-name" v-if="file">
                {{ file.name }}
              </span>
            </b-field>
            <b-field label="Title" message="Enter an easy readable file title">
              <b-input v-model="fileTitle"></b-input>
            </b-field>
            <b-field label="Description" message="Describe what the file is about">
              <b-input v-model="fileDesc" type="textarea" rows="3"></b-input>
            </b-field>
            <!-- <div>
              <label>Upload file</label>
              <input type="file" ref="file" @change="onSelect" />
            </div> -->
            <div>
              <button class="button">Upload</button>
            </div>
          </form>
        </div>
        <!-- upload window: start -->
        <!-- file list: start -->
        <div class="p11" v-if="Task.files.length === 0">You don't have any files uploaded.</div>
        <draggable tag="div" :list="Task.files" handle=".handle" @end="submitForm(false)">
          <section v-for="(gf, index) in Task.files" :index="index" :key="index">
            <div class="isFlex pt5 pb5 mb3 mt3 blueborder1bottom">
              <div class="iconbutton handle" v-if="editMode">
                <font-awesome-icon :icon="['fal', 'sort']" size="sm" ></font-awesome-icon>
              </div>

              <div class="isFlexGrow pl3 pr5" v-if="gf.edit">
                <div><b-input v-model="gf.title" @blur="submitForm(false)"></b-input></div>
                <div><b-input v-model="gf.description" type="textarea" rows="3" @blur="submitForm(false)"></b-input></div>
              </div>
              <div class="isFlexGrow pl3 pr5" v-else>
                <div class="txtbold">{{ gf.title }} </div>
                <div v-if="gf.mimetype === 'video/mp4'" class="mt11 mb11">
                  <b-button icon-right="play" @click="playVideo(gf, 'video/mp4')">Play Video</b-button>
                </div>
                <div>{{ gf.description }}</div>
                <div style="width: 60px;" v-if="gf.mimetype === 'image/png' || gf.mimetype === 'image/jpg' || gf.mimetype === 'image/jpeg'"><img :src="gf.url" /></div>
              </div>
              <div class="isFlex">
                <div class="iconbutton tsk redborder red mr5" @click="fileDelete(gf, index)" v-if="editMode">
                  <font-awesome-icon :icon="['fal', 'trash']" size="sm"></font-awesome-icon>
                </div>
                <div class="iconbutton tsk blueborder10 bluetxt10 mr5" @click="fileEdit(gf)" v-if="editMode">
                  <font-awesome-icon :icon="['fal', 'edit']" size="sm"></font-awesome-icon>
                </div>
                <a class="iconbutton tsk blueborder10 bluetxt10" :href="gf.url" target="_blank">
                  <font-awesome-icon :icon="['fal', 'external-link']" size="sm"></font-awesome-icon>
                </a>
              </div>
              
            </div>
          </section>
        </draggable>
        <!-- file list: end -->
      </div>
      <!-- resources: end -->
      <div v-if="tabs.who && !tabs.loading">
        <div class="txth4 mb5">Team</div>
        <div class="isFlex blueborder1bottom pb3 mb11" v-if="editMode">
          <div class="isFlexGrow mr11">
            <b-select v-model="GoalNewMember" placeholder="Select a member" expanded>
              <option v-for="gm in GoalMembers" :value="gm" :key="gm.id"> {{ gm.username }} </option>
            </b-select>
          </div>
          <div><b-button @click="gmAdd">Add</b-button></div>
        </div>

        <div class="isFlex blueborder1bottom pb3 mb11" v-for="(tm, index) in Task.assignedto" :key="tm._id">
          <div class="isFlexGrow">
            <div>{{ tm.username }} <span class="txtsm red" v-if="tm.review">(Leader)</span> </div>
            <div class="txtsm">{{tm.email}}</div>
            <!-- <div class="pl3 pt7">
              <b-tooltip label="Works on the task"><b-checkbox v-model="tm.worker" type="is-light" size="is-small"><font-awesome-icon :icon="['fal', 'digging']" size="lg"></font-awesome-icon></b-checkbox></b-tooltip>&nbsp;&nbsp;&nbsp;
              <b-tooltip label="Can Archive/Delete the task"><b-checkbox v-model="tm.resources" type="is-light" size="is-small"><font-awesome-icon :icon="['fal', 'trash']" size="lg"></font-awesome-icon></b-checkbox></b-tooltip>&nbsp;&nbsp;&nbsp;
              <b-tooltip label="Can comment on the task"><b-checkbox v-model="tm.review" type="is-light" size="is-small"><font-awesome-icon :icon="['fal', 'file-search']" size="lg"></font-awesome-icon></b-checkbox></b-tooltip>&nbsp;&nbsp;&nbsp;
              <b-tooltip label="Can change settings"><b-checkbox v-model="tm.approve" type="is-light" size="is-small"><font-awesome-icon :icon="['fal', 'cogs']" size="lg"></font-awesome-icon></b-checkbox></b-tooltip>&nbsp;&nbsp;&nbsp;
              <b-tooltip label="Can complete the task"><b-checkbox v-model="tm.complete" type="is-light" size="is-small"><font-awesome-icon :icon="['fal', 'check-circle']" size="lg"></font-awesome-icon></b-checkbox></b-tooltip>
            </div> -->
          </div>
          <div class="mr7 pt5">
            {{ tm.workflow }}
          </div>
          <div class="mr7 pt5" v-if="editMode">
            <b-tooltip label="Is leader"><b-checkbox v-model="tm.review" type="is-light" size="is-small"><font-awesome-icon :icon="['fal', 'chalkboard-teacher']" size="lg"></font-awesome-icon></b-checkbox></b-tooltip>
          </div>
          <div class="iconbutton2x tsk redborder red" @click="gmRemove(index)" v-if="editMode">
            <font-awesome-icon :icon="['fal', 'trash']" size="lg"></font-awesome-icon>
          </div>
        </div>

        <div style="height: 20px;"></div>
        <div class="txth4 mb5">Roles</div>
        <div class="isFlex blueborder1bottom pb3 mb11" v-if="editMode">
          <div class="isFlexGrow mr11">
            <b-select v-model="GoalNewRole" placeholder="Select a role" expanded>
              <option v-for="(gp, gi) in GoalPeople" :value="gp" :key="gi"> {{ gp.role }} ({{ gp.type }}) </option>
            </b-select>
          </div>
          <div><b-button @click="gpAdd">Add</b-button></div>
        </div>
        <div class="isFlex isFlexAlign" v-for="(r, ri) in Task.people" :key="ri">
          <div class="isFlexGrow mr7">
            <div>{{ r.role }}</div>
            <div class="txtsm">{{ r.type }}</div>
          </div>
          <div class="mr7 txtr" style="width: 50px;">{{ r.day }}</div>
          <div class="mr7 txtr" style="width: 40px;">{{ r.hour }}</div>
          <div class="iconbutton2x tsk redborder red" @click="gpRemove(ri)" v-if="editMode">
            <font-awesome-icon :icon="['fal', 'trash']" size="lg"></font-awesome-icon>
          </div>
        </div>
      </div>

      <div v-if="tabs.when && !tabs.loading">
        <b-field label="Type">
          <b-select placeholder="Select" expanded v-model="Task.durantiontype" @input="dailyChange">
            <option value="once">Once</option>
            <option value="daily">Daily</option>
          </b-select>
        </b-field>
        <b-field label="Due date" v-if="Task.durantiontype === 'once'">
          <b-datepicker v-model="dtEnd" placeholder="Click to select..." icon="calendar"></b-datepicker>
        </b-field>
        <b-field label="Due time" v-if="Task.durantiontype === 'once'">
          <b-clockpicker v-model="dtEndTime" placeholder="Click to select..." icon="clock" hour-format="24"></b-clockpicker>
        </b-field>

        <section v-if="Task.durantiontype === 'daily'">
          <b-field label="Daily run">
            <b-select placeholder="Select" expanded v-model="Task.durantiondaily">
              <option value="tillcompleted">Untill Completed</option>
              <!-- <option value="timeline">Defined Timeline</option> -->
            </b-select>
          </b-field>

          <section v-if="Task.durantiondaily === 'timeline'">
            <b-field label="First day">
              <b-datepicker v-model="dtStart" placeholder="Click to select..." icon="calendar"></b-datepicker>
            </b-field>
            <!-- <b-field label="Start time">
              <b-clockpicker v-model="dtStartTime" placeholder="Click to select..." icon="clock" hour-format="24"></b-clockpicker>
            </b-field> -->
            <b-field label="Last day">
              <b-datepicker v-model="dtEnd" placeholder="Click to select..." icon="calendar"></b-datepicker>
            </b-field>
            <!-- <b-field label="End time">
              <b-clockpicker v-model="dtEndTime" placeholder="Click to select..." icon="clock" hour-format="24"></b-clockpicker>
            </b-field> -->
          </section>

          <div class="mt11">
            <div class="txtbold mb7">
              What days of the week can you work on this task and for how
              long every day?
            </div>
            <!-- monday -->
            <div class="txtbold mb7">Monday</div>
            <div class="isFlex inputbr isFlexAlign tsk mb5">
              <div>
                <b-checkbox
                  v-model="Task.monday"
                  @input="blocksCalc"></b-checkbox>
              </div>
              <div class="p5">H</div>
              <div style="width: 100px;">
                <b-numberinput
                  v-model="Task.mondaysettings.hours"
                  size="is-small"
                  type="is-light"
                  @input="blocksCalc"></b-numberinput>
              </div>
              <div class="p5">M</div>
              <div style="width: 100px;">
                <b-numberinput
                  v-model="Task.mondaysettings.minutes"
                  size="is-small"
                  type="is-light"
                  step="15"
                  @input="blocksCalc"></b-numberinput>
              </div>
            </div>
            <!-- tuesday -->
            <div class="txtbold mb7">Tuesday</div>
            <div class="isFlex inputbr isFlexAlign tsk mb5">
              <div>
                <b-checkbox
                  v-model="Task.tuesday"
                  @input="blocksCalc"></b-checkbox>
              </div>
              <div class="p5">H</div>
              <div style="width: 100px;">
                <b-numberinput
                  v-model="Task.tuesdaysettings.hours"
                  size="is-small"
                  type="is-light"
                  @input="blocksCalc"></b-numberinput>
              </div>
              <div class="p5">M</div>
              <div style="width: 100px;">
                <b-numberinput
                  v-model="Task.tuesdaysettings.minutes"
                  size="is-small"
                  type="is-light"
                  step="15"
                  @input="blocksCalc"></b-numberinput>
              </div>
            </div>
            <!-- wednesday -->
            <div class="txtbold mb7">Wednesday</div>
            <div class="isFlex inputbr isFlexAlign tsk mb5">
              <div>
                <b-checkbox
                  v-model="Task.wednesday"
                  @input="blocksCalc"></b-checkbox>
              </div>
              <div class="p5">H</div>
              <div style="width: 100px;">
                <b-numberinput
                  v-model="Task.wednesdaysettings.hours"
                  size="is-small"
                  type="is-light"
                  @input="blocksCalc"></b-numberinput>
              </div>
              <div class="p5">M</div>
              <div style="width: 100px;">
                <b-numberinput v-model="Task.wednesdaysettings.minutes" size="is-small" type="is-light" step="15" @input="blocksCalc"></b-numberinput>
              </div>
            </div>
            <!-- thursday -->
            <div class="txtbold mb7">Thursday</div>
            <div class="isFlex inputbr isFlexAlign tsk mb5">
              <div>
                <b-checkbox v-model="Task.thursday" @input="blocksCalc"></b-checkbox>
              </div>
              <div class="p5">H</div>
              <div style="width: 100px;">
                <b-numberinput v-model="Task.thursdaysettings.hours" size="is-small" type="is-light" @input="blocksCalc"></b-numberinput>
              </div>
              <div class="p5">M</div>
              <div style="width: 100px;">
                <b-numberinput v-model="Task.thursdaysettings.minutes" size="is-small" type="is-light" step="15" @input="blocksCalc"></b-numberinput>
              </div>
            </div>
            <!-- friday -->
            <div class="txtbold mb7">Friday</div>
            <div class="isFlex inputbr isFlexAlign tsk mb5">
              <div>
                <b-checkbox v-model="Task.friday" @input="blocksCalc"></b-checkbox>
              </div>
              <div class="p5">H</div>
              <div style="width: 100px;">
                <b-numberinput v-model="Task.fridaysettings.hours" size="is-small" type="is-light" @input="blocksCalc"></b-numberinput>
              </div>
              <div class="p5">M</div>
              <div style="width: 100px;">
                <b-numberinput v-model="Task.fridaysettings.minutes" size="is-small" type="is-light" step="15" @input="blocksCalc"></b-numberinput>
              </div>
            </div>
            <!-- saturday -->
            <div class="txtbold mb7">Saturday</div>
            <div class="isFlex inputbr isFlexAlign tsk mb5">
              <div>
                <b-checkbox v-model="Task.saturday" @input="blocksCalc"></b-checkbox>
              </div>
              <div class="p5">H</div>
              <div style="width: 100px;">
                <b-numberinput v-model="Task.saturdaysettings.hours" size="is-small" type="is-light" @input="blocksCalc" ></b-numberinput>
              </div>
              <div class="p5">M</div>
              <div style="width: 100px;">
                <b-numberinput v-model="Task.saturdaysettings.minutes" size="is-small" type="is-light" step="15" @input="blocksCalc"></b-numberinput>
              </div>
            </div>
            <!-- sunday -->
            <div class="txtbold mb7">Sunday</div>
            <div class="isFlex inputbr isFlexAlign tsk mb5">
              <div>
                <b-checkbox v-model="Task.sunday" @input="blocksCalc"></b-checkbox>
              </div>
              <div class="p5">H</div>
              <div style="width: 100px;">
                <b-numberinput  v-model="Task.sundaysettings.hours" size="is-small" type="is-light" @input="blocksCalc"></b-numberinput>
              </div>
              <div class="p5">M</div>
              <div style="width: 100px;">
                <b-numberinput v-model="Task.sundaysettings.minutes" size="is-small" type="is-light" step="15" @input="blocksCalc"></b-numberinput>
              </div>
            </div>
          </div>
        </section>

      </div>

      <div v-if="tabs.response && !tabs.loading">
        <div v-if="isforme">
          <!-- Questions: Leader marking -->
          <div v-if="isLeader && !editMode">
            <div class="txth4 txtbold b11">Replies</div>
            <div v-if="Task.answers.length === 0">No users a have posted any responces</div>
            <div v-for="(an, ani) in Task.answers" :key="ani">
              <div>
                <div class="tsk bluebg1 mb11">
                  <div class="txtbold txt txth6" @click="questionExpand(an)">{{ an.username }}</div>
                  <div class="blueborder5top pt7" v-if="an.expanded">
                    <div v-for="(a, ani) in an.data" :key="a.day" :index="ani">
                      <div class="isFlex isFlexAlign" @click="showHideAnswers(a)">
                        <div class="txth5 isFlexGrow mr7"><span>{{a.day}}</span></div>
                        <div v-if="a.completed" class="red mr7"><span>Not completed</span></div>
                        <div v-if="!a.completed" class="mr7"><span v-if="a.marked">Processed</span><span class="red" v-else>Not Processed</span></div>
                        <div class="txth3">{{a.count}}/{{ a.answers.length }}</div>
                      </div>
                      <div v-if="a.expanded">
                        <div class="mt5 txtbold">Note</div>
                        <div><b-input v-model="a.note" type="textarea" rows="2" placeholder="Note to user"></b-input></div>
                      </div>
                      <div style="height: 10px;" v-if="a.expanded"></div>
                      <div class="mr11" v-if="a.expanded">
                        <div class="txtbold txth5">Answers</div>
                        <div v-if="a.answers.length === 0">No questions answered</div>
                        <div class="mb11" v-for="(da, index) in a.answers" :key="da.qid" :index="index">
                          <div class="isFlex ">
                            <div class="isFlexGrow">
                              <div class="txtbold">{{ da.question }}</div>
                              <div>{{ da.answer }}</div>
                            </div>
                            <div style="width: 90px;">
                              <b-switch size="is-small" v-model="da.correct">
                                <span v-if="da.correct" class="bluetxt11">Correct</span>
                                <span v-else class="red">In-Correct</span>
                              </b-switch>
                            </div>
                          </div>
                          <div class="mt3"><b-input v-model="da.note" placeholder="Note regarding the answer"></b-input></div>
                        </div>
                      </div>
                      <div style="height: 10px;" v-if="a.expanded"></div>
                      <div v-if="a.expanded" class="isFlex">
                        <div><b-switch v-model="a.marked">Processed</b-switch></div>
                      </div>
                      <div style="height: 10px;" v-if="a.expanded"></div>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div  v-if="!isLeader && !editMode && !Task.questionsactive">
            No responce needed or not available yet.
          </div>
          <!-- Questions: User responce -->
          <div  v-if="!isLeader && !editMode && Task.questionsactive">
            <div class="mb15">
              <div class="txth4 txtbold">Description and instructions</div>
              <div>{{ Task.questionstext }}</div>
            </div>
            <div v-for="(qu, qui) in Task.questions" :key="qui">
              <div v-if="qu.type === 'text' || qu.type === 'textarea'" class="mb11">
                <div class="isFlex">
                  <div class="txtbold mb5 isFlexGrow">{{ qu.question }}</div>
                  <div v-if="qu.skip" class="txtsm bluetxt5">Optional</div>
                </div>
                <div><b-input v-model="qu.tempanswer" :type="qu.type"></b-input></div>
              </div>
              <div v-if="qu.type === 'select'" class="mb11">
                <div class="isFlex">
                  <div class="txtbold mb5 isFlexGrow">{{ qu.question }}</div>
                  <div v-if="qu.skip" class="txtsm bluetxt5">Optional</div>
                </div>
                <div>
                  <b-select
                    placeholder="Please select..."
                    v-model="qu.tempanswer"
                    expanded>
                    <option value="">No answer selected</option>
                    <option
                      v-for="option in qu.options"
                      :value="option"
                      :key="option">
                      {{ option }}
                    </option>
                  </b-select>
                  </div>
              </div>
            </div>
            <div style="height: 10px;"></div>
            <div class="field mt11">
              <b-switch v-model="Completed">I have completed the questionaire</b-switch>
            </div>

            <div style="height: 10px;"></div>
            <div class="txth4 txtbold">Answers</div>
            <div class="tsk vlgreybg mb11" v-for="(a, ani) in Task.answers[0].data" :key="a.day" :index="ani">
              <div class="isFlex isFlexAlign" @click="showHideAnswers(a)">
                <div class="txth5 isFlexGrow mr7"><span>{{a.day}}</span></div>
                <div v-if="a.completed" class="red mr7"><span>Not completed</span></div>
                <div v-if="!a.completed" class="mr7"><span v-if="a.marked">Processed</span><span class="red" v-else>Not Processed</span></div>
                <div class="txth3">{{a.count}}/{{ a.answers.length }}</div>
              </div>
              <div v-if="a.expanded">
                <div class="mt5 txtbold">Note</div>
                <div v-if="a.note !== ''">{{a.note}}</div>
                <div v-else>No note posted</div>
              </div>
              <div style="height: 10px;" v-if="a.expanded"></div>
              <div class="mr11" v-if="a.expanded">
                <div class="txtbold txth5">Answers</div>
                <div v-if="a.answers.length === 0">No questions answered</div>
                <div class="isFlex mb11" v-for="(da, index) in a.answers" :key="da.qid" :index="index">
                  <div class="isFlexGrow">
                    <div class="txtbold">{{ da.question }}</div>
                    <div>{{ da.answer }}</div>
                    <div v-if="da.note !== ''" class="txtitalic txtsm red">{{ da.note }}</div>
                  </div>
                  <div v-if="a.marked">
                    <span v-if="da.answer" class="bluetxt11">Correct</span>
                    <span v-else class="red">In-Correct</span>
                  </div>
                </div>
              </div>
              <div style="height: 10px;" v-if="a.expanded"></div>
            </div>
          </div>
        </div>
        <div v-else>Not for you. If this is incorrect contact the leader.</div>

        <!-- Questions: Edit questions -->
        <div v-if="editMode">
          <div>
            <b-field label="Description and instructions">
              <b-input maxlength="200" type="textarea" v-model="Task.questionstext"></b-input>
            </b-field>
          </div>
          <!-- <div class="field">
            <b-switch v-model="Task.automark">Auto mark the user's answers on submit</b-switch>
          </div> -->
          <div class="field">
            <b-switch v-model="Task.questionsdaily" :disabled="!isScheduled">Require daily responses. <span v-if="!isScheduled" class="red">Requires the task to be scheduled or a daily task.</span></b-switch>
          </div>
          <div class="field">
            <b-switch v-model="Task.questionsactive">
              <span v-if="!Task.questionsactive">Questionaire is not active. Click to activate</span>
              <span v-else>Questionaire is live. Click to deactivate</span>
            </b-switch>
          </div>
          <div class="txtbold midgreybgborderbottom pb3 mb11">Questions</div>
          <draggable tag="div" :list="Task.questions" handle=".handle"  @end="submitForm(false)">
            <section v-for="(q, index) in Task.questions" :index="index" :key="index">
              <div class="tsk vlgreybg mb5">
                <div>
                  <div class="txtr">{{q.type}}</div>
                  <div>
                    <b-field label="Question">
                      <b-input v-model="q.question"></b-input>
                    </b-field>
                  </div>
                  <div v-if="q.type === 'select'">
                    <b-field label="Select options">
                      <b-taginput
                          v-model="q.options"
                          ellipsis
                          icon="plus"
                          placeholder="Add an option">
                      </b-taginput>
                  </b-field>
                  </div>
                  <div class="mt5">
                    <b-field label="Answer">
                      <b-input v-model="q.answer"></b-input>
                    </b-field>
                  </div>
                  <div class="isFlex mt3">
                    <div class="isFlexGrow isFlex">
                      <div class="field" v-if="!Task.automark">
                        <b-switch size="is-small" v-model="q.skip">Not required</b-switch>
                      </div>
                      <div class="field" v-if="Task.automark">
                        <b-switch size="is-small" v-model="q.nocase">Ignore case</b-switch>
                      </div>
                    </div>
                    <div class="iconbutton" @click="questionDelete(index)">
                      <font-awesome-icon :icon="['fal', 'trash']" size="sm"></font-awesome-icon>
                    </div>
                  </div>
                </div>
                
              </div>
            </section>
          </draggable>
        </div>
        
        <div style="height: 20px;"></div>
        <div class="isFlex tsk bluebg1" v-if="editMode">
          <div class="isFlexGrow">Add New</div>
          <div class="buttons">
            <b-button size="is-small" @click="questionsAdd('text')">Text</b-button>
            <b-button size="is-small" @click="questionsAdd('textarea')">Textarea</b-button>
            <b-button size="is-small" @click="questionsAdd('select')">Select</b-button>
          </div>
        </div>
      </div>

      <div v-if="tabs.log && !tabs.loading">
        <!-- worklog: start  -->
        <div v-if="Task._id === 'new'">Please create the task first</div>
          <div v-else>
            <div class="tsk bluebg8 white mb9" v-if="wlHasOpen">
              <div>You currently have an active session <span v-if="wlActive.person !== ''">(Person): {{wlActive.person}}</span> <span v-if="wlActive.task !== ''">(Task): {{ wlActive.task }} <span v-if="wlActive.goal !== ''"> in {{wlActive.goal}}</span></span></div>
            </div>
            <div class="mb11 tsk lgreybg" v-if="!wlIsOpen">
              <div class="isFlex isFlexJustify">
                <div class="iconbutton5x " style="width; 100px;" @click="wlStart()" >
                  <font-awesome-icon :icon="['fal', 'play-circle']" size="5x" ></font-awesome-icon>
                </div>
              </div>
              <div class="txtc p3">Start a new session</div>
            </div>
            
            <div class="mb11 tsk lgreybg" v-if="wlIsOpen">
              <textarea class="inputwithbuttontext" v-model="wlSession.description" rows="2" placeholder="What did you do? Did you have any problems?" ></textarea>
              <div class="isFlex isFlexJustify">
                <div class="iconbutton5x " style="width; 100px;" @click="wlStop()" >
                  <font-awesome-icon :icon="['fal', 'stop-circle']" size="5x" ></font-awesome-icon>
                </div>
              </div>
              <div class="txtc p3">Stop session</div>
            </div>


            <div class="mb11 p9 tsk lgreybg isFlex">
              <div class="isFlexGrow mr5">
                <b-datepicker type="month" placeholder="Click to select..." icon="calendar" v-model="wlmonth" ></b-datepicker>
              </div>
              <!-- <div>
                <b-select placeholder="Team" v-model="wlTeam" @input="wlChangeTeam">
                  <option
                      v-for="option in wlTeamList"
                      :value="option"
                      :key="option._id">
                      {{ option.firstname }}
                  </option>
                </b-select> 
              </div> -->
              <!-- <div v-if="editAllowTeam">
                <b-select placeholder="Members" v-model="wlTeamMember">
                  <option v-for="option in wlTeamMembers" :value="option" :key="option._id" >{{ option.firstname }}</option>
                </b-select>
              </div> -->
              <div>
                <b-button @click="wlShowMonth">Apply</b-button>
                <b-button @click="wlMLshow=true">Manual Entry</b-button>
              </div>
            </div>

            <div v-if="wlMLshow">
              <div class="tsk vlgreybg lgreyborder mb11">
                <div class="txth3 txtbold">Manual log entry</div>
                <b-field label="From date">
                  <b-datepicker v-model="wlStartDate" placeholder="Click to select..." icon="calendar"></b-datepicker>
                </b-field>
                <b-field label="From time">
                  <b-clockpicker v-model="wlStartTime" placeholder="Click to select..." icon="clock" hour-format="24"></b-clockpicker>
                </b-field>
                <b-field label="To date">
                  <b-datepicker v-model="wlEndDate" placeholder="Click to select..." icon="calendar"></b-datepicker>
                </b-field>
                <b-field label="To time">
                  <b-clockpicker v-model="wlEndTime" placeholder="Click to select..." icon="clock" hour-format="24"></b-clockpicker>
                </b-field>
                <b-field label="What did you do?">
                  <textarea class="inputwithbuttontext" v-model="wlMLwhat" rows="2" placeholder="What did you do? Did you have any problems?" ></textarea>
                </b-field>
                <b-field label="Why was it not recorded?">
                  <textarea class="inputwithbuttontext" v-model="wlMLwhy" rows="2" placeholder="Naugthy naughty" ></textarea>
                </b-field>
                <div class="buttons">
                  <b-button @click="wlMLshow=false">Cancel</b-button>
                  <b-button @click="wlMLDo">Record Log</b-button>
                </div>
              </div>
            </div>

            <div v-for="(ss, index) in wlRows" :key="ss._id">
              <div class="tsk p9 mb11 vlgreybg isFlex isFlexAlign" v-bind:class="{ completed: ss.deleted }">
                <div class="mr5">
                  <font-awesome-icon :icon="['fal', 'alarm-exclamation']" size="lg" v-if="ss.islate && ss.islatestatus !== 'approved'"></font-awesome-icon>
                  <b-checkbox v-else v-model="ss.selected"></b-checkbox>
                </div>
                <div class="isFlexGrow">
                  <div class="isFlex">
                    <div class="isFlexGrow"><span v-if="ss.description !== ''">{{ ss.description }}</span><span v-else>No description entered</span></div>
                    <div class="txtsm">{{ ss.islatestatus }}</div>
                  </div>
                  <div class="txtsm">
                    Who: <strong>{{ ss.subname }}</strong> | Duration:
                    <strong>{{ ss.totalhours }} </strong> Hours
                    <strong>{{ ss.totalminutes }} </strong> Minutes <br />From
                    <strong >{{ ss.startdt.dayst }} {{ ss.startdt.monthtxt }} {{ ss.startdt.year }} {{ ss.startdt.hour | numberStr("0", "pre") }}:{{ss.startdt.minute | numberStr("0", "post")}}</strong>
                    to
                    <strong >{{ ss.enddt.dayst }} {{ ss.enddt.monthtxt }} {{ ss.enddt.year }} {{ ss.enddt.hour | numberStr("0", "pre") }}:{{ ss.enddt.minute | numberStr("0", "post") }}</strong>
                    <span v-if="ss.billed"> (Billed)</span>
                  </div>
                  <div class="txtsm" v-if="ss.deleted">
                    <strong>Delete Reason: </strong>{{ ss.deletedreason }}
                  </div>
                  <div class="txtsm" v-if="ss.islate && !ss.deleted">
                    <strong>Late Reason: </strong>{{ ss.islatereason }}
                    <span> ({{ ss.islatestatus }})</span>
                  </div>
                  <div class="txtsm red" v-if="ss.islatestatus === 'rejected'">
                    <strong>Message: </strong>{{ ss.islatestatusmessage }}
                  </div>
                </div>
                <div class="iconbutton" @click="wlDelete(ss, index)" v-if="ss.canedit" >
                  <font-awesome-icon :icon="['fal', 'trash']" size="lg" v-if="!ss.deleted"></font-awesome-icon>
                  <font-awesome-icon :icon="['fal', 'trash-restore']" size="lg" v-if="ss.deleted"></font-awesome-icon>
                </div>
              </div>
            </div>

            <div class="tsk p7 mb11 vlgreybg" v-if="wlRows.length === 0">
              No sessions logged.
            </div>

            <div class="mb11 p9 tsk lgreybg isFlex">
              <!-- <div class="mr5">
                <b-button @click="wlEditLog('new', {}, -1)">
                  <font-awesome-icon :icon="['fal', 'plus']" size="lg" ></font-awesome-icon>
                </b-button>
              </div> -->
              <div class="isFlexGrow mr5">
                <b-input v-model="wlFileName"></b-input>
              </div>
              <div class="mr5">
                <b-button @click="wlcsv">CSV</b-button>
              </div>
              <!-- <div>
                <b-button @click="wlbilled">Billed</b-button>
              </div> -->
            </div>
          </div>
          <!-- worklog: end  -->
      </div>
      <div v-if="tabs.more && !tabs.loading">
        
      </div>
    </section>
    <div class="p11 lgreybg" v-if="tabs.comments && canComment">
      <div><b-input v-model="newComment" maxlength="300" rows="2" type="textarea" placeholder="Post a comment"></b-input></div>
      <div class="buttons">
        <b-button expanded @click="commentAdd">Post Comment</b-button>
      </div>
    </div>
    <footer class="modal-card-foot isFlex" style="padding: 10px;" v-if="!tabs.loading && !editMode">
      <div class="isFlexGrow">
        <b-button @click="$parent.close()">Close</b-button>
        <b-button @click="editMode=true" v-if="!editMode && canEdit">Edit</b-button>
      </div>
      <div v-if="tabs.response && !isLeader">
        <b-button @click="questionsAnwer()">Save Answers</b-button>
      </div>
      <div v-if="tabs.response && isLeader">
        <b-button @click="questionsMark()">Save Responses</b-button>
      </div>
    </footer>
    <footer class="modal-card-foot isFlex" style="padding: 10px;" v-if="!tabs.loading && editMode"> 
      <div class="isFlexGrow" v-if="Task._id !== 'new'">
        <b-button @click="taskDelete()" v-if="Task.deleted">
          Delete
        </b-button>
        <b-button @click="taskRestore()" v-if="Task.deleted">Restore</b-button>
        <b-button @click="taskArchive()" v-if="!Task.deleted">Archive</b-button>
        <b-button @click="editMode=false" v-if="editMode">View</b-button>
      </div>
      <div class="isFlexGrow" v-if="Task._id === 'new'">
        <b-button @click="$parent.close()">Cancel</b-button>
      </div>
      <div v-if="Task._id !== 'new'">
        <b-button @click="$parent.close()">Close</b-button>
        <b-button @click="submitForm(false)">Save</b-button>
        <b-button @click="submitForm(true)">Save &amp; Close</b-button>
      </div>
      <div v-if="Task._id === 'new'">
        <b-button @click="submitForm(false)">Create Task</b-button>
        <b-button @click="submitForm(true)">Create Task &amp; Close</b-button>
      </div>
    </footer>
  </div>
</template>

<script>
import array from "lodash/array";
import ObjectID from "bson-objectid";
// import { EventBus } from "../event-bus.js";
import { Utils } from "@/mixins/utils.js";
import { dbFunctions } from "@/mixins/dbMixins.js";
import { Schemas } from "@/schemas/all.js";
import draggable from "vuedraggable";
import ModalEditor from "@/components/ModalEditor.vue";
import ModalImageSquare from "@/components/ModalImageSquare.vue";
import ModalVideo from "@/modals/ModalVideo.vue";
import taskitem from "@/components/task-item.vue";
export default {
  name: "ModalTask",
  mixins: [Utils, dbFunctions, Schemas],
  components: { draggable, taskitem },
  props: {
    data: Object,
    edit: Boolean,
    callBus: String
  },
  data() {
    return {
      TaskIn: this.data,
      Task: {},
      ActiveTask: '',
      TreeData: [],
      tabs: {
        loading: true,
        desc: false,
        who: false,
        when: false,
        response: false,
        log: false,
        more: false
      },

      dtStart: null,
      dtStartTime: null,
      dtEnd: null,
      dtEndTime: null,
      ModalWidth: `width: auto`,

      GoalMembers: [],
      GoalPeople: [],
      GoalNewMember: null,
      GoalNewRole: null,
      GoalNewWorker: true,
      GoalNewResources: false,
      GoalNewReview: false,
      GoalNewApprove: false,
      GoalNewComplete: false,

      workflow: [
        { value: 'New', label: 'New', icon: 'sparkles' },
        { value: 'Active', label: 'Active', icon: 'sparkles' },
        { value: 'Completed', label: 'Completed', icon: 'sparkles' }
      ],

      Me: {},

      userPriv: 'none',
      canEdit: false,
      canArc: false,
      editMode: false,
      canComment: false,

      heading: 'This is a heading',
      lorum: `Lorem ipsum dolor sit amet consectetur, adipiscing elit facilisi tempus, torquent aenean taciti ac. Dictumst vitae class orci nam nec turpis convallis mus mattis leo, luctus justo vestibulum aenean nisl facilisi a conubia fames, mauris netus dictum venenatis blandit phasellus molestie id himenaeos. Molestie vulputate arcu sociosqu suspendisse senectus habitasse sem praesent lacus luctus, aenean laoreet suscipit primis ad nibh porttitor dignissim curae urna integer, conubia sagittis et justo condimentum ac cursus pulvinar leo.`,
      image: 'https://via.placeholder.com/1280x720',

      qpeople: [],

      isforme: false,
      isScheduled: false,
      isLeader: false,
      Completed: false,

      isadmin: localStorage.getItem("isadmin"),

      activeAnswers: [],
      questionsLocked: false,

      // file uploads
      uploadShow: false,
      file: null,
      message: "",
      fileTitle: "",
      fileDesc: "",
      iseditor: false,
      // links
      showLinksAdd: false,
      linkNewType: "Web page",
      linkNewUrl: "",
      linkNewTitle: "",
      linkNewDesc: "",
      // links

      isDemo: false,
      showVideo: false,

      newComment: '',

      sub: localStorage.getItem("sub"),

      playerOptions: {
        // videojs options
        width: '300',
        autoplay: true,
        muted: false,
        language: 'en',
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [{
          type: 'video/mp4',
          src: ''
        }],
        poster: this.poster,
      },

      // worklog: start
      wlHasOpen: false,
      wlIsOpen: false,
      wlActive: null,
      wlRows: [],
      wlmonth: new Date(),
      wlFileName: "timelog.csv",
      wlTeamList: [],
      wlTeam: { _id: "all", firstname: "All Teams" },
      wlTeamMembers: [],
      wlTeamMember: {
        _id: '',
        firstname: ''
      },
      wlSession: {},
      wlOpenSession: {},

      wlMLshow: false,
      wlStartDate: null,
      wlStartTime: null,
      wlEndDate: null,
      wlEndTime: null,
      wlMLwhat: '',
      wlMLwhy: '',
      // worklog: end
    };
  },
  created() {
    this.getGoalSelect();
    this.getme();
  },
  methods: {
    async getme() {
      this.Me = await this.DataGet('/me');
      this.TaskLoad();
    },

    async TaskLoad() {
      const sub = localStorage.getItem('sub');
      if (this.TaskIn._id === "new") {
        this.Task = this.SchemaNew(this.taskSchema);
        this.Task.goalid = this.TaskIn.goalid;
        this.Task.goalcolor = this.TaskIn.goalcolor;
        this.Task.goalname = this.TaskIn.goalname;
        this.Task.people = [];
        if (this.TaskIn.parentid !== "none") {
          this.Task.parentid = this.TaskIn.parentid;
        }
        this.userPriv = 'Admin';
        this.canEdit = true;
        this.canArc = true;
        this.editMode = true;
        this.canComment = true;
      } else { // existing task
        const intask = await this.axiosget(`/task/${this.TaskIn._id}`);
      // this.changeTaskSelected(task, index);
        if (intask.errormessage) {
          this.$buefy.snackbar.open({ message: intask.errormessage, position: "is-bottom" });
          this.$parent.close();
          return;
        }
        this.Task = intask.rows;
        this.userPriv = intask.privs;
        this.canEdit = this.Task.canEdit;
        this.canArc = this.Task.canArc;
        this.canComment = this.Task.canComment;
        this.wlActive = intask.logs;
        if (this.wlActive !== null) {
          this.wlHasOpen = true;
          if(String(this.wlActive.taskid) === String(this.Task._id)) {
            const getwl = await this.DataGet(`/workload/${this.wlActive._id}`);
            this.wlSession = getwl;
            this.wlIsOpen = true;
          }
        }
      } 

      this.ActiveTask = this.Task._id;

      if( this.Task.video !== '') {
        this.playerOptions.sources[0].src = this.Task.video;
        this.showVideo = true;
      }

      this.isDemo = this.Task.demo;
      if (this.isDemo) {
        this.canEdit = true;
      }
      if (this.Task.sub === sub) {
        this.canEdit = true;
        this.isDemo = false;
      }

      if(this.edit) {
        this.editMode = true;
      }
      const usertrue = array.findLastIndex(this.Task.assignedto, { '_id': this.Me._id });
      if(usertrue !== -1) {
        const usr = this.Task.assignedto[usertrue];
        if (usr.review) {
          this.isLeader = true;
        }
        this.isforme = true;
        this.questionProcess();
      }

      for (const a of this.Task.assignedto) {
        if(!a.completed) {
          a.completed = false;
        }
        if(!a.workflow) {
          a.workflow = this.Task.workflow;
        }
      }

      if (this.goalid !== '') {
        const data = {
          parentid: this.Task.goalid,
          parenttype: "goal",
          deleted: false,
          workflowtype: "ne",
          workflow: "Completed",
          sortby: "childorder",
        };
        const tasks = await this.DataSend("post", "/taskstree", data, "", false);
        this.TreeData = tasks.rows;
      }

      if(this.Task.durantiontype === 'daily' || this.Task.ondates.length !== 0) {
        this.isScheduled = true;
      }

      if (!this.Task.mondaysettings) {
        this.Task.mondaysettings = { hours: 0, minutes: 0 };
      }
      if (!this.Task.tuesdaysettings) {
        this.Task.tuesdaysettings = { hours: 0, minutes: 0 };
      }
      if (!this.Task.wednesdaysettings) {
        this.Task.wednesdaysettings = { hours: 0, minutes: 0 };
      }
      if (!this.Task.thursdaysettings) {
        this.Task.thursdaysettings = { hours: 0, minutes: 0 };
      }
      if (!this.Task.fridaysettings) {
        this.Task.fridaysettings = { hours: 0, minutes: 0 };
      }
      if (!this.Task.saturdaysettings) {
        this.Task.saturdaysettings = { hours: 0, minutes: 0 };
      }
      if (!this.Task.sundaysettings) {
        this.Task.sundaysettings = { hours: 0, minutes: 0 };
      }
      if (!this.Task.grindhoursinday) {
        this.Task.grindhoursinday = 6;
      }
      if (!this.Task.grinddaysinmonth) {
        this.Task.grinddaysinmonth = 21;
      }
      if (!this.Task.grinddaysinweek) {
        this.Task.grinddaysinweek = 5;
      }
      if (!this.Task.grindweeksinyear) {
        this.Task.grindweeksinyear = 52;
      }

      // dates
      if (this.Task.taskstartdate !== "") {
        this.dtStart = new Date(this.Task.taskstartdate);
      }
      if (this.Task.taskstarttime !== "") {
        this.dtStartTime = new Date(this.Task.taskstarttimets);
      }
      if (this.Task.tasktilldate !== "") {
        this.dtEnd = new Date(this.Task.tasktilldate);
      }
      if (this.Task.tasktilltime !== "") {
        this.dtEndTime = new Date(this.Task.tasktilltimets);
      }
      
      if(this.Task.goalid !== '') {
        this.getGoalWorkFlow(this.Task.goalid);
        this.getGoalMembers();
      }

      this.blocksCalc();
      this.wlLoad();
      this.setWidth();
      this.tabsChange("desc");
    },

    async getGoalMembers() {
      this.GoalMembers = await this.DataGet(`/goal/availableto/${this.TaskIn.goalid}`);
      this.GoalPeople = await this.DataGet(`/goal/roles/${this.TaskIn.goalid}`);
    },

    async getGoalWorkFlow(id) {
      const getw = await this.DataGet(`/goal/workflow/${id}`);
      this.workflow = getw;
    },

    gmAdd() {
      if (this.GoalNewMember === null) {
        this.$buefy.toast.open("Select a team member");
        return;
      }
      const newmember = {
        _id: this.GoalNewMember._id,
        username: this.GoalNewMember.username,
        email: this.GoalNewMember.email,
        worker: true,
        resources: false,
        review: false,
        approve: false,
        complete: false,
        completed: false,
        workflow: this.Task.workflow,
      };
      this.Task.assignedto.push(newmember);
    },

    gmRemove(index) {
      this.$buefy.dialog.confirm({
        title: "",
        message:
          "Are you sure you want to <b>delete</b> this goal buddy / team member?",
        confirmText: "Delete",
        type: "is-danger",
        icon: 'exclamation',
        hasIcon: true,
        onConfirm: () => {
          this.Task.assignedto.splice(index, 1);
        }
      });
    },

    gpAdd() {
      if (this.GoalNewRole === null) {
        this.$buefy.toast.open("Select a team member");
        return;
      }
      this.Task.people.push(this.GoalNewRole);
    },

    gpRemove(index) {
      this.$buefy.dialog.confirm({
        title: "",
        message:
          "Are you sure you want to <b>delete</b> this role?",
        confirmText: "Delete",
        type: "is-danger",
        hasIcon: true,
        icon: 'exclamation',
        onConfirm: () => {
          this.Task.people.splice(index, 1);
        }
      });
    },

    setWidth() {
      const inw = document.body.clientWidth;
      const inh = document.body.clientHeight;
      let newsize = `width: auto`;
      if (inw > 750) {
        const usize = inh - 100;
        newsize = `width: 700px; height: ${usize}px;`;
        // newsize = `width: ${usize}px; height: 80%;`;
        this.playerOptions.width = 698;
      } else {
        this.playerOptions.width = inw;
      }
      this.ModalWidth = newsize;
    },

    tabsChange(tab) {
      this.tabs.loading = false;
      this.tabs.desc = false;
      this.tabs.comments = false;
      this.tabs.resources = false;
      this.tabs.who = false;
      this.tabs.when = false;
      this.tabs.response = false;
      this.tabs.log = false;
      this.tabs.more = false;
      this.tabs[tab] = true;
    },

    blocksCalc() {},

    blocksCalc2() {
      this.BlocksEstimated = 0;
      this.BlocksNeeded = 0;
      this.DaysNeeded = 0;
      let hasdays = false;
      let mondayblocks = 0;
      if (this.Task.monday) {
        mondayblocks +=
          this.Task.mondaysettings.hours * 4 +
          Math.round(this.Task.mondaysettings.minutes / 15);
        hasdays = true;
      }
      let tuesdayblocks = 0;
      if (this.Task.tuesday) {
        tuesdayblocks +=
          this.Task.tuesdaysettings.hours * 4 +
          Math.round(this.Task.tuesdaysettings.minutes / 15);
        hasdays = true;
      }
      let wednesdayblocks = 0;
      if (this.Task.wednesday) {
        wednesdayblocks +=
          this.Task.wednesdaysettings.hours * 4 +
          Math.round(this.Task.wednesdaysettings.minutes / 15);
        hasdays = true;
      }
      let thursdayblocks = 0;
      if (this.Task.thursday) {
        thursdayblocks +=
          this.Task.thursdaysettings.hours * 4 +
          Math.round(this.Task.thursdaysettings.minutes / 15);
        hasdays = true;
      }
      let fridayblocks = 0;
      if (this.Task.friday) {
        fridayblocks +=
          this.Task.fridaysettings.hours * 4 +
          Math.round(this.Task.fridaysettings.minutes / 15);
        hasdays = true;
      }
      let saturdayblocks = 0;
      if (this.Task.saturday) {
        saturdayblocks +=
          this.Task.saturdaysettings.hours * 4 +
          Math.round(this.Task.saturdaysettings.minutes / 15);
        hasdays = true;
      }
      let sundayblocks = 0;
      if (this.Task.sunday) {
        sundayblocks +=
          this.Task.sundaysettings.hours * 4 +
          Math.round(this.Task.sundaysettings.minutes / 15);
        hasdays = true;
      }
      // this.BlocksEstimated += (mondayblocks + tuesdayblocks + wednesdayblocks + thursdayblocks + fridayblocks + saturdayblocks + sundayblocks);
      // this.BlocksNeeded += this.timeDifference()
      let useDTR = 0;
      let dayscount = 1;
      const daycounts = {
        monday: { days: 0, hours: 0, minutes: 0, displayhours: 0, displayminutes: 0, totalminutes: 0, blocks: 0 },
        tuesday: { days: 0, hours: 0, minutes: 0, displayhours: 0, displayminutes: 0, totalminutes: 0, blocks: 0 },
        wednesday: {
          days: 0,
          hours: 0,
          minutes: 0,
          displayhours: 0,
          displayminutes: 0,
          totalminutes: 0,
          blocks: 0
        },
        thursday: {
          days: 0,
          hours: 0,
          minutes: 0,
          displayhours: 0,
          displayminutes: 0,
          totalminutes: 0,
          blocks: 0
        },
        friday: {
          days: 0,
          hours: 0,
          minutes: 0,
          displayhours: 0,
          displayminutes: 0,
          totalminutes: 0,
          blocks: 0
        },
        saturday: {
          days: 0,
          hours: 0,
          minutes: 0,
          displayhours: 0,
          displayminutes: 0,
          totalminutes: 0,
          blocks: 0
        },
        sunday: {
          days: 0,
          hours: 0,
          minutes: 0,
          displayhours: 0,
          displayminutes: 0,
          totalminutes: 0,
          blocks: 0
        }
      };
      if (this.dtStart !== null && this.dtEnd !== null) {
        useDTR = 3;
        dayscount = this.timeDifference(this.dtEnd, this.dtStart);
        for (let index = 0; index < dayscount.days; index++) {
          const newdate = this.addDays(this.dtStart, index);
          if (this.Task[newdate.daytext]) {
            daycounts[newdate.daytext].days += 1;
            daycounts[newdate.daytext].hours += this.Task[
              newdate.daytext + "settings"
            ].hours;
            daycounts[newdate.daytext].minutes += this.Task[
              newdate.daytext + "settings"
            ].minutes;
          }
        }
      }
      const daysofweek = [
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
        "sunday"
      ];
      for (const d of daysofweek) {
        const ind = daycounts[d];
        ind.totalminutes = ind.hours * 60 + ind.minutes;
        ind.blocks = ind.totalminutes / 15;
        this.BlocksEstimated += ind.blocks;
      }
      const onday = this.Task.esthours * 60 + this.Task.estminutes;
      let totalminutes = 0;
      if (this.Task.grindyears !== 0) {
        totalminutes +=
          this.Task.grindyears *
          (this.Task.grinddaysinmonth * 52 * this.Task.grindhoursinday * 60);
      }
      if (this.Task.grindmonths !== 0) {
        totalminutes +=
          this.Task.grindmonths *
          (this.Task.grinddaysinmonth * this.Task.grindhoursinday * 60);
      }
      if (this.Task.grindweeks !== 0) {
        totalminutes +=
          this.Task.grindweeks *
          (this.Task.grinddaysinweek * this.Task.grindhoursinday * 60);
      }
      if (this.Task.grinddays !== 0) {
        totalminutes += this.Task.grinddays * (this.Task.grindhoursinday * 60);
      }
      if (this.Task.grindhours !== 0) {
        totalminutes += this.Task.grindhours * 60;
      }
      if (this.Task.grindminutes !== 0) {
        totalminutes += this.Task.grindminutes;
      }

      const totalseconds = totalminutes * 60;
      const displaydatesin = this.secondsToHours(totalseconds);
      if (onday === 0) {
        this.DaysNeeded = totalminutes / (this.Task.grindhoursinday * 60);
      } else {
        this.DaysNeeded = totalminutes / onday;
      }
      this.BlocksNeeded = Math.round(totalminutes / 15);
      this.Task.esthours = displaydatesin.hours;
      this.Task.estminutes = displaydatesin.minutes;
      this.Task.estblocks = this.BlocksNeeded;

      // this.BlocksNeeded += (blocksYears + blocksMonths + blocksWeeks + blocksDays + blocksHours + blocksMinutes );
      // this.DaysNeeded += (grindYears + grindMonths + grindWeeks + grindDays + hourMinutes + grindMinutes );
    },

    changeGoal(data){
      if (data !== '') {
        const gindex = array.findLastIndex(this.GoalList, { '_id': data });
        this.Task.goalid = data;
        this.Task.goalname = this.GoalList[gindex].goaltitle;
        this.Task.goalcolor = this.GoalList[gindex].goalcolor;
      } else {
        this.Task.goalid = '';
        this.Task.goalname = '';
        this.Task.goalcolor = '';
      }
    },

    // save task: start
    async submitForm(close) {
      // start date
      if(this.isDemo) {
        this.DataDemo();
        return;
      }
      if (this.dtStart !== null) {
        const inst = this.getDateFull(this.dtStart, "none", "none", 0);
        this.Task.taskstartdate = inst.db;
        this.Task.taskstartdatets = inst.tsm;
      } else {
        this.Task.taskstartdate = "";
        this.Task.taskstartdatets = 0;
      }

      if (this.Task.video === '') {
        this.showVideo = false;
      }
      
      if (this.dtStartTime !== null) {
        if (this.dtStart === null) {
          this.$buefy.snackbar.open({
            message: "Please select a start date",
            position: "is-top"
          });
          return;
        }
        const insts = this.getDateFull(this.dtStartTime, "none", "none", 0);
        var starttime = new Date(this.dtStart);
        starttime.setHours(insts.hoursingle, insts.minutesingle, 0, 0);
        this.Task.taskstarttime = `${insts.hoursingle}:${insts.minutesingle}`;
        this.Task.taskstarttimets = starttime.getTime();
      } else {
        this.Task.taskstarttime = "";
        this.Task.taskstarttimets = 0;
      }
      // end date
      if (this.dtEnd !== null) {
        const inst = this.getDateFull(this.dtEnd, "none", "none", 0);
        this.Task.tasktilldate = inst.db;
        this.Task.tasktilldatets = inst.tsm;
      } else {
        this.Task.tasktilldate = "";
        this.Task.tasktilldatets = 0;
      }
      if (this.dtEndTime !== null) {
        if (this.dtEnd === null) {
          this.$buefy.snackbar.open({
            message: "Please select an end date",
            position: "is-top"
          });
          return;
        }
        const insts = this.getDateFull(this.dtEndTime, "none", "none", 0);
        starttime = new Date(this.dtEnd);
        starttime.setHours(insts.hoursingle, insts.minutesingle, 0, 0);
        this.Task.tasktilltime = `${insts.hoursingle}:${insts.minutesingle}`;
        this.Task.tasktilltimets = starttime.getTime();
      } else {
        this.Task.tasktilltime = "";
        this.Task.tasktilltimets = 0;
      }
      const uptask = await this.DataSend("put", "/task", this.Task);
      let type = "update";
      if (this.Task._id === "new") {
        this.Task._id = uptask.rows._id;
        this.Task.assignedto = uptask.rows.assignedto;
        type = "new";
      }
      this.$emit("taskUpdated", { type, data: uptask.rows });
      if (close) {
        this.$parent.close();
      }
      // const retdata = this.busData;
      // retdata["fields"] = this.infields;
      // EventBus.$emit(this.callBus, retdata);
    },

    taskArchive() {
      this.$buefy.dialog.confirm({
        title: "",
        message:
        "Are you sure you want to <b>Archive</b> this task and all it's child tasks?",
        confirmText: "Archive",
        type: "is-danger",
        hasIcon: true,
        icon: 'exclamation',
        onConfirm: async () => {
          if(this.isDemo) {
            this.DataDemo();
            return;
          }
          this.Task.deleted = true;
          const arctask = await this.DataDelete(`/task/archive/${this.Task._id}`, "row");
          this.$emit("taskUpdated", { type: "update", data: this.Task });
        }
      });
    },
    taskRestore() {
      this.$buefy.dialog.confirm({
        title: "",
        message:
        "Are you sure you want to <b>Restore</b> this task and all it's children tasks?",
        confirmText: "Restore",
        type: "is-danger",
        hasIcon: true,
        icon: 'exclamation',
        onConfirm: async () => {
          if(this.isDemo) {
            this.DataDemo();
            return;
          }
          this.Task.deleted = false;
          const restask = await this.DataDelete(`/task/restore/${this.Task._id}`, "row");
          this.$emit("taskUpdated", { type: "update", data: this.Task });
        }
      });
    },

    taskDelete() {
      this.$buefy.dialog.confirm({
        title: "",
        message:
        "Are you sure you want to <b>Delete</b> this task and all it's children tasks?",
        confirmText: "Delete",
        type: "is-danger",
        hasIcon: true,
        icon: 'exclamation',
        onConfirm: async () => {
          if(this.isDemo) {
            this.DataDemo();
            return;
          }
          const deltask = await this.DataDelete(`/task/${this.Task._id}`, "row");
          this.$emit("taskUpdated", { type: "delete", data: deltask });
          this.$parent.close();
        }
      });
    },
    // save task: start

    // content
    addsection(type) {
      if(type === 'heading') {
        this.Task.content.push(
          { type: 'heading', text: '', edit: false }
        );
      }
      if(type === 'paragraph') {
        this.Task.content.push(
          { type: 'paragraph', text: '', edit: false }
        );
      }
      if(type === 'image') {
        this.Task.content.push(
          { type: 'image', src: '', alt: '', edit: false }
        );
      }
    },
    contentDelete(index) {
      this.$buefy.dialog.confirm({
        title: "",
        message:
        "Are you sure you want to <b>delete</b> this content? This cannot be undone.",
        confirmText: "Delete",
        type: "is-danger",
        hasIcon: true,
        icon: 'exclamation',
        onConfirm: () => {
          this.Task.content.splice(index, 1);
        }
      });
    },

    contentEdit(row, index) {
      if(row.type === 'heading') { row.edit = true; }
      if(row.type === 'paragraph') {
        this.modalE = this.$buefy.modal.open({
          parent: this,
          component: ModalEditor,
          hasModalCard: true,
          props: {
            area: 'task',
            usetext: row.text
          },
          events: {
            textChanged: value => {
              row.text = value.html;
              this.submitForm(false);
            }
          }
        });
      }
      if(row.type === 'image') {
        this.modalI = this.$buefy.modal.open({
          parent: this,
          component: ModalImageSquare,
          hasModalCard: true,
          props: {
            area: 'task',
            inGoalId: this.Task.goalid,
            inWidth: 1200,
            inHeight: 630
          },
          events: {
            imageChanged: value => {
              row.src = value;
            }
          }
        });
      }
    },

    dailyChange() {
      if(this.Task.durantiontype === 'daily' || this.Task.ondates.length !== 0) {
        this.isScheduled = true;
      } else {
        this.isScheduled = false;
      }
    },

    questionProcess() {
      const indt = this.getDateFull(new Date(), 'none', 'none', 0);
      const tday = indt.db;
      const hasrecord = array.findLastIndex(this.Task.answers, { 'userid': this.Me._id });
      let activerecord = {};
      let user = {};
      let createnewrecord = false;
      let iscomplete = false;
      if(hasrecord === -1) {
        createnewrecord = true;
        const userin = {
          userid: this.Me._id,
          username: `${this.Me.firstname} ${this.Me.lastname}`,
          data: [],
          expanded: false,
          completed: false,
        }
        const ucrt = this.Task.answers.push(userin);
        user = this.Task.answers[ucrt - 1];
      } else {
        user = this.Task.answers[hasrecord];
      }
      if(this.Task.questionsdaily) {
        const hasday = array.findLastIndex(user.data, { 'day': tday });
        if (hasday === -1) {
          const newday = {
            day: tday,
            answers: [],
            marked: false,
            locked: false,
            completed: false,
            expanded: false,
            count: 0,
            percentage: 0,
            note: ''
          }
          user.data.unshift(newday);
        }
        activerecord = user.data[0];
      } else {
        // const activerecord = user.data[0];
        if(user.data.length === 0) {
          const newday = {
            day: tday,
            answers: []
          }
          user.data.unshift(newday);
        }
        activerecord = user.data[0];
      }
      if(activerecord.answers.length !== 0) {
        if (activerecord.locked) {
          this.questionsLocked = true;
        }

        this.Completed = activerecord.completed;

        for (const g of this.Task.questions) {
          const inqid = g._id;
          const hasans = array.findLastIndex(activerecord.answers, { 'qid': inqid });
          if (hasans !== -1) {
            g.tempanswer = activerecord.answers[hasans].answer;
            g.marked = activerecord.answers[hasans].marked;
            g.note = activerecord.answers[hasans].note;
          } 
        }
      }
      this.activeAnswers = activerecord;

    },

    showHideAnswers(row) {
      if(row.expanded) {
        row.expanded = false;
      } else {
        row.expanded = true;
      }
    },

    questionsCompile() {

    },

    async questionsAnwer() {
      let proceed = true;
      for (const qa of this.Task.questions) {
        if (qa.tempanswer === '' && qa.skip === false) {
          proceed = false;
        }
        const ans = array.findLastIndex(this.activeAnswers.answers, { 'qid': qa._id });
        if(ans === -1) {
          const newa = {
            qid: qa._id,
            question: qa.question,
            answer: qa.tempanswer,
            marked: false,
            correct: false,
            expanded: false,
            note: ''
          }
          this.activeAnswers.answers.push(newa);

        } else {
          this.activeAnswers.answers[ans].question = qa.question;
          this.activeAnswers.answers[ans].tempanswer = qa.tempanswer;
          this.activeAnswers.answers[ans].date = qa.adate;
        }
      }
      if(this.Completed && !proceed) {
        this.$buefy.snackbar.open({
          message: 'You need to complete all the questions before you can submit',
          type: 'is-danger',
          position: 'is-bottom',
          actionText: 'I understand',
          indefinite: true,
          onAction: () => {
            this.Completed = false;
          }
        })
      } else {
        this.activeAnswers.completed = this.Completed;
      }
      const data = {
        _id: this.Task._id,
        answers: this.Task.answers,
      }
      if(this.isDemo) {
        this.DataDemo();
        return;
      }
      const ansend = await this.DataSend('post', '/task/updatefew', data);
    },

    questionExpand(q) {
      if(q.expanded) {
        q.expanded = false;
      } else {
        q.expanded = true;
      }
    },

    questionsAdd(type) {
      const newrow = {
        _id: new ObjectID().str,
        type,
        question: '',
        answer: '',
        tempanswer: '',
        options: [],
        skip: false,
        nocase: false,
        edit: false,
      }
      this.Task.questions.push(newrow);

    },

    questionDelete(index) {
      this.$buefy.dialog.confirm({
        title: "",
        message:
        "Are you sure you want to <b>delete</b> this? This cannot be undone.",
        confirmText: "Delete",
        type: "is-danger",
        hasIcon: true,
        icon: 'exclamation',
        onConfirm: () => {
          this.Task.questions.splice(index, 1);
        }
      });
    },

    async questionsMark() {
      for (const a1 of this.Task.answers) {
        a1.expanded = false;
        for (const d of a1.data) {
          d.count = 0;
          d.expanded = false;
          for (const a2 of d.answers) {
            if (a2.correct) { d.count += 1; }
          }
        }
      }
      const data = {
        _id: this.Task._id,
        answers: this.Task.answers,
      }
      const ansend = await this.DataSend('post', '/task/updatefew', data);
    },

    async saveWorkflow() {
      let comp = false;
      if (this.Task.workflow === 'Completed') {
        comp = true;
      }
      const data = {
        _id: this.Task._id,
        workflow: this.Task.workflow,
        completed: comp
      }
      if(this.isDemo) {
        this.DataDemo();
        return;
      }
      const ansend = await this.DataSend('post', '/task/updatefew', data);
    },

    // resources
    // files: start
    uploadToggle() {
      if (this.uploadShow) {
        this.uploadShow = false;
      } else {
        this.uploadShow = true;
      }
    },
    onSelect() {},

    async onSubmit() {
      if (this.file === null) {
        this.$buefy.toast.open({
          message: "Please select a file to upload",
          position: "is-bottom",
          type: "is-danger"
        });
        return;
      }
      if (this.fileTitle === "") {
        this.$buefy.toast.open({
          message: "Please enter a file title",
          position: "is-bottom",
          type: "is-danger"
        });
        return;
      }
      if(this.isDemo) {
        this.DataDemo();
        return;
      }
      const formData = new FormData();
      formData.append("doc", this.file);
      formData.append("goalid", this.Task._id);
      formData.append("filetitle", this.fileTitle);
      formData.append("filedesc", this.fileDesc);
      if(this.isDemo) {
        this.DataDemo();
        return;
      }
       try {
        const sendfile = await this.DataSend("post", "/upload/task", formData, '', true);
        this.Task.files = [];
        for (const f of sendfile.rows.files) {
          this.Task.files.push(f);
          this.file = null;
          this.fileDesc = "";
          this.fileDesc = "";
          this.uploadShow = false;
        }
      } catch (error) {
        this.message = "Something went wrong!";
      }
    },

    fileEdit(row) {
      if (row.edit) {
        row.edit = false;
      } else {
        row.edit = true;
      }
    },

    fileDelete(row, index) {
      if(this.isDemo) {
        this.DataDemo();
        return;
      }
      this.$buefy.dialog.confirm({
        title: "",
        message:
        "Are you sure you want to <b>delete</b> this?",
        confirmText: "Delete",
        type: "is-danger",
        hasIcon: true,
        icon: 'exclamation',
        onConfirm: async () => {
          const delfile = await this.DataSend("post", "/filedelete/task", row);
          this.Task.files.splice(index, 1);
          this.submitForm(false);
        }
      });
    },

    playVideo(gf, type) {
      let useFull = true;
      let useCanCancel = false;
        const inw = document.body.clientWidth;
        if (inw > 750) { useFull = false; useCanCancel = true; }
      	this.modal = this.$buefy.modal.open({
          parent: this,
          component: ModalVideo,
          hasModalCard: true,
          fullScreen: useFull,
          canCancel: useCanCancel,
          width: 700,
          props: {
            title: gf.title,
            video: gf.url,
            type
          },
          events: {
            dataFromModal: value => {}
          }
        });
    },
    // files: end

    // links: start
    linksAddToggle() {
      if (this.showLinksAdd) {
        this.showLinksAdd = false;
      } else {
        this.showLinksAdd = true;
      }
    },

    linkAdd() {
      const newlink = {
        type: this.linkNewType,
        url: this.linkNewUrl,
        title: this.linkNewTitle,
        description: this.linkNewDesc,
        edit: false
      }
      this.Task.links.push(newlink);
      this.linkNewType = "Web page";
      this.linkNewUrl = "";
      this.linkNewTitle = "";
      this.linkNewDesc = "";
      this.submitForm(false);
    },

    linkEdit(row) {
      if (row.edit) {
        row.edit = false;
      } else {
        row.edit = true;
      }
    },

    linkDelete(index) {
      this.$buefy.dialog.confirm({
        title: "",
        message: "Are you sure you want to <b>delete</b> this link? This action cannot be undone.",
        confirmText: "Delete",
        type: "is-danger",
        icon: 'exclamation',
        hasIcon: true,
        onConfirm: () => {
          this.Task.links.splice(index, 1);
          this.submitForm(false);
        }
      });
    },
    // links: end

    // comments: start
    async commentAdd() {
      if(this.isDemo) {
        this.DataDemo();
        return;
      }
      if( this.newComment === '') {
        return;
      }
      if(this.isDemo) {
        this.DataDemo();
        return;
      }
      const sub = localStorage.getItem("sub");
      const first = localStorage.getItem("firstname");
      const last = localStorage.getItem("lastname");
      const nr = {
        fromid: sub,
        from: `${first} ${last}`,
        comment: this.newComment,
        date: new Date(),
      }
      this.Task.comments.push(nr);
      const data = {
        _id: this.Task._id,
        comments: this.Task.comments,
      }
      const upTask = await this.DataSend('post', '/task/updatefew', data, "", false);
      this.newComment = '';
    },

    commentDelete(i) {
      if(this.isDemo) {
        this.DataDemo();
        return;
      }
      this.$buefy.dialog.confirm({
        title: "",
        message:
        "Are you sure you want to <b>delete</b> this comment?",
        confirmText: "Delete",
        type: "is-danger",
        hasIcon: true,
        icon: 'exclamation',
        onConfirm: async () => {
          this.Task.comments.splice(i, 1);
          const data = {
            _id: this.Task._id,
            comments: this.Task.comments,
          }
          const upTask = await this.DataSend('post', '/task/updatefew', data, "", false);
        }
      });
    },
    // comments: end

    // worklog: start
    wlShowMonth() {
      this.wlRows = [];
      this.wlLoad();
    },

    async wlLoad() {
      this.wlRows = [];
      const indate = this.getDateFull(this.wlmonth, "none", "none", 0);

      const data = {
        taskid: this.Task._id,
        endmonth: indate.monthsingle,
        endyear: indate.year,
        isLeader: this.isLeader,
      }
      // if (this.wlTeamMember._id !== "all") {
      //   query.$and.push({ sub: this.wlTeamMember._id });
      // }
      const options = {};
      const getses = await this.DataSend('post', '/worklog/all', data, 'rows', false);
      for (const ss of getses) {
        if (ss.sub === this.sub) {
          if (ss.open) {
            this.wlHasOpen = true;
          }
        }
        ss.selected = false;
        // check if can edit
        ss.canedit = false;
        if (ss.sub === this.sub) {
          ss.canedit = true;
        }
        this.wlRows.push(ss);
      }
    },

    async wlStart() {
      const inMe = this.GetMeLocal();
      if (this.wlHasOpen) {
        this.$buefy.snackbar.open({
          message: "You already have an open session. Please close it first.",
          position: "is-top"
        });
        return;
      }
      const TeamLive = localStorage.getItem("TeamLive");
      const TeamLiveName = localStorage.getItem("TeamLiveName");
      const newWL = { ...this.worklog };
      const id = new ObjectID();
      const idout = id.str;
      newWL._id = idout;
      newWL.sub = inMe.sub;
      newWL.subname = inMe.subname;
      newWL.availableto = this.Task.assignedto;
      newWL.taskid = this.Task._id;
      newWL.task = this.Task.tasktext;
      newWL.goalid = this.Task.goalid;
      newWL.goal = this.Task.goalname;

      const indate = this.getDateFull(new Date(), "none", "none", 0);
      newWL.start = indate.ts;
      newWL.startdt.day = indate.daysingle;
      newWL.startdt.dayofweek = indate.dayofweek;
      newWL.startdt.dayst = indate.dayst;
      newWL.startdt.daytxt = indate.dayofweektxtshort;
      newWL.startdt.hour = indate.hoursingle;
      newWL.startdt.week = indate.week;
      newWL.startdt.minute = indate.minutesingle;
      newWL.startdt.month = indate.monthsingle;
      newWL.startdt.monthtxt = indate.monthshort;
      newWL.startdt.quarter = indate.quarter;
      newWL.startdt.year = indate.year;

      if(this.isDemo) {
        this.DataDemo();
        return;
      }

      const newlog = await this.updata( "worklog", newWL, "none", {}, false);
      const activelog = {
        _id: idout,
        taskid: this.Task._id,
        task: this.Task.tasktext,
        goalid: this.Task.goalid,
        goal: this.Task.goalname,
      }
      EventBus.$emit('has_active_task', activelog);
      this.wlSession = newlog;
      this.wlIsOpen = true;
    },

    async wlStop() {
      const row = this.wlSession;
      // if (row.description === "") {
      //   this.$buefy.snackbar.open({
      //     message:
      //       "Come on, enter what you did. A minute or so spent now will save days trying to get your hours approved.",
      //     position: "is-top",
      //     duration: 7000
      //   });
      //   return;
      // }
      const indate = this.getDateFull(new Date(), "none", "none", 0);
      row.end = indate.ts;
      row.enddt.day = indate.daysingle;
      row.enddt.dayofweek = indate.dayofweek;
      row.enddt.dayst = indate.dayst;
      row.enddt.daytxt = indate.dayofweektxtshort;
      row.enddt.hour = indate.hoursingle;
      row.enddt.week = indate.week;
      row.enddt.minute = indate.minutesingle;
      row.enddt.month = indate.monthsingle;
      row.enddt.monthtxt = indate.monthshort;
      row.enddt.quarter = indate.quarter;
      row.enddt.year = indate.year;
      row.open = false;

      // calculate the totals
      const tsecs = row.end - row.start;
      row.totaltime = tsecs;
      const insecs = this.secondsToHours(tsecs);
      row.totalhours = insecs.hours;
      row.totalminutes = insecs.minutes;
      row.totalseconds = insecs.seconds;

      if(this.isDemo) {
        this.DataDemo();
        return;
      }
      const updl = await this.DataSend('put', '/worklog/update', row);
      EventBus.$emit('has_active_task', null);
      this.wlLoad();
      this.wlIsOpen = false;
    },

    wlEditLog(mode, row, index) {
      const fields = [
        { type: "input", subtype: "textarea", field: "description", label: "What did you do?", value: "", placeholder: "I did the following...", required: "true", show: true },
        { type: "date", field: "startdate", label: "Start date", value: new Date(), show: true, inline: true },
        {
          type: "time",
          field: "starttime",
          label: "Start Time",
          hour: "9",
          minutes: "15",
          timefull: 915,
          show: true
        },
        {
          type: "date",
          field: "enddate",
          label: "End date",
          value: new Date(),
          show: true,
          inline: true
        },
        {
          type: "time",
          field: "endtime",
          label: "End Time",
          hour: "17",
          minutes: "15",
          timefull: 915,
          show: true
        },
        {
          type: "input",
          subtype: "textarea",
          field: "islatereason",
          label: "Reason for not using the timer?",
          value: "",
          placeholder: "Why?",
          required: "true",
          show: true
        },
        {
          type: "select",
          field: "islatestatus",
          label: "Approval Status",
          value: "new",
          options: [
            { label: "new", value: "new" },
            { label: "rejected", value: "rejected" },
            { label: "approved", value: "approved" }
          ],
          show: true,
          disabled: true
        },
        {
          type: "input",
          subtype: "textarea",
          field: "islatestatusmessage",
          label: "Approval message",
          value: "",
          placeholder: "",
          show: true,
          disabled: true
        }
      ];
      if (mode === "edit") {
        this.wlOpenSession = row;
        fields[0].value = row.description;
        const stdt = new Date(
          row.startdt.year,
          row.startdt.month - 1,
          row.startdt.day,
          row.startdt.hour,
          row.startdt.minute,
          0,
          0
        );
        fields[1].value = stdt;
        fields[2].hour = row.startdt.hour;
        fields[2].minutes = row.startdt.minute;
        const endt = new Date(
          row.enddt.year,
          row.enddt.month - 1,
          row.enddt.day,
          row.enddt.hour,
          row.enddt.minute,
          0,
          0
        );
        fields[3].value = endt;
        fields[4].hour = row.enddt.hour;
        fields[4].minutes = row.enddt.minute;
        fields[5].value = row.islatereason;
        fields[6].value = row.islatestatus;
        fields[7].value = row.islatestatusmessage;
        if (this.editAllow) {
          fields[6].disabled = false;
          fields[7].disabled = false;
        }
      }
      this.modalWL = this.$buefy.modal.open({
        parent: this,
        component: ModalForm,
        hasModalCard: true,
        props: {
          fields,
          callBus: "bus_wl",
          busData: {
            index,
            mode
          }
        }
      });
    },

    async wlMLDo() {
      if (this.wlStartDate === null) { this.$buefy.snackbar.open({ message: "Please select a start date", position: "is-top" }); return; }
      if (this.wlStartTime === null) { this.$buefy.snackbar.open({ message: "Please select a start time", position: "is-top" }); return; }
      if (this.wlEndDate === null) { this.$buefy.snackbar.open({ message: "Please select a end date", position: "is-top" }); return; }
      if (this.wlEndTime === null) { this.$buefy.snackbar.open({ message: "Please select a end time", position: "is-top" }); return; }
      if (this.wlMLwhy === '') { this.$buefy.snackbar.open({ message: "Please say why you are doing a manual entry?", position: "is-top" }); return; }

      let newWL = {};
      newWL = { ...this.worklog };
      const id = new ObjectID();
      const idout = id.str;

      const meIn = this.GetMeLocal();

      newWL.sub = meIn.sub;
      newWL.subname = meIn.subname;
      newWL.availableto = this.Task.assignedto;
      newWL.taskid = this.Task._id;
      newWL.task = this.Task.tasktext;
      newWL.goalid = this.Task.goalid;
      newWL.goal = this.Task.goalname;

      newWL.description = this.wlMLwhat;
      newWL.islatereason = this.wlMLwhy;

      const inst = this.getDateFull(this.wlStartDate, "none", "none", 0);
      const instspre = this.getDateFull(this.wlStartTime, "none", "none", 0);
      const newst = new Date(inst.year, inst.monthsingle-1, inst.daysingle, instspre.hoursingle, instspre.minutesingle, 0, 0);
      const insts = this.getDateFull(newst, "none", "none", 0);
      newWL.start = insts.ts;
      newWL.startdt.day = insts.daysingle;
      newWL.startdt.dayofweek = insts.dayofweek;
      newWL.startdt.dayst = insts.dayst;
      newWL.startdt.daytxt = insts.dayofweektxtshort;
      newWL.startdt.week = insts.week;
      newWL.startdt.month = insts.monthsingle;
      newWL.startdt.monthtxt = insts.monthshort;
      newWL.startdt.quarter = insts.quarter;
      newWL.startdt.year = insts.year;
      newWL.startdt.hour = insts.hoursingle;
      newWL.startdt.minute = insts.minutesingle;

      const endt = this.getDateFull(this.wlEndDate, "none", "none", 0);
      const endtpre = this.getDateFull(this.wlEndTime, "none", "none", 0);
      const newet = new Date(endt.year, endt.monthsingle-1, endt.daysingle, endtpre.hoursingle, endtpre.minutesingle, 0, 0);
      const endtt = this.getDateFull(newet, "none", "none", 0);
      newWL.end = endtt.ts;
      newWL.enddt.day = endtt.daysingle;
      newWL.enddt.dayofweek = endtt.dayofweek;
      newWL.enddt.dayst = endtt.dayst;
      newWL.enddt.daytxt = endtt.dayofweektxtshort;
      newWL.enddt.week = endtt.week;
      newWL.enddt.month = endtt.monthsingle;
      newWL.enddt.monthtxt = endtt.monthshort;
      newWL.enddt.quarter = endtt.quarter;
      newWL.enddt.year = endtt.year;
      newWL.enddt.hour = endtt.hoursingle;
      newWL.enddt.minute = endtt.minutesingle;

      newWL.open = false;
      newWL.islate = true;

      // calculate the totals
      const tsecs = newWL.end - newWL.start;
      if (tsecs < 0) {
        this.$buefy.snackbar.open({ message: "It seems you worked backwards in time. Please check your dates and times", position: "is-bottom", type: "is-danger", indefinite: true }); return;
      }
      newWL.totaltime = tsecs;
      const insecs = this.secondsToHours(tsecs);
      newWL.totalhours = insecs.hours;
      newWL.totalminutes = insecs.minutes;
      newWL.totalseconds = insecs.seconds;
      if(this.isDemo) {
        this.DataDemo();
        return;
      }
      const addnew = await this.DataSend('post', '/worklog/new', newWL);
      this.wlMLshow = false,
      this.wlStartDate = null;
      this.wlStartTime = null;
      this.wlEndDate = null;
      this.wlEndTime = null;
      this.wlMLwhat = '';
      this.wlMLwhy = '';
      this.wlLoad();
    },

    async wlEditLogDo(inbound) {
      let newWL = {};
      if (inbound.mode === "new") {
        const TeamLive = localStorage.getItem("TeamLive");
        const TeamLiveName = localStorage.getItem("TeamLiveName");
        newWL = { ...this.worklog };
        const id = new ObjectID();
        const idout = id.str;
        newWL._id = idout;
        newWL.sub = this.user.sub;
        newWL.subname = this.user.subname;
        newWL.availableto = this.prsn.availableto;
        newWL.personid = this.prsn._id;
        newWL.person = this.prsn.firstname + " " + this.prsn.lastname;
        newWL.team = TeamLiveName;
        newWL.teamid = TeamLive;
        newWL.open = false;
        newWL.islate = true;
      } else {
        newWL = this.wlOpenSession;
      }
      for (const f of inbound.fields) {
        if (f.required) {
          if (f.value === "") {
            this.$buefy.snackbar.open({
              message: "Missing: " + f.label,
              position: "is-top"
            });
            return;
          }
        }
        if (f.field === "description") {
          newWL.description = f.value;
        }
        if (f.field === "islatereason") {
          newWL.islatereason = f.value;
        }
        if (f.field === "startdate") {
          const stdt = this.getDateFull(f.value, "none", "none", 0);
          newWL.start = stdt.ts;
          newWL.startdt.day = stdt.daysingle;
          newWL.startdt.dayofweek = stdt.dayofweek;
          newWL.startdt.dayst = stdt.dayst;
          newWL.startdt.daytxt = stdt.dayofweektxtshort;
          newWL.startdt.week = stdt.week;
          newWL.startdt.month = stdt.monthsingle;
          newWL.startdt.monthtxt = stdt.monthshort;
          newWL.startdt.quarter = stdt.quarter;
          newWL.startdt.year = stdt.year;
        }
        if (f.field === "starttime") {
          newWL.startdt.hour = parseInt(f.hour);
          newWL.startdt.minute = parseInt(f.minutes);
        }
        if (f.field === "enddate") {
          const endt = this.getDateFull(f.value, "none", "none", 0);
          newWL.end = endt.ts;
          newWL.enddt.day = endt.daysingle;
          newWL.enddt.dayofweek = endt.dayofweek;
          newWL.enddt.dayst = endt.dayst;
          newWL.enddt.daytxt = endt.dayofweektxtshort;
          newWL.enddt.week = endt.week;
          newWL.enddt.month = endt.monthsingle;
          newWL.enddt.monthtxt = endt.monthshort;
          newWL.enddt.quarter = endt.quarter;
          newWL.enddt.year = endt.year;
        }
        if (f.field === "endtime") {
          newWL.enddt.hour = parseInt(f.hour);
          newWL.enddt.minute = parseInt(f.minutes);
        }
        if (f.field === "islatestatus") {
          newWL.islatestatus = f.value;
        }
        if (f.field === "islatestatusmessage") {
          newWL.islatestatusmessage = f.value;
        }
      }

      // adjust dates
      const stdt = new Date(
        newWL.startdt.year,
        newWL.startdt.month,
        newWL.startdt.day,
        newWL.startdt.hour,
        newWL.startdt.minute,
        0,
        0
      );
      const newstdt = this.getDateFull(stdt, "none", "none", 0);
      newWL.start = newstdt.ts;
      const endt = new Date(
        newWL.enddt.year,
        newWL.enddt.month,
        newWL.enddt.day,
        newWL.enddt.hour,
        newWL.enddt.minute,
        0,
        0
      );
      const newendt = this.getDateFull(endt, "none", "none", 0);
      newWL.end = newendt.ts;

      // calculate the totals
      const tsecs = newWL.end - newWL.start;
      newWL.totaltime = tsecs;
      const insecs = this.secondsToHours(tsecs);
      newWL.totalhours = insecs.hours;
      newWL.totalminutes = insecs.minutes;
      newWL.totalseconds = insecs.seconds;
      const savelog = await this.updata(
        "worklog",
        newWL,
        "none",
        {},
        false
      );
      this.wlRows = [];
      this.wlLoad();
    },

    async wlUpdate(row) {
      const updl = await this.DataSend('put', '/worklog/update', row);
    },

    async wlDelete(row) {
      if (row.deleted) {
        this.$buefy.dialog.confirm({
          title: "",
          message:
          "Restore this log entry?",
          confirmText: "Restore",
          type: "is-warning",
          hasIcon: true,
          icon: 'exclamation',
          onConfirm: async () => {
            row.deleted = false;
            row.deletedreason = '';
            const updl = await this.DataSend('put', '/worklog/update', row);
          }
        });
      } else {
        this.$buefy.dialog.prompt({
          message: `Please enter a reason for deleting this log`,
          inputAttrs: {
            placeholder: "Reason...",
            maxlength: 10
          },
          trapFocus: true,
          onConfirm: async value => {
            if(this.isDemo) {
              this.DataDemo();
              return;
            }
            row.deleted = true;
            row.deletedreason = value;
            const updl = await this.DataSend('put', '/worklog/update', row);
          }
        });
      }
    },

    wlcsv() {
      if(this.isDemo) {
        this.DataDemo();
        return;
      }
      const csvarr = [
        [
          "Goal",
          "Task",
          "Person",
          "Hours",
          "Minutes",
          "From",
          "To",
          "Description",
          "Late",
          "Late reason",
          "billed",
          "GoalId",
          "TaskId",
          "PersonId"
        ]
      ];
      let hasSelected = false;
      for (const r of this.wlRows) {
        if (r.selected) {
          hasSelected = true;
          const stdt = this.getDateFull(new Date( r.startdt.year, r.startdt.month - 1, r.startdt.day, r.startdt.hour, r.startdt.minute, 0, 0 ), "none", "none", 0);
          const endt = this.getDateFull( new Date( r.enddt.year, r.enddt.month - 1, r.enddt.day, r.enddt.hour, r.enddt.minute, 0, 0 ), "none", "none", 0);
          const nwr = [
            r.goal,
            r.task,
            r.subname,
            r.totalhours,
            r.totalminutes,
            stdt.dbtm,
            endt.dbtm,
            r.description,
            r.islate,
            r.islatereason,
            r.billed,
            r.goalid,
            r.taskid,
            r.sub
          ];
          csvarr.push(nwr);
        }
      }
      if (!hasSelected) {
        this.$buefy.snackbar.open({
          message: "Select some entries to export",
          position: "is-top"
        });
        return;
      }
      this.exportToCsv(this.wlFileName, csvarr);
    },

    wlbilled() {},

    // worklog: end

  }
}
</script>

<style>
.modallarge {
  max-width: 700px;
  min-width: 700px;
}
.ul {
  list-style-type: circle;
  padding-left: 20px;
}
</style>