<template>
  <section>
    <div class="modal-card" style="height: calc(100vh - 180px)">
      <header class="modal-card-head" style="padding: 10px;">
        <p class="modal-card-title">Contacts</p>
      </header>
      <section class="modal-card-body" style="padding: 0; height:100%;">
        <PeopleList
          :showOpen="showOpen"
          :showSelect="showSelect"
          :showCheckBox="showCheckBox"
          :hideHeader="hideHeader"
          :hideRefresh="hideRefresh"
          :hideFilter="hideFilter"
          :hideAdd="hideAdd"
          :hideFooter="hideFooter"
          :listPersonType="listPersonType"
          :listClients="listClients"
          :listSuppliers="listSuppliers"
          :listProspects="listProspects"
          :listContacts="listContacts"
          :listFriends="listFriends"
          :callBus="callBus"
        />
      </section>
      <footer class="modal-card-foot" style="padding: 10px;">
        <button class="button" type="button" @click="$parent.close()">
          Close
        </button>
      </footer>
    </div>
  </section>
</template>

<script>
import PeopleList from "@/components/PeopleList.vue";
export default {
  name: "ModalPeopleList",
  components: {
    PeopleList
  },
  props: {
    showOpen: Boolean,
    showSelect: Boolean,
    showCheckBox: Boolean,
    hideHeader: Boolean,
    hideRefresh: false,
    hideFilter: false,
    hideAdd: false,
    hideFooter: Boolean,
    listPersonType: String,
    listClients: Boolean,
    listSuppliers: Boolean,
    listProspects: Boolean,
    listContacts: Boolean,
    listFriends: Boolean,
    callBus: String
  }
};
</script>
