import { mapActions } from "vuex";
import axios from "axios";
import store from "../store";
import { EventBus } from "../event-bus.js";
// import Amplify, { Auth, API } from "aws-amplify";
// import awsconfig from "../aws-exports";
import { getTime } from "date-fns";
import * as moment from "moment";
import ObjectID from "bson-objectid";
import filter from "lodash/filter";

// Amplify.configure(awsconfig);
export const dbFunctions = {
  data() {
    return {
      onLine: navigator.onLine,
      isConnected: true,
      signedIn: true,
      db: "called",
      APIurl: "https://2po4b1nl1d.execute-api.eu-west-1.amazonaws.com/v2",
      // api: 'http://192.168.0.8:3008',
      // api: 'https://dne.limitless8.com',
      api: 'https://api.dne.life',
      // api: 'http://localhost:3008',
      goal: {
        sub: "",
        subname: "",
        team: "",
        availableto: [],
        clientid: "",
        client: "",
        goaltype: "Goal",

        goaltitle: "",
        description: "",
        why: "",
        outcome: "",
        worstcase: "",

        duedate: "",
        duedatets: 0,

        goalcolor: "#CCCCCC",
        goalstyle: "5px solid silver",
        childof: "",
        childorder: 9999,
        who: [],
        teammembers: [],
        editmode: false,

        oncal: "No",
        monday: false,
        mondaysettings: { hours: 0, minutes: 0 },
        tuesday: false,
        tuesdaysettings: { hours: 0, minutes: 0 },
        wednesday: false,
        wednesdaysettings: { hours: 0, minutes: 0 },
        thursday: false,
        thursdaysettings: { hours: 0, minutes: 0 },
        friday: false,
        fridaysettings: { hours: 0, minutes: 0 },
        saturday: false,
        saturdaysettings: { hours: 0, minutes: 0 },
        sunday: false,
        sundaysettings: { hours: 0, minutes: 0 },

        grindhoursinday: 6,
        grinddaysinmonth: 21,
        grinddaysinweek: 5,
        grindweeksinyear: 52,
        grindyears: 0,
        grindmonths: 0,
        grindweeks: 0,
        grinddays: 0,
        grindhours: 0,
        grindminutes: 0,
        grindtotal: 0,

        hourblocks: 0,
        blocksdaily: 0,
        minuteblocks: 0,
        goalorder: 0,
        completed: false,
        deleted: false,
        selected: false,
        expanded: false,
        ondates: [],
        children: [],
        tasks: [],
        lastsync: "",
        lastsyncdevice: "",
        notsynced: false
      },
      task: {
        sub: "", // the user id
        subname: "",
        team: "",
        clientid: "",
        client: "",
        availableto: [],
        assignedto: [],
        tasktext: "", // the main text for the task, like the title
        taskdescription: "", // additional description
        tasknote: "", // note
        tasktype: "todo", // the type of task. See tasktypes for types
        taskcat: "Work",
        taskcatsub: "Do",
        taskcolor: "#666",
        taskauthor: "", // used for reading
        taskwho: "",
        taskwho_id: "",
        taskwho_tel: "",
        taskwho_email: "",
        taskmeeting_location: "address",
        taskaddress: "",

        goalid: "",
        goalcolor: "",
        goalname: "",

        projectid: "",
        tasklink: "",

        durantiontype: "once",
        durantiondaily: "tillcompleted",
        monday: false,
        mondaysettings: { hours: 0, minutes: 0 },
        tuesday: false,
        tuesdaysettings: { hours: 0, minutes: 0 },
        wednesday: false,
        wednesdaysettings: { hours: 0, minutes: 0 },
        thursday: false,
        thursdaysettings: { hours: 0, minutes: 0 },
        friday: false,
        fridaysettings: { hours: 0, minutes: 0 },
        saturday: false,
        saturdaysettings: { hours: 0, minutes: 0 },
        sunday: false,
        sundaysettings: { hours: 0, minutes: 0 },

        workflow: "New",
        useicon: "sparkles",

        grindhoursinday: 6,
        grinddaysinmonth: 21,
        grinddaysinweek: 5,
        grindweeksinyear: 52,
        grindyears: 0,
        grindmonths: 0,
        grindweeks: 0,
        grinddays: 0,
        grindhours: 0,
        grindminutes: 15,
        grindtotal: 0,

        esthours: 0,
        estminutes: 15,
        estblocks: 1,

        hourblocks: 0,
        blocksdaily: 0,
        minuteblocks: 15,
        duration: 3,

        taskstartdate: "",
        taskstartdatets: 0,
        taskstarttime: "",
        taskstarttimets: 0,

        tasktilldate: "",
        tasktilldatets: 0,
        tasktilltime: "",
        tasktilltimets: 0,

        trackstart: 0,
        trackend: 0,

        taskdate: "",
        taskdatets: "",
        tasktime: "",
        tasktimets: "",
        importance: 0,
        ondates: [],
        ondatescompleted: [],
        steps: [],
        keywords: "",
        childorder: 9999,
        taskorder: 0,
        completed: false,
        deleted: false,
        expanded: false,

        comments: [],

        lastsync: "",
        lastsyncdevice: "",
        notsynced: false
      },
      person: {
        _id: "",
        sub: "",
        subname: "",
        original: "",
        from: "",
        email: "",
        persontype: "Person",
        personrole: {
          contact: true,
          friend: false,
          prospect: false,
          client: false,
          supplier: false
        },
        inteam: [],
        team: [],
        inco: [],
        availableto: [],
        clientid: "",
        clientname: "",
        salutation: "",
        firstname: "",
        lastname: "",
        coname: "",
        teamname: "",
        handle: "",
        occupation: "",
        oneliner: "",
        profile: "",
        gender: "",
        dob: "",
        coreg: "",
        vatno: "",
        contactnumbers: [{ type: "Mobile", number: "" }],
        emails: [{ type: "Work", email: "" }],
        addresses: [
          {
            type: "Work",
            address: "",
            town: "",
            county: "",
            state: "",
            postcode: "",
            country: ""
          }
        ],
        info: [{ key: "", value: "" }],
        employment: [],
        education: [],
        skils: [],
        photo: "",
        changes: [],
        schedule: [],
        publish: false,
        publishedbyid: "",
        publishedbyname: "",
        deleted: false
      },
      thought: {
        sub: '',
        subname: '',
        author: '',
        availableto: [],
        thought: '',
        link: '',
        image: '',
        hashtags: "",
        datets: 0,
        replies: 0,
        parent: '',
        reported: false,
        reports: [],
      },
      note: {
        teamid: "",
        team: "",
        taskid: "",
        task: "",
        goalid: "",
        goal: "",
        personid: "",
        person: "",
        notehtml: "",
        notetext: {},
        notedate: "",
        notedt: 0,
        deleted: false,
        sub: "",
        subname: "",
        availableto: []
      },
      worklog: {
        teamid: "",
        team: "",
        personid: "",
        person: "",
        team: "",
        taskid: "",
        task: "",
        goalid: "",
        goal: "",
        description: "",
        islate: false,
        islatereason: "",
        islatestatus: "new",
        islatestatusmessage: "",
        start: 0,
        startdt: {
          year: 0,
          quarter: 0,
          month: 0,
          monthtxt: "",
          week: 0,
          day: 0,
          dayofweek: 0,
          dayst: "",
          daytxt: "",
          hour: 0,
          minute: 0
        },
        end: 0,
        enddt: {
          year: 0,
          quarter: 0,
          month: 0,
          monthtxt: "",
          week: 0,
          day: 0,
          dayofweek: 0,
          dayst: "",
          daytxt: "",
          hour: 0,
          minute: 0
        },
        open: true,
        totaltime: 0,
        totalhours: 0,
        totalminutes: 0,
        totalseconds: 0,
        billed: false,
        availableto: [],
        deleted: false,
        deletedreason: ""
      },
      day: {
        _id: "",
        daytxt: "",
        dayts: 0,
        events: [],
        sub: "", // the user id
        subname: "",
        lastsync: 0,
        lastsyncdevice: "",
      },
      dayobj: {
        calid: '',
        dataid: '', // _id of the record
        type: '', // task | project | meeting 
        duration: 'repeat',
        subtype: '', // Task: Work | Strategic | Sales | Marketing | Self Improvement | Breakout / Meeting: Physical, Virtual, Call
        title: '', // title of the task or project
        parent: '',
        parenttext: '',
        subparent: '',
        subparenttext: '',
        blocks: 0,
        hours: 0,
        minutes: 0,
        hourstart: 0,
        hourend: 0,
        minutestart: 0,
        minuteend: 0,
        meetinghours: 0,
        meetingminutes: 0,
        travelhours: 0,
        travelminutes: 0,
        slottype: 'One to one',
        slotbill: 'Free',
        slotbillterm: '',
        slotcost: 0,
        attendees: 1,
        where: 'Office',
        address: '',
        confurl: '',
        confuser: '',
        confpin: '',
        steps: [],
        tasks: [],
        people: [],
        workflow: 'New',
        useicon: 'sparkles',
        colour: '#CCC',
        expanded: false,
        completed: false,
      },
      comment: {
        goalid: '',
        goalname: '',
        goalcolor: '',
        taskid: '',
        tasktext: '',
        meetingid: '',

      },
      optTaskCat: [
        { value: "Work", label: "Work", icon: "user-hard-hat" },
        { value: "Strategic", label: "Strategic", icon: "chart-line" },
        { value: "Sales", label: "Sales", icon: "sack-dollar" },
        { value: "Marketing", label: "Marketing", icon: "megaphone" },
        { value: "Self Improvement", label: "Self Improvement", icon: "graduation-cap" },
        { value: "Breakout", label: "Breakout", icon: "pizza-slice" },
      ],
      optContactNo: [
        { value: "Mobile", label: "Mobile" },
        { value: "Work", label: "Work" },
        { value: "Home", label: "Home" },
        { value: "Reception", label: "Reception" },
        { value: "Support", label: "Support" },
        { value: "Sales", label: "Sales" },
        { value: "Fax", label: "Fax" },
        { value: "Other", label: "Other" }
      ],
      optEmail: [
        { value: "Work", label: "Work" },
        { value: "Home", label: "Home" },
        { value: "Other", label: "Other" }
      ],
      optSex: [
        { value: "", label: "Don't know" },
        { value: "Male", label: "Male" },
        { value: "Female", label: "Female" },
        { value: "Other", label: "Gender neutral" }
      ],
      optTitles: [
        { value: "", label: "I don't assign labels to people" },
        { value: "Mr", label: "Mr" },
        { value: "Mrs", label: "Mrs" },
        { value: "Ms", label: "Ms" },
        { value: "Miss", label: "Miss" },
        { value: "Dr", label: "Dr" },
        { value: "Prof", label: "Prof" },
        { value: "Other", label: "Other" }
      ],
      optPersonType: [
        { value: "Person", label: "Person" },
        { value: "Organisation", label: "Organisation" },
        { value: "Team", label: "Team" }
      ],
      optPersonTypeSearch: [
        { value: "", label: "All" },
        { value: "Person", label: "Person" },
        { value: "Organisation", label: "Organisation" },
      ],
      teammember: {
        firstname: "",
        lastname: "",
        email: "",
        userid: "",
        status: "pending",
        goalid: "",
        invitecode: "",
        dt: "",
        peopleadmin: false,
        projectadmin: false,
        tasksadd: true,
        tasksedit: true,
        taskscomplete: true,
        tasksarchive: false,
        tasksdelete: false,
        tasksassign: false
      },
      processors: {
        async tasklist(data) {
          for (const row of data) {
            row.isChecked = false;
          }
          return data;
        },
        async peoplelist(data) {
          for (const row of data) {
            row.isChecked = false;
            if (!row.personrole) {
              row.personrole = {
                contact: false,
                friend: false,
                prospect: false,
                client: false,
                supplier: false
              };
            }
            if (!row.photo) {
              row.photo = "";
            }
            if (!row.schedule) {
              row.schedule = [];
            }
          }
          return data;
        },
        personbasic() {}
      },
      peopleworkflow: [
        { value: "Doer", label: "Doer", buyin: 0, sellat: 0 },
        { value: "Resources", label: "Resources", buyin: 0, sellat: 0 },
        {
          value: "Architect/Planner",
          label: "Architect/Planner",
          buyin: 0,
          sellat: 0
        },
        { value: "Budget", label: "Budget", buyin: 0, sellat: 0 },
        {
          value: "Customer Service",
          label: "Customer Service",
          buyin: 0,
          sellat: 0
        },
        { value: "Task Owner", label: "Task Owner", buyin: 0, sellat: 0 },
        { value: "Accounts", label: "Accounts", buyin: 0, sellat: 0 },
        { value: "Legal", label: "Legal" },
        { value: "Client", label: "Client", buyin: 0, sellat: 0 }
      ]
    };
  },

  watch: {
    onLine(v) {
      if (v) {
        this.isConnected = true;
      }
    }
  },

  created() {
    window.addEventListener("online", this.updateOnlineStatus);
    window.addEventListener("offline", this.updateOnlineStatus);
  },
  methods: {
    ...mapActions([
      "setTeam", // map `this.increment()` to `this.$store.dispatch('increment')`
      "setSignedIn"
    ]),

    async axiosget(url) {
      return new Promise(async (resolve) => {
        try {
          const token = localStorage.getItem("token");
          let response = await axios.get(`${this.api}${url}?token=${token}`);
          resolve(response.data);
        } catch (error) {
          resolve(null);
        }
      });
    },

    userSession() {
      return new Promise(async function(resolve) {
        Auth.currentAuthenticatedUser()
          .then(() => {
            const signedIn = true;
            resolve(signedIn);
          })
          .catch(() => (signedIn = false));
      });
    },
    updateOnlineStatus(e) {
      const { type } = e;
      this.onLine = type === "online";
    },

    async getData(collection, query, options, processor, listener, func='searchextended') {
      // data package init
      const data = {
        query,
        sort: "-lastsync",
        select: "",
        skip: 0,
        limit: 0,
        ActiveUser: {}
      };

      // sort: start
      if (options.sort) {
        let sortlist = "";
        for (const sr of options.sort) {
          let sfield = sr[0];
          if (sr[1] === "") {
            sfield = `-${sfield}`;
          }
          sortlist = sortlist + `${sfield}`;
        }
        data.sort = sortlist;
      }
      // sort: end

      // fields to return: start
      if (options.fields) {
        let selectlist = "";
        for (const sf in options.fields) {
          selectlist = selectlist + ` ${sf}`;
        }
        data.select = selectlist;
      }
      // fields to return: start

      // limit
      if (options.limit) {
        data.limit = options.limit;
      }

      // skip
      if (options.skip) {
        data.skip = options.skip;
      }

      const ConvertData = "" + JSON.stringify(data);
      const newData = {
        FuncType: func,
        Model: collection,
        Body: data
      };
      const token = localStorage.getItem("token");
      let response = await axios.post(`${this.api}/v1?token=${token}`, newData);
      let getD = response.data;
      let retbody = getD.body;
      if (processor !== "none") {
        retbody = this.processors[processor](getD.body);
      }
      if (listener === "none") {
        return retbody;
      } else {
        EventBus.$emit(listener, retbody);
      }
    },

    async updata(collection, data, listener, params, testmode) {
      // this.ActiveUser = await this.getProfile();
      // if (!data.sub) {
      //   data.sub = this.ActiveUser.original;
      //   data.subname = this.ActiveUser.subname;
      // }
      data.lastsync = getTime(new Date());
      delete data.isChecked;

      if (!testmode) {
        // db insert: start
        const ConvertData = "" + JSON.stringify(data);
        // const Data = this.escapeSpecialChars(ConvertData);
        const newData = {
          FuncType: "upsert",
          Model: collection,
          Body: data
        };
        const token = localStorage.getItem("token");
        let getD = await axios.post(`${this.api}/v1?token=${token}`, newData);
        if (getD.errorMessage) {
          this.$buefy.snackbar.open({
            message: getD.errorMessage,
            position: "is-top",
            duration: 10000
          });
          return;
        }

        // db insert: end
      }

      if (listener === "none") {
        return data;
      } else {
        params._id = data._id;
        params.data = data;
        EventBus.$emit(listener, params);
      }
    },

    livePostPromise(func, mod, doc) {
      // upsert
      return new Promise(async (resolve) => {
        // check if online
        if (this.isConnected) {
          // prepare the data
          if (func === "upsert") {
            doc.lastsync = getTime(new Date());
            doc.lastsyncdevice = this.deviceString;
            delete doc.notsynced;
          }
          const ConvertData = "" + JSON.stringify(doc);
          const Data = this.escapeSpecialChars(ConvertData);
          const newData = { FuncType: func, Model: mod, Body: doc };
          // add headers
          // do the request
          let apiName = "dne";
          let path = "/public";
          let myInit = {
            body: newData
          };
          // const getD = await API.post(apiName, path, myInit);
          const token = localStorage.getItem("token");
          let response = await axios.post(`${this.api}/v1?token=${token}`, newData);
          const getD = response.data;
          resolve({
            status: "success",
            message: "",
            data: getD.body
          });
          // if (getD.status) {
          //   this.$buefy.snackbar.open({
          //     message: getD.errorMessage,
          //     position: "is-top",
          //     duration: 10000
          //   });
          //   resolve({
          //     status: "error",
          //     message: getD.errorMessage,
          //     data: []
          //   });
          // } else {
            
          // }
        } else {
          resolve({
            status: "error",
            message: "No connection",
            data: []
          });
        }
      });
    },

    escapeSpecialChars(text) {
      return text
        .replace(/[\\]/g, "\\\\")
        .replace(/[\"]/g, '\\"')
        .replace(/[\/]/g, "\\/")
        .replace(/[\b]/g, "\\b")
        .replace(/[\f]/g, "\\f")
        .replace(/[\n]/g, "\\n")
        .replace(/[\r]/g, "\\r")
        .replace(/[\t]/g, "\\t");
    },

    async getProfile2() {
      let apiName = "dne";
      let path = "/public";
      const newData = { FuncType: "profile", Model: "people", Body: {} };
      let myInit = {
        body: newData
      };
      const getP = await API.post(apiName, path, myInit);
      return getP.body[0];
    },

    async GoalsData() {
      let query = { deleted: false };
      const options = { fields: { goaltitle: 1, goalcolor: 1 } };
      const GoalList = await this.getData(
        "goals",
        query,
        options,
        "none",
        "none",
        "searchextended"
      );
      GoalList.unshift({
        _id: "all",
        goaltitle: "All Projects",
        goalcolor: ""
      });
      return GoalList;
    },

    async GoalsGrouped() {
      let query = { deleted: false };
      if (this.TeamLive !== '') {
      }
      const options = { fields: { goaltitle: 1, goalcolor: 1, childof: 1 } }
      const goals = await this.getData('goals', query, options, 'none', 'none');
      let gllist = filter(goals, function(o) { return o.childof === ''; });
      for(const gc of gllist) {
        gc.children = filter(goals, function(o) { return o.childof === gc._id; });
      }
      for (const fg of gllist) {
        const rowval = { _id: fg._id, goaltitle: fg.goaltitle, goalcolor: fg.goalcolor }
        fg.val = rowval;
        for (const fgc of fg.children) {
          const subrowval = { _id: fgc._id, goaltitle: fgc.goaltitle, goalcolor: fgc.goalcolor }
          fgc.val = subrowval;
        }
      }
      const GoalList = gllist;
      GoalList.unshift({ _id: 'all', goaltitle: 'No Goal Selected', goalcolor: '#CCC', val: { _id: 'all', goaltitle: 'No Goal Selected', goalcolor: '#CCC' }});
      return GoalList;
    },

    async GoalsDataTeam() {
      const TeamLive = localStorage.getItem("TeamLive");
      let query = { deleted: false };
      if (TeamLive !== "") {
      }
      const options = { fields: { goaltitle: 1, goalcolor: 1 } };
      const GoalList = await this.getData(
        "goals",
        query,
        options,
        "none",
        "none"
      );
      GoalList.unshift({
        _id: "all",
        goaltitle: "All Projects",
        goalcolor: ""
      });
      return GoalList;
    },

    getArrayId(array, key, val) {
      for (let i = 0; i < array.length; i++) {
        const row = array[i];
        if (row[key] === val) {
          return i;
        }
      }
    },

    getUUID(asString) {
      const id = new ObjectID();
      let idout = id;
      if (asString) {
        idout = id.str;
      }
      return idout;
    },

    getTS() {
      const newTS = moment().unix();
      return newTS;
    },

    getDateFull(indate, method, units, amount) {
      let newdt = moment(indate);
      let newutc = moment(indate).utc();
      switch (method) {
        case "add":
          newdt = moment(indate).add(amount, units);
          newutc = moment(indate)
            .add(amount, units)
            .utc();
          break;
        case "subtract":
          newdt = moment(indate).subtract(amount, units);
          newutc = moment(indate)
            .subtract(amount, units)
            .utc();
          break;

        default:
          newdt = moment(indate);
          newutc = moment(indate).utc();
          break;
      }
      const tsdate = new Date(indate);
      const dtst = tsdate.setHours(0, 0, 0, 0);
      const endate = new Date(indate);
      const dtend = endate.setHours(23, 59, 59, 999);
      const dtout = {
        dtraw: newdt.format(),
        dtutc: newutc.format(),
        ts: newdt.unix(),
        tsm: newdt.valueOf(),
        tsms: dtst.valueOf(),
        tsme: dtend.valueOf(),
        db: newdt.format("YYYY-MM-DD"),
        dbtm: newdt.format("YYYY-MM-DD HH:mm:ss"),
        // year
        year: newdt.format("YYYY") * 1,
        yearshort: newdt.format("YY") * 1,
        // quarter
        quarter: newdt.format("Q") * 1,
        // month
        month: newdt.format("MM"),
        monthsingle: newdt.format("M") * 1,
        monthshort: newdt.format("MMM"),
        monthlong: newdt.format("MMMM"),
        // day
        day: newdt.format("DD"),
        daysingle: newdt.format("D") * 1,
        dayst: newdt.format("Do"), // 1st, 2nd
        dayofyear: newdt.format("DDD"),
        // week
        week: newdt.format("w") * 1,
        dayofweek: newdt.format("d") * 1,
        dayofweektxtshort: newdt.format("ddd"), // Mon, Tue
        dayofweektxtlong: newdt.format("dddd"), // Monday, Tuesday
        // time
        hour: newdt.format("HH"),
        hoursingle: newdt.format("HH") * 1,
        minute: newdt.format("mm"),
        minutesingle: newdt.format("m") * 1,
        second: newdt.format("ss"),
        secondsingle: newdt.format("ss") * 1,
        milli: newdt.format("SSS"),
      };
      return dtout;
    },

    timeDifference(date1, date2) {
      var difference = date1.getTime() - date2.getTime();

      var daysDifference = Math.floor(difference / 1000 / 60 / 60 / 24);
      difference -= daysDifference * 1000 * 60 * 60 * 24;

      var hoursDifference = Math.floor(difference / 1000 / 60 / 60);
      difference -= hoursDifference * 1000 * 60 * 60;

      var minutesDifference = Math.floor(difference / 1000 / 60);
      difference -= minutesDifference * 1000 * 60;

      var secondsDifference = Math.floor(difference / 1000);
      const retobj = {
        days: daysDifference,
        hours: hoursDifference,
        minutes: minutesDifference,
        seconds: secondsDifference
      };
      return retobj;
    },

    addDays(date, days) {
      var nwdt = new Date(date);
      nwdt.setDate(nwdt.getDate() + days);
      const dayno = nwdt.getDay();
      let retobj = {
        dt: nwdt,
        year: nwdt.getFullYear(),
        month: nwdt.getMonth() + 1,
        day: nwdt.getDate(),
        dayno: nwdt.getDay(),
        daytext: ""
      };
      if (dayno === 0) {
        retobj.daytext = "sunday";
      }
      if (dayno === 1) {
        retobj.daytext = "monday";
      }
      if (dayno === 2) {
        retobj.daytext = "tuesday";
      }
      if (dayno === 3) {
        retobj.daytext = "wednesday";
      }
      if (dayno === 4) {
        retobj.daytext = "thursday";
      }
      if (dayno === 5) {
        retobj.daytext = "friday";
      }
      if (dayno === 6) {
        retobj.daytext = "saturday";
      }
      return retobj;
    },

    assignValueToFields(fields, data) {
      for (const f of fields) {
        switch (f.type) {
          case "time":
            f.hour = data[f.field].hour;
            f.minutes = data[f.field].minutes;
            f.timefull = data[f.field].timefull;
            break;
          case "duration":
            f.hour = data[f.field].hour;
            f.minutes = data[f.field].minutes;
            f.blocks = data[f.field].blocks;
            break;

          default:
            f.value = data[f.field];
            break;
        }
        if (f.conditionkey) {
          if (fields[f.conditionkey].value === f.conditionvalue) {
            f.show = true;
          } else {
            f.show = false;
          }
        }
      }
      return fields;
    },

    assignFieldsToValue(indata, newrow) {
      for (const row of indata) {
        switch (row.type) {
          case "time":
            newrow[row.field].hour = row.hour;
            newrow[row.field].minutes = row.minutes;
            newrow[row.field].timefull = row.timefull;
            break;
          case "duration":
            newrow[row.field].hour = row.hour;
            newrow[row.field].minutes = row.minutes;
            newrow[row.field].blocks = row.blocks;
            break;

          default:
            newrow[row.field] = row.value;
            break;
        }
      }
      return newrow;
    },

    secondsToHours(inbound) {
      var sec_num = parseInt(inbound, 10); // don't forget the second param
      var hours = Math.floor(sec_num / 3600);
      var minutes = Math.floor((sec_num - hours * 3600) / 60);
      var seconds = sec_num - hours * 3600 - minutes * 60;

      // if (hours   < 10) {hours   = "0"+hours;}
      // if (minutes < 10) {minutes = "0"+minutes;}
      // if (seconds < 10) {seconds = "0"+seconds;}
      // return hours+':'+minutes+':'+seconds;
      return { hours, minutes, seconds };
    },

    exportToCsv(filename, rows) {
      var processRow = function(row) {
        var finalVal = "";
        for (var j = 0; j < row.length; j++) {
          var innerValue = row[j] === null ? "" : row[j].toString();
          if (row[j] instanceof Date) {
            innerValue = row[j].toLocaleString();
          }
          var result = innerValue.replace(/"/g, '""');
          if (result.search(/("|,|\n)/g) >= 0) result = '"' + result + '"';
          if (j > 0) finalVal += ",";
          finalVal += result;
        }
        return finalVal + "\n";
      };

      var csvFile = "";
      for (var i = 0; i < rows.length; i++) {
        csvFile += processRow(rows[i]);
      }

      var blob = new Blob([csvFile], { type: "text/csv;charset=utf-8;" });
      if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, filename);
      } else {
        var link = document.createElement("a");
        if (link.download !== undefined) {
          // feature detection
          // Browsers that support HTML5 download attribute
          var url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", filename);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    },

    async getTeams() {
      const that = this;
      return new Promise(async function(resolve) {
        const query = { deleted: false, persontype: "Team" };
        const options = { fields: { firstname: 1, inteam: 1 } };
        const TeamList = await that.getData(
          "people",
          query,
          options,
          "none",
          "none"
        );
        if (localStorage.getItem("TeamLive") !== null) {
          const tId = localStorage.getItem("TeamLive");
          const tNm = localStorage.getItem("TeamLiveName");
          const team = {
            id: tId,
            name: tNm,
            list: [],
            self: {}
          };
          for (const t of TeamList) {
            if (t._id === tId) {
              team.list = t.inteam;
            }
            for (const ita of t.inteam) {
              if (ita.userid === store.state.user.sub) {
                team.self = ita;
              }
            }
          }
          that.$store.dispatch("setTeam", team);
        }
        resolve(TeamList);
      });
    },
    // settings: start
    getSettings() {
      if (localStorage.getItem("settings") === null) {
      } else {
      }
    },
    settingsOpen() {}
    // settings: end
  },

  filters: {
    displaydate(value, f) {
      const outdate = moment(value).format(f);
      return outdate;
    },
    displaydateTS(value, f) {
      const usedate = new Date(value);
      let newdt = moment(usedate);
      const outdate = newdt.format(f);
      return outdate;
    },
    displaydateMY(value) {
      const outdate = moment(value).format("MMMM YYYY");
      return outdate;
    },
    numberStr(value, pd, pos) {
      let newno = value.toString();
      if (pos === "pre") {
        if (newno.length === 1) {
          newno = `${pd}${value}`;
        }
      }
      if (pos === "post") {
        if (newno.length === 1) {
          newno = `${value}${pd}`;
        }
      }
      return newno;
    }
  },

  beforeDestroy() {
    window.removeEventListener("online", this.updateOnlineStatus);
    window.removeEventListener("offline", this.updateOnlineStatus);
  }
};
