import Vue from "vue";
import VueRouter from 'vue-router'
import JsonCSV from 'vue-json-csv';
// import Amplify, * as AmplifyModules from 'aws-amplify'
// import { AmplifyPlugin } from 'aws-amplify-vue'
import store from "./store";
// import "./registerServiceWorker";
require('videojs-youtube');
import VueVideoPlayer from 'vue-video-player';
import 'video.js/dist/video-js.css';

Vue.component('downloadCsv', JsonCSV);
Vue.use(VueVideoPlayer, /* {
  options: global default options,
  events: global videojs events
} */);

import contenteditable from 'vue-contenteditable';
Vue.use(contenteditable)

import TextareaAutosize from 'vue-textarea-autosize';
Vue.use(TextareaAutosize)

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faUserSecret,
  faCheckSquare,
  faArrowAltLeft,
  faArrowAltRight,
  faArrowAltDown,
  faArrowAltToLeft,
  faArrowAltToRight,
  faEye,
  faEdit,
  faUser,
  faUsers,
  faRepeat,
  faPlus,
  faPlusSquare,
  faPlusCircle,
  faSearchPlus,
  faFilter,
  faUserCircle,
  faBuilding,
  faLightbulbOn,
  faStickyNote,
  faCalendarDay,
  faRulerTriangle,
  faBullseyeArrow,
  faSave,
  faTrash,
  faCheck,
  faTimes,
  faUserCrown,
  faUserPlus,
  faIdCard,
  faIdCardAlt,
  faIdBadge,
  faUsersClass,
  faExclamationTriangle,
  faSyncAlt,
  faAddressBook,
  faMonument,
  faChartNetwork,
  faPencilRuler,
  faCalendarWeek,
  faBars,
  faMobile,
  faPaperPlane,
  faMapSigns,
  faCode,
  faBold,
  faQuoteRight,
  faParagraph,
  faH1,
  faH2,
  faH3,
  faH4,
  faHorizontalRule,
  faListOl,
  faListUl,
  faTasks,
  faItalic,
  faLink,
  faStrikethrough,
  faUnderline,
  faUndo,
  faRedo,
  faPlay,
  faStop,
  faStopwatch,
  faStopCircle,
  faPlayCircle,
  faAlarmExclamation,
  faCaretDown,
  faCaretRight,
  faForklift,
  faHourglassEnd,
  faHourglassStart,
  faSort,
  faListAlt,
  // faHaykal,
  faPauseCircle,
  faMicroscope,
  faSearchDollar,
  faBalanceScale,
  faUserTag,
  faBoxFull,
  faDollyFlatbed,
  faHeadSideBrain,
  faUserHardHat,
  faDebug,
  faClipboardListCheck,
  faClipboardCheck,
  faFileSignature,
  faCreditCard,
  faArrows,
  faHandshake,
  faBrowser,
  faHome,
  faHomeAlt,
  faFileInvoice,
  faCogs,
  faCubes,
  faShoppingCart,
  faChartBar,
  faClipboardList,
  faSignOut,
  faUserLock,
  faRoute,
  faImage,
  faComment,
  faArrowsAltH,
  faMagic,
  faProjectDiagram,
  faTasksAlt,
  faDigging,
  faPersonCarry,
  faFileSearch,
  faThumbsUp,
  faCheckCircle,
  faArchive,
  faExternalLink,
  faSearch,
  faPersonDollyEmpty,
  faPersonDolly,
  faArrowToBottom,
  faRabbitFast,
  faSparkles,
  faMinus,
  faInfoCircle,
  faHeading,
  faChalkboardTeacher,
  faArrowToRight,
  faArrowFromLeft,
  faChild,
  faArrowUp,
  faArrowDown,
  faArrowLeft,
  faTrees,
  faTree,
  faExclamation,
  faInbox,
  faInboxOut,
  faInboxIn,
  faAngleUp,
  faAngleDown,
  faAngleRight,
  faAngleLeft,
  faCalendar,
  faClock,
  faCalendarStar,
  faBullseyePointer,
  faCommentsAlt,
  faHandHoldingSeedling,
  faToilet,
  faQuestionCircle,
  faBox,
  faQuestion,
  faConciergeBell,
  faNewspaper,
  faBullhorn,
  faUpload,
  faEyeSlash,
  faTrashRestore,
  faVideo,
  faExchangeAlt,
  faArrowAltUp,
  faCopy,
  faTruck,
  faClone,
  faSpiderWeb,
  faPresentation,
  faBezierCurve,
  faPeopleArrows,
  faExclamationCircle,
  faRemoveFormat,
  faColumns,
  faBorderBottom,
  faBorderLeft,
  faBorderRight,
  faBorderTop
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
library.add(
  faUserSecret,
  faCheckSquare,
  faArrowAltLeft,
  faArrowAltRight,
  faArrowAltDown,
  faArrowAltUp,
  faArrowAltToLeft,
  faArrowAltToRight,
  faEye,
  faEdit,
  faUser,
  faUsers,
  faRepeat,
  faPlus,
  faPlusSquare,
  faPlusCircle,
  faSearchPlus,
  faFilter,
  faUserCircle,
  faBuilding,
  faLightbulbOn,
  faStickyNote,
  faCalendarDay,
  faRulerTriangle,
  faBullseyeArrow,
  faSave,
  faTrash,
  faTrashRestore,
  faCheck,
  faTimes,
  faUserCrown,
  faUserPlus,
  faIdCard,
  faIdCardAlt,
  faIdBadge,
  faUsersClass,
  faExclamationTriangle,
  faSyncAlt,
  faAddressBook,
  faMonument,
  faChartNetwork,
  faPencilRuler,
  faCalendarWeek,
  faBars,
  faMobile,
  faPaperPlane,
  faMapSigns,
  faCode,
  faBold,
  faQuoteRight,
  faParagraph,
  faHeading,
  faH1,
  faH2,
  faH3,
  faH4,
  faHorizontalRule,
  faListOl,
  faListUl,
  faTasks,
  faTasksAlt,
  faItalic,
  faLink,
  faStrikethrough,
  faUnderline,
  faUndo,
  faRedo,
  faPlay,
  faStop,
  faStopwatch,
  faStopCircle,
  faPlayCircle,
  faAlarmExclamation,
  faBullseyeArrow,
  faCaretDown,
  faCaretRight,
  faForklift,
  faHourglassEnd,
  faHourglassStart,
  faSort,
  faListAlt,
  // faHaykal,
  faPauseCircle,
  faMicroscope,
  faSearchDollar,
  faBalanceScale,
  faUserTag,
  faBoxFull,
  faDollyFlatbed,
  faHeadSideBrain,
  faUserHardHat,
  faDebug,
  faExclamationTriangle,
  faClipboardListCheck,
  faClipboardCheck,
  faFileSignature,
  faCreditCard,
  faArrows,
  faHandshake,
  faImage,
  faComment,
  faArrowsAltH,
  faBrowser,
  faHome,
  faHomeAlt,
  faFileInvoice,
  faCogs,
  faCubes,
  faShoppingCart,
  faChartBar,
  faClipboardList,
  faSignOut,
  faUserLock,
  faRoute,
  faMagic,
  faProjectDiagram,
  faDigging,
  faPersonCarry,
  faFileSearch,
  faThumbsUp,
  faCheckCircle,
  faArchive,
  faExternalLink,
  faSearch,
  faPersonDolly,
  faPersonDollyEmpty,
  faArrowToBottom,
  faArrowToRight,
  faRabbitFast,
  faSparkles,
  faMinus,
  faInfoCircle,
  faChalkboardTeacher,
  faArrowFromLeft,
  faChild,
  faArrowUp,
  faArrowDown,
  faArrowLeft,
  faTrees,
  faTree,
  faExclamation,
  faInbox,
  faInboxOut,
  faInboxIn,
  faAngleUp,
  faAngleDown,
  faAngleRight,
  faAngleLeft,
  faCalendar,
  faClock,
  faCalendarStar,
  faBullseyePointer,
  faCommentsAlt,
  faHandHoldingSeedling,
  faToilet,
  faQuestionCircle,
  faBox,
  faQuestion,
  faConciergeBell,
  faNewspaper,
  faBullhorn,
  faUpload,
  faEyeSlash,
  faVideo,
  faExchangeAlt,
  faCopy,
  faTruck,
  faClone,
  faSpiderWeb,
  faPresentation,
  faBezierCurve,
  faPeopleArrows,
  faExclamationCircle,
  faRemoveFormat,
  faColumns,
  faBorderBottom,
  faBorderLeft,
  faBorderRight,
  faBorderTop
);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('vue-fontawesome', FontAwesomeIcon);

import App from "./App.vue";
import router from "./router";
// import config from './aws-exports';

import Buefy from "buefy";
import "buefy/dist/buefy.css";

Vue.use(Buefy, {
  defaultIconComponent: 'vue-fontawesome',
  defaultIconPack: 'fal',
});

Vue.use(VueRouter)
Vue.config.productionTip = false
Vue.use(Buefy, {
  defaultIconPack: 'fas',
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");

PusherPushNotifications.init({
  instanceId: 'cf17aa36-ca41-4a49-97bb-7ba746965847',
})
  .then(beamsClient =>
    beamsClient
      .start()
      .then(() => beamsClient.addDeviceInterest('hello'))
      .then(() => console.log('Successfully registered and subscribed! Device ID:', beamsClient.deviceId)),
  )
  .catch(console.error);
