<template>
  <div class="outer">
    <div class="container-login100">
      <div class="wrap-login100 p-l-50 p-r-50 p-b-30">
        <form class="login100-form validate-form">
          <div class="position-relative image-side pt11">
            <img src="/logos/dne-250.png" />
          </div>

          <div class="txtc w-full mv15" style="font-size: 1.3em;">
            <div class="mb5"> Not registered? Register now for free.</div> <router-link tag="button" class="button is-danger is-fullwidth" to="/signup">Register Now!</router-link>
          </div>
          <div style="height: 20px;"></div>

          <span class="login100-form-title p-b-55">Login</span>

          <b-field label="Email" style="color: white;">
            <b-input v-model="email" type="email" expanded></b-input>
          </b-field>
          <b-field label="Password" v-if="showPass">
            <b-input type="password" v-model="password" password-reveal></b-input>
          </b-field>
          <b-field label="Recovery Pin" style="color: white;" v-if="showPin">
            <b-input v-model="pin" expanded></b-input>
          </b-field>
          <b-field label="Choose a New Password" v-if="showPin">
            <b-input type="password" v-model="password" password-reveal></b-input>
          </b-field>
          <div v-if="showProceed" @click="loginpre">
            <b-button expanded>Proceed</b-button>
          </div>
          <div v-if="showPass" @click="loginpass">
            <b-button expanded>Login</b-button>
          </div>
          <div v-if="showRecover" @click="requestPin">
            <b-button expanded>Send recovery pin</b-button>
          </div>
          <div v-if="showPin" @click="sendPin">
            <b-button expanded>Reset password</b-button>
          </div>

          <div class="text-center w-full mt11" v-if="!showRecover">
            <span class="txt1"> Not a member? </span>
            <router-link tag="a" class="al" to="/signup">Sign up now</router-link>
          </div>
          <div class="text-center w-full" v-if="!showRecover">
            <span class="txt1"> Can't login? </span>
            <a class="al" @click="resetPass">Reset password</a>
          </div>
          <div class="text-center w-full mt11" v-if="showRecover">
            <span class="txt1"> I have a recover pin: </span>
            <a class="al" @click="openPin">Enter Pin</a>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { mapActions } from "vuex";
import store from "../store";
import { EventBus } from "../event-bus.js";
import { Utils } from "../mixins/utils.js";
export default {
  mixins: [Utils],
  name: "Login",
  store,
  data() {
    return {
      email: "",
      password: "",
      pin: "",
      showProceed: true,
      showPass: false,
      showRecover: false,
      showPin: false,
      showTfa: false,
      showPinSent: false
    };
  },
  created() {
    if (localStorage.getItem("token") !== null) {
      this.$router.push({ name: "home" });
    }
  },
  methods: {
    ...mapActions([
      "setAdmin", // map `this.increment()` to `this.$store.dispatch('increment')`
      "setPermissions",
      "setUser",
    ]),

    async loginpre() {
      if (this.email === "") {
        this.$buefy.snackbar.open({
          message: "Please enter a valid email address",
          position: "is-top"
        });
        return;
      }
      try {
        let response = await axios.post(`${this.api}/loginpre`, {
          uname: this.email
        });
        const data = response.data;
        if (data.status === 400) {
          this.$buefy.snackbar.open({
            message: data.message,
            position: "is-top"
          });
          return;
        }
        if (data.type === "pass") {
          this.showPass = true;
          this.showProceed = false;
        } else {
          this.showTfa = true;
          this.showProceed = false;
        }
      } catch (err) {}
    },

    async loginpass() {
      if (this.email === "") {
        this.$buefy.snackbar.open({
          message: "Please enter a valid email address",
          position: "is-top"
        });
        return;
      }
      if (this.password === "") {
        this.$buefy.snackbar.open({
          message: "Please enter your password",
          position: "is-top"
        });
        return;
      }
      try {
        let response = await axios.post(`${this.api}/loginpass`, {
          uname: this.email,
          upass: this.password
        });
        const data = response.data;
        if (data.status === 400) {
          this.$buefy.snackbar.open({
            message: data.message,
            position: "is-top"
          });
          return;
        }
        if (data.status === 200) {
          this.$buefy.snackbar.open({
            message: data.message,
            position: "is-top"
          });
          localStorage.setItem("token", data.token);
          localStorage.setItem("sub", data.sub);
          localStorage.setItem("firstname", data.firstname);
          localStorage.setItem("lastname", data.lastname);
          localStorage.setItem("isadmin", data.admin);
          localStorage.setItem("demo", data.demo);
          const user = {
            sub: data.sub,
            subname: `${data.firstname} ${data.lastname}`,
            email: data.email,
            admin: data.admin
          };
          this.$store.dispatch("setUser", user);
          const inperms = "" + JSON.stringify(data.permissions);
          localStorage.setItem("permissions", inperms);
          // EventBus.$emit("check_usr", {});
          if (localStorage.getItem("lastpage") !== null) {
            const pushto = localStorage.getItem("lastpage");
            this.$router.push({ path: pushto });
          } else {
            this.$router.push({ name: "home" });
          }
          
        }
      } catch (error) {
        this.$buefy.snackbar.open({ message: error, position: "is-top" });
      }
    },

    resetPass() {
      this.showProceed = false;
      this.showPass = false;
      this.showRecover = true;
      this.showPin = false;
    },

    openPin() {
      this.showProceed = false;
      this.showPass = false;
      this.showRecover = false;
      this.showPin = true;
    },

    backtologin() {
      this.showProceed = false;
      this.showPass = false;
      this.showRecover = false;
      this.showPin = false;
    },

    async requestPin() {
      if(this.email === '') {
        this.$buefy.toast.open({ message: "Please enter a valid email", position: "is-bottom", type: "is-danger" });
        return;
      }
      const sendcode = await this.DataSend('post', '/requestpin', { email: this.email });
      if (sendcode.status === 200) {
        this.openPin();
      }
    },

    async sendPin() {
      if(this.email === '') {
        this.$buefy.toast.open({ message: "Please enter a valid email", position: "is-bottom", type: "is-danger" });
        return;
      }
      if(this.pin === '') {
        this.$buefy.toast.open({ message: "Please enter a valid pin", position: "is-bottom", type: "is-danger" });
        return;
      }
      if(this.password === '') {
        this.$buefy.toast.open({ message: "Please enter a password", position: "is-bottom", type: "is-danger" });
        return;
      }
      const indata = {
        email: this.email,
        pin: this.pin,
        password: this.password,
      }
      const data = await this.DataSend('post', '/changepass', indata);
      if (data.status === 200) {
        this.$buefy.snackbar.open({
          message: data.message,
          position: "is-top"
        });
        localStorage.setItem("token", data.token);
        localStorage.setItem("sub", data.sub);
        localStorage.setItem("firstname", data.firstname);
        localStorage.setItem("lastname", data.lastname);
        localStorage.setItem('subname', `${data.firstname} ${data.lastname}`);
        localStorage.setItem("isadmin", data.admin);
        localStorage.setItem("demo", data.demo);
        localStorage.setItem('activegoal', '');
        const user = {
          sub: data.sub,
          subname: `${data.firstname} ${data.lastname}`,
          email: data.email,
          admin: data.admin
        };
        this.$store.dispatch("setUser", user);
        const inperms = "" + JSON.stringify(data.permissions);
        localStorage.setItem("permissions", inperms);
        EventBus.$emit("check_usr", {});
        this.$router.push({ name: "home" });
      }
    }

  }
};
</script>
<style>
.outer {
  position: absolute;
  width: 100%;
  margin: 0 auto;
  top: 0px;
  left: 0px;
  height: 100%;
  bottom: 0px;
  overflow: auto;
}
.container-login100 {
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background: #410000;
  background: -webkit-linear-gradient(to top, #1300bd, #0e0035);
  background: -o-linear-gradient(to top, #1300bd, #0e0035);
  background: -moz-linear-gradient(to top, #1300bd, #0e0035);
  background: linear-gradient(to top, #1300bd, #0e0035);
  position: relative;
  z-index: 1;
}

.wrap-login100 {
  width: 450px;
  background: #150072;
  border-radius: 7px;
  color: white;
}

.login100-form .label {
  color: white !important;
}

.p-r-50 {
  padding-right: 50px;
}
.p-l-50 {
  padding-left: 50px;
}
.p-b-30 {
  padding-bottom: 30px;
}
.p-t-77 {
  padding-top: 77px;
}
.login100-form {
  width: 100%;
  /* display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; */
}
.login100-form-title {
  font-size: 30px;
  color: #fff;
  line-height: 1.2;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  display: block;
}

.al {
  color: #f6921e;
}
.al:hover {
  color: #fcbe77;
}

@media (max-width: 480px) {
  .wrap-login100 {
    padding-left: 15px;
    padding-right: 15px;
  }
}
</style>
