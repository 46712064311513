<template>
  <li>
    <div class="txtbold isPointer" :class="{ 'red': Active === task._id, 'tcompleted': task.completed }" @click="taskOpenList(task)">{{task.tasktext}}</div>
    <div class="txtsm">{{task.taskdescription}}</div>
    <ul class="ul" v-if="task.steps.length !== 0">
      <taskitem v-for="(task, index) in task.steps" :key="index" :item="task" :Active="Active"></taskitem></ul></li>
</template>

<script>
import { Utils } from "@/mixins/utils.js";
import ModalTask from "@/modals/ModalTask.vue";
export default {
  name: 'taskitem',
  mixins: [Utils],
  props: {
    item: Object,
    Active: { type: String, default: '' }
  },
  data: function () {
    return {
      task: this.item
    }
  },
  methods: {
    async taskOpenList(task) {
      let useFull = true;
      let useCanCancel = false;
      const inw = document.body.clientWidth;
      if (inw > 750) { useFull = false; useCanCancel = true; }
      this.modal = this.$buefy.modal.open({
        parent: this,
        component: ModalTask,
        hasModalCard: true,
        fullScreen: useFull,
        canCancel: useCanCancel,
        width: 700,
        props: {
          data: task, // this is the row data that gets sent to the modal
          callBus: "Modal_Goals_Task" // name of the listner for the data coming in from the modal
        },
        events: {
          taskUpdated: value => {
            task.completed = value.data.completed;
          }
        }
      });
    },
  },
}
</script>