<template>
  <div class="modal-card" :style="ModalWidth">
    <header class="modal-card-head whitebg" style="padding: 10px; width: 250px;"  v-if="tabs.loading"></header>
    <section class="whitebg roundedtop">
      <div v-if="Goal._id === 'new'" class="txtc p3">Create Goal to activate menu</div>
      <nav class="tabs is-centered" v-if="!tabs.loading && Goal._id !== 'new'">
        <ul>
          <li :class="{ 'is-active': tabs.desc }">
            <a @click="tabsChange('desc')">What</a>
          </li>
          <li :class="{ 'is-active': tabs.resources }">
            <a @click="tabsChange('resources')">Resources</a>
          </li>
          <li :class="{ 'is-active': tabs.who }">
            <a @click="tabsChange('who')">Who</a>
          </li>
          <li :class="{ 'is-active': tabs.when }" v-if="editMode">
            <a @click="tabsChange('when')">When</a>
          </li>
          <li :class="{ 'is-active': tabs.workflow }" v-if="editMode">
            <a @click="tabsChange('workflow')">Workflow</a>
          </li>
          <li :class="{ 'is-active': tabs.worklog }" v-if="editMode">
            <a @click="tabsChange('worklog')">Work Log</a>
          </li>
          <li :class="{ 'is-active': tabs.more }" v-if="editMode">
            <a @click="tabsChange('more')">Blueprint</a>
          </li>
        </ul>
      </nav>
    </section>
    <section class="modal-card-body">
      <div class="tab-item" v-if="tabs.loading">
        <b-progress type="is-info"></b-progress>
      </div>
      <template v-if="!editMode && !tabs.loading && tabs.desc">
        <template v-if="showVideo">
          <div style="background-color: white; margin-left: -20px; margin-top: -20px;">
            <video-player  class="video-player-box"
              ref="videoPlayer"
              :options="playerOptions"
              :playsinline="true"
              customEventName="customstatechangedeventname">
            </video-player>
          </div>
        </template>
        <template v-else>
          <div v-if="Goal.blueprintimage !== ''" :style="ImageStyle">
            <img :src="Goal.blueprintimage" alt="">
          </div>
        </template>
      </template>
      <!-- what; start  -->
      <div v-if="tabs.desc">
        <div class="isFlex mb11">
          <div class="txth3 mt9 isFlexGrow">{{ Goal.goaltitle }}</div>
          <!-- <div class="isFlex" v-if="canEdit">
            <div><b-button size="is-small" @click="editMode=true" v-if="!editMode">Edit</b-button></div>
            <div><b-button size="is-small" @click="editMode=false" v-if="editMode">View</b-button></div>
          </div> -->
        </div>
        <b-field label="Goal tile" v-if="editMode">
          <b-input v-model="Goal.goaltitle"></b-input>
        </b-field>

        <div class="field" v-if="editMode">
            <b-switch v-model="Goal.planning">Planning mode. Activate to not show the goal on the calendar.</b-switch>
        </div>

        <b-field label="Task completion flow" v-if="editMode">
            <b-select v-model="Goal.goaltype" placeholder="Select..." expanded>
                <option value="Manual">Tasks are manually scheduled</option>
                <option value="Succession">Tasks to be completed in succession</option>
                <option value="Timeline">Tasks are scheduled on the calendar</option>
            </b-select>
        </b-field>

        <div class="mb7" v-if="!editMode">{{Goal.description}}</div>
        <b-field label="Short desctription" v-if="editMode">
          <b-input v-model="Goal.description" maxlength="500" type="textarea"></b-input>
        </b-field>
        
        <!-- what list: start -->
        <div class="label">What?</div>
        <div class="mb11" style="margin-left: 22px;" v-if="!editMode">
          <ul style="list-style: circle;">
            <li v-for="(wl, wi) in Goal.whatlist" :key="wi">{{ wl.text }}</li>
          </ul>
        </div>
        <div class="txtsm pb7" v-if="editMode">
          Describe what the goal is in detail using short bullet points. Try for a minimum of 3 points.
        </div>
        <div v-if="editMode">
          <div class="isFlex mb9" v-for="(wl, wi) in Goal.whatlist" :key="wi">
            <div class="isFlexGrow">
              <b-field>
                <b-input v-model="wl.text" type="textarea" rows="1"></b-input>
              </b-field>
            </div>
            <div class="iconbutton2x tsk bluebg1 ml5" @click="wlDelete(wi)">
              <font-awesome-icon :icon="['fal', 'trash']" size="lg" ></font-awesome-icon>
            </div>
          </div>
          <div class="isFlex mb9">
          <div class="isFlexGrow">
            <b-field>
              <b-input v-model="wlNewText" type="textarea" rows="1"></b-input>
            </b-field>
          </div>
          <div class="iconbutton2x tsk bluebg1 ml5" @click="wlAdd()">
            <font-awesome-icon :icon="['fal', 'plus']" size="lg" ></font-awesome-icon>
          </div>
        </div>
        </div>

        <!-- what list: start -->
        <!-- why list: start -->
        <div class="label">Why?</div>
        <div class="mb11" style="margin-left: 22px;" v-if="!editMode">
          <ul style="list-style: circle;">
            <li v-for="(wy, wyi) in Goal.whylist" :key="wyi">{{ wy.text }}</li>
          </ul>
        </div>
        <div v-else>
          <div class="txtsm pb7">
            Why do you want to do this? What drives you. The more bullet points
            you list the more motivated you will be to achieve this goal.
          </div>
          <div class="isFlex mb9" v-for="(wy, wyi) in Goal.whylist" :key="wyi">
            <div class="isFlexGrow">
              <b-field>
                <b-input v-model="wy.text" type="textarea" rows="1"></b-input>
              </b-field>
            </div>
            <div class="iconbutton2x tsk bluebg1 ml5" @click="whyDelete(wy)">
              <font-awesome-icon :icon="['fal', 'trash']" size="lg" ></font-awesome-icon>
            </div>
          </div>
          <div class="isFlex mb9">
            <div class="isFlexGrow">
              <b-field>
                <b-input v-model="whyNewText" type="textarea" rows="1"></b-input>
              </b-field>
            </div>
            <div class="iconbutton2x tsk bluebg1 ml5" @click="whyAdd()">
              <font-awesome-icon :icon="['fal', 'plus']" size="lg" ></font-awesome-icon>
            </div>
          </div>
        </div>
        
        
        <!-- why list: end -->

        <div v-if="!editMode">
          <div style="height: 20px;"></div>
          <div class="txth4">Outline</div>

          <ul class="ul">
            <taskitem v-for="(task, index) in TreeData" :key="index" :item="task" :Active="ActiveTask"></taskitem>
          </ul>
        </div>

        <!-- colour picker: start -->
        <b-field label="Colour" v-if="editMode">
          <compact-picker v-model="colors" @input="changeColor" />
        </b-field>
        <!-- colour picker: end -->
      </div>
      <!-- what; end  -->

      <!-- who: start -->
      <div class="tab-item" v-if="tabs.who">
        <div v-if="editMode">
          <p>
            In this section, we list all the people that can help us achieve this
            goal, either as:
          </p>
          <ul>
            <li>
              <strong>A goal bubby:</strong> someone who holds you accountable
              accountable and gives you advice, this can also be a mentor or a
              coach.
            </li>
            <li>
              <strong>Team member:</strong> business partner or staff member that
              will help you complete the actions points for this goal.
            </li>
            <!-- <li>
              <strong>Supplier:</strong> this is people or businesses that can
              supply services or goods that you need to achieve this goal.
            </li> -->
          </ul>
          <div style="height: 20px;"></div>
          <div class="isFlex mt11 bluebg1 p5 tsk" v-if="canEdit">
            <div class="isFlexGrow">Invite Team members &amp; Goal buddies</div>
            <!-- <div><b-button size="is-small">Invite</b-button></div> -->
          </div>
          <!-- invite goal buddy; start -->
          <div class="isFlex isFlexFlow blueborder1bottom pb5 mb5" v-if="canEdit">
            <div class="isFlexGrow p3">
              <b-input
                v-model="gbEmail"
                placeholder="someone@their-email.com"
              ></b-input>
            </div>
            <div class="p3">
              <b-select placeholder="Select role" v-model="gbRole" style="width: 150px;">
                <option v-for="option in gbPrefs" :value="option.value" :key="option.value" >
                  {{ option.label }}
                </option>
              </b-select>
            </div>
            <div class="p3">
              <b-button @click="gbSendInvite">Invite</b-button>
            </div>
          </div>
          <!-- invite goal buddy; end -->
          <!-- goal bubbies list: start -->
          <div v-if="editMode">
            <div class="m5 txtbold" v-if="Invites.length !== 0">
              Pending invites
            </div>
            <div class="isFlex isFlexAlign blueborder1bottom" v-for="(inv, invindex) in Invites" :key="inv._id" >
              <div class="p3 mt5 isFlexGrow">
                <div v-if="inv.username !== ''">
                  <strong>{{ inv.username }}</strong>
                </div>
                <div>{{ inv.useremail }}</div>
              </div>
              <div class="p3 mt5 red" v-if="inv.status === 'Rejected'">
                {{ inv.status }}
              </div>
              <div class="p3 mt5" v-else>
                {{ inv.status }}
              </div>
              <div class="p3">
                <b-button @click="gbDeleteInvite(inv, invindex)">Delete</b-button>
              </div>
            </div>
            <div style="height: 25px;"></div>
          </div>
        </div>
        
        
        
        
        <div class="m5 txtbold blueborder1bottom pb5">
          Goal buddies / Team members
        </div>
        <div class="m5" v-if="Goal.availableto.length === 0">
          You don't have any team members or goal buddies to help you. Start
          inviting using the invite form above.
        </div>

        <div class="isFlex blueborder1bottom" v-for="(ga, index) in Goal.availableto" :key="ga._id" >
          <div class="p3 isFlexGrow">
            <div>
              <strong>{{ ga.username }}</strong>
              <span v-if="ga.permission === 'Owner'"> (Owner)</span>
            </div>
            <div>{{ ga.email }}</div>
          </div>
          <div class="isFlex" v-if="editMode">
            <div class="p3 mt5">Hourly</div>
            <div class="p3 mt5" style="width: 70px;">
              <b-input v-model="ga.perhour" size="is-small" type="number"></b-input>
            </div>
            <div class="p3 mt5" v-if="canEdit && ga.permission !== 'Owner'">
              <b-select placeholder="Select role" v-model="ga.permission" style="width: 120px;" size="is-small">
                <option v-for="option in gbPrefs" :value="option.value" :key="option.value" >
                  {{ option.label }}
                </option>
              </b-select>
            </div>
            <div class="p3 mt5" v-if="canEdit && ga.permission !== 'Owner'">
              <b-button @click="gbDeleteBuddy(index)" size="is-small">Remove</b-button>
            </div>
          </div>
        </div>
        <!-- goal bubbies list: start -->
        <!-- client: start -->
        <div v-if="editMode && canEdit" class="mt11">
          <b-field label="Assign to Client">
            <b-select placeholder="Select..." icon="users" v-model="Goal.clientid" expanded>
              <option value="">None</option>
              <template v-for="(c, ci) in clients">
                <option :value="c._id" :key="ci">{{c.firstname}} {{c.lastname}}</option>
              </template>
              
            </b-select>
          </b-field>
        </div>
        <div class="mt11">
          <div class="txtbold">Assigned to client</div>
          <div>{{Goal.client}}</div>
        </div>
        <!-- client: end -->
      </div>
      <!-- who: end -->

      <!-- when: start -->
      <div class="tab-item" v-if="tabs.when">
        <div v-if="!Goal.blueprint">
          <b-field label="When do you want to achieve this by?" class="mt11">
            <b-datepicker v-model="DueDate" placeholder="Click to select..." icon="calendar">
              <button class="button is-primary is-small" @click="DueDate = new Date()">
                <b-icon icon="calendar-day"></b-icon>
                <span>Today</span>
              </button>
              <button class="button is-danger is-small" @click="DueDate = null">
                <b-icon icon="times"></b-icon>
                <span>Clear</span>
              </button>
            </b-datepicker>
          </b-field>
        </div>

        <div class="txtbold mt11 mb7" v-if="!Goal.blueprint">
          What days of the week can you work on this {{ Goal.goaltype }} and for
          how long every day?
        </div>
        <div>
          <div class="txtbold mt11 mb7 red" v-if="Goal.blueprinttype === 'Lead'">
            Please specify the below on the timeline. 
          </div>
          <div class="txtbold mt11 mb7" v-else>
            On what days are you available to offer support. Specify the time and duration
          </div>
        </div>
        

        <!-- monday -->
        <div class="txtbold mb7">Monday</div>
        <div class="isFlex inputbr isFlexAlign tsk mb5">
          <div><b-checkbox v-model="Goal.monday"></b-checkbox></div>
          <div class="p5">H</div>
          <div style="width: 100px;">
            <b-numberinput
              v-model="Goal.mondaysettings.hours"
              size="is-small"
              type="is-light"
            ></b-numberinput>
          </div>
          <div class="p5">M</div>
          <div style="width: 100px;">
            <b-numberinput
              v-model="Goal.mondaysettings.minutes"
              size="is-small"
              type="is-light"
              step="15"
            ></b-numberinput>
          </div>
        </div>
        <!-- tuesday -->
        <div class="txtbold mb7">Tuesday</div>
        <div class="isFlex inputbr isFlexAlign tsk mb5">
          <div><b-checkbox v-model="Goal.tuesday"></b-checkbox></div>
          <div class="p5">H</div>
          <div style="width: 100px;">
            <b-numberinput
              v-model="Goal.tuesdaysettings.hours"
              size="is-small"
              type="is-light"
            ></b-numberinput>
          </div>
          <div class="p5">M</div>
          <div style="width: 100px;">
            <b-numberinput
              v-model="Goal.tuesdaysettings.minutes"
              size="is-small"
              type="is-light"
              step="15"
            ></b-numberinput>
          </div>
        </div>
        <!-- wednesday -->
        <div class="txtbold mb7">Wednesday</div>
        <div class="isFlex inputbr isFlexAlign tsk mb5">
          <div><b-checkbox v-model="Goal.wednesday"></b-checkbox></div>
          <div class="p5">H</div>
          <div style="width: 100px;">
            <b-numberinput
              v-model="Goal.wednesdaysettings.hours"
              size="is-small"
              type="is-light"
            ></b-numberinput>
          </div>
          <div class="p5">M</div>
          <div style="width: 100px;">
            <b-numberinput
              v-model="Goal.wednesdaysettings.minutes"
              size="is-small"
              type="is-light"
              step="15"
            ></b-numberinput>
          </div>
        </div>
        <!-- thursday -->
        <div class="txtbold mb7">Thursday</div>
        <div class="isFlex inputbr isFlexAlign tsk mb5">
          <div><b-checkbox v-model="Goal.thursday"></b-checkbox></div>
          <div class="p5">H</div>
          <div style="width: 100px;">
            <b-numberinput
              v-model="Goal.thursdaysettings.hours"
              size="is-small"
              type="is-light"
            ></b-numberinput>
          </div>
          <div class="p5">M</div>
          <div style="width: 100px;">
            <b-numberinput
              v-model="Goal.thursdaysettings.minutes"
              size="is-small"
              type="is-light"
              step="15"
            ></b-numberinput>
          </div>
        </div>
        <!-- friday -->
        <div class="txtbold mb7">Friday</div>
        <div class="isFlex inputbr isFlexAlign tsk mb5">
          <div><b-checkbox v-model="Goal.friday"></b-checkbox></div>
          <div class="p5">H</div>
          <div style="width: 100px;">
            <b-numberinput
              v-model="Goal.fridaysettings.hours"
              size="is-small"
              type="is-light"
            ></b-numberinput>
          </div>
          <div class="p5">M</div>
          <div style="width: 100px;">
            <b-numberinput
              v-model="Goal.fridaysettings.minutes"
              size="is-small"
              type="is-light"
              step="15"
            ></b-numberinput>
          </div>
        </div>
        <!-- saturday -->
        <div class="txtbold mb7">Saturday</div>
        <div class="isFlex inputbr isFlexAlign tsk mb5">
          <div><b-checkbox v-model="Goal.saturday"></b-checkbox></div>
          <div class="p5">H</div>
          <div style="width: 100px;">
            <b-numberinput
              v-model="Goal.saturdaysettings.hours"
              size="is-small"
              type="is-light"
            ></b-numberinput>
          </div>
          <div class="p5">M</div>
          <div style="width: 100px;">
            <b-numberinput
              v-model="Goal.saturdaysettings.minutes"
              size="is-small"
              type="is-light"
              step="15"
            ></b-numberinput>
          </div>
        </div>
        <!-- sunday -->
        <div class="txtbold mb7">Sunday</div>
        <div class="isFlex inputbr isFlexAlign tsk mb5">
          <div><b-checkbox v-model="Goal.sunday"></b-checkbox></div>
          <div class="p5">H</div>
          <div style="width: 100px;">
            <b-numberinput
              v-model="Goal.sundaysettings.hours"
              size="is-small"
              type="is-light"
            ></b-numberinput>
          </div>
          <div class="p5">M</div>
          <div style="width: 100px;">
            <b-numberinput
              v-model="Goal.sundaysettings.minutes"
              size="is-small"
              type="is-light"
              step="15"
            ></b-numberinput>
          </div>
        </div>
      </div>
      <!-- when: end -->

      <!-- workflow: Start -->
      <div class="tab-item" v-if="tabs.workflow">
        <div class="txtbold mb7">Goal workflow</div>
        <div class="isFlex pb11 mb11 blueborder1bottom">
          <div class="isFlexGrow pr5">
            <b-input v-model="wfNewLabel" placeholder="New workflow"></b-input>
          </div>
          <div><b-button @click="wfAdd">Add New</b-button></div>
        </div>

        <draggable tag="div" :list="Goal.workflow" handle=".handle">
          <div v-for="(gw, index) in Goal.workflow" :key="gw._id">
            <div class="isFlex mb5" v-if="gw.value === 'New' || gw.value === 'Active' || gw.value === 'Completed'">
              <div class="isFlexGrow pr5">
                <b-input v-model="gw.value" placeholder="New workflow" disabled></b-input>
              </div>
              <div class="iconbutton2x tsk vlgreyborder vlgrey mr7">
                <font-awesome-icon :icon="['fal', 'trash']" size="lg"></font-awesome-icon>
              </div>
              <div class="iconbutton2x tsk midgreybgborder midgrey handle">
                <font-awesome-icon :icon="['fal', 'sort']" size="lg" ></font-awesome-icon>
              </div>
            </div>
            <div class="isFlex mb5" v-else>
              <div class="isFlexGrow pr5">
                <b-input v-model="gw.value" placeholder="New workflow"></b-input>
              </div>
              <div class="iconbutton2x tsk redborder red mr7" @click="wfDelete(index)">
                <font-awesome-icon :icon="['fal', 'trash']" size="lg"></font-awesome-icon>
              </div>
              <div class="iconbutton2x tsk midgreybgborder midgrey handle">
                <font-awesome-icon :icon="['fal', 'sort']" size="lg" ></font-awesome-icon>
              </div>
            </div>
          </div>
        </draggable>
      </div>
      <!-- workflow: End -->

      <!-- resources: Start -->
      <div class="tab-item" v-if="tabs.resources">
        <!-- links heading -->
        <div class="isFlex blueborder2bottom">
          <div class="isFlexGrow txth3">Links</div>
          <div v-if="editMode"><b-button @click="linksAddToggle()"><span v-if="showLinksAdd">Close</span><span v-else>Add link</span></b-button></div>
        </div>

        <!-- links add: start -->
        <div class="tsk vlgreybg" v-if="showLinksAdd">
          <div class="txth3">Add link</div>
          <b-field label="Type of link">
            <b-select placeholder="Select..." v-model="linkNewType">
              <option value="Web page">Website or web page</option>
              <option value="Youtube video">Youtube video</option>
              <option value="Google doc">Google doc or sheet</option>
              <option value="Other">Other</option>
            </b-select>
          </b-field>
          <b-field label="Title of link">
            <b-input v-model="linkNewUrl" placeholder="https://addressoflink.com/page/etc" type="url"></b-input>
          </b-field>
          <b-field label="Title of link">
            <b-input v-model="linkNewTitle"></b-input>
          </b-field>
          <b-field label="Description of link">
            <b-input maxlength="500" type="textarea" v-model="linkNewDesc"></b-input>
          </b-field>
          <div>
            <button class="button" @click="linkAdd()">Add link</button>
          </div>
        </div>
        <!-- links add: end -->
        <div class="p11" v-if="Goal.links.length === 0">You don't have any links defined.</div>

        <!-- links list: start -->
        <draggable tag="div" :list="Goal.links" handle=".handle"  @end="submitForm(false)" >
          <section v-for="(gl, index) in Goal.links" :index="index" :key="index">
            <div class="isFlex pt5 pb5 mb3 mt3 blueborder1bottom">
              <div class="iconbutton handle" v-if="editMode">
                <font-awesome-icon :icon="['fal', 'sort']" size="sm" ></font-awesome-icon>
              </div>
              <div class="isFlexGrow pl3 pr5" v-if="gl.edit">
                <div>
                  <b-select placeholder="Select..." v-model="gl.type">
                    <option value="Web page">Website or web page</option>
                    <option value="Youtube video">Youtube video</option>
                    <option value="Google doc">Google doc or sheet</option>
                    <option value="Other">Other</option>
                  </b-select>
                </div>
                <div><b-input v-model="gl.url" @blur="submitForm(false)"></b-input></div>
                <div><b-input v-model="gl.title" @blur="submitForm(false)"></b-input></div>
                <div><b-input v-model="gl.description" type="textarea" rows="3" @blur="submitForm(false)"></b-input></div>
              </div>
              <div class="isFlexGrow pl3 pr5" v-else>
                <div class="txtbold">{{ gl.title }}</div>
                <div>{{ gl.description }}</div>
                <div style="width: 60px;" v-if="gl.type === 'Youtube video'">
                  <b-button icon-right="play" @click="playVideo(gl, 'video/youtube')">Play Video</b-button>
                </div>
              </div>
              <div class="isFlex">
                <div class="iconbutton tsk redborder red mr5" @click="linkDelete(gl, index)" v-if="editMode">
                  <font-awesome-icon :icon="['fal', 'trash']" size="sm"></font-awesome-icon>
                </div>
                <div class="iconbutton tsk blueborder10 bluetxt10 mr5" @click="linkEdit(gl)" v-if="editMode">
                  <font-awesome-icon :icon="['fal', 'edit']" size="sm"></font-awesome-icon>
                </div>
                <a class="iconbutton tsk blueborder10 bluetxt10" :href="gl.url" target="_blank">
                  <font-awesome-icon :icon="['fal', 'external-link']" size="sm"></font-awesome-icon>
                </a>
              </div>
            </div>
          </section>
        </draggable>
        <!-- links list: end -->

        <!-- upload heading -->
        <div style="height: 20px;"></div>
        <div class="isFlex blueborder2bottom">
          <div class="isFlexGrow txth3">Files</div>
          <div v-if="editMode"><b-button @click="uploadToggle()"><span v-if="uploadShow">Close</span><span v-else>Upload</span></b-button></div>
        </div>
        <!-- upload window: start -->
        <div class="tsk vlgreybg" v-if="uploadShow">
          <div class="txth3">Upload file</div>
          <div class="mb11 red">Please note: you can only upload images (.jpg, .png), video (mp4) and documents (.pdf) files.</div>
          <form @submit.prevent="onSubmit" enctype="multipart/form-data">
            <b-field class="file">
              <b-upload v-model="file" ref="file" @input="onSelect">
                <a class="button is-primary"><b-icon icon="upload"></b-icon><span>Select file</span></a>
              </b-upload>
              <span class="file-name" v-if="file">{{ file.name }}</span>
            </b-field>
            <b-field label="Title" message="Enter an easy readable file title">
              <b-input v-model="fileTitle"></b-input>
            </b-field>
            <b-field label="Description" message="Describe what the file is about">
              <b-input v-model="fileDesc" type="textarea" rows="3"></b-input>
            </b-field>
            <div>
              <button class="button">Upload</button>
            </div>
          </form>
        </div>
        <!-- upload window: end -->
        <!-- file list: start -->
        <div class="p11" v-if="Goal.files.length === 0">You don't have any files uploaded.</div>
        <draggable tag="div" :list="Goal.files" handle=".handle" @end="submitForm(false)">
          <section v-for="(gf, index) in Goal.files" :index="index" :key="index">
            <div class="isFlex pt5 pb5 mb3 mt3 blueborder1bottom">
              <div class="iconbutton handle" v-if="editMode">
                <font-awesome-icon :icon="['fal', 'sort']" size="sm" ></font-awesome-icon>
              </div>

              <div class="isFlexGrow pl3 pr5" v-if="gf.edit">
                <div><b-input v-model="gf.title" @blur="submitForm(false)"></b-input></div>
                <div><b-input v-model="gf.description" type="textarea" rows="3" @blur="submitForm(false)"></b-input></div>
              </div>
              <div class="isFlexGrow pl3 pr5" v-else>
                <div class="txtbold">{{ gf.title }} </div>
                <div v-if="gf.mimetype === 'video/mp4'" class="mt11 mb11">
                  <b-button icon-right="play" @click="playVideo(gf, 'video/mp4')">Play Video</b-button>
                </div>
                <div>{{ gf.description }}</div>
                <div style="width: 60px;" v-if="gf.mimetype === 'image/png' || gf.mimetype === 'image/jpg' || gf.mimetype === 'image/jpeg'"><img :src="gf.url" /></div>
              </div>
              <div class="isFlex">
                <div class="iconbutton tsk redborder red mr5" @click="fileDelete(gf, index)" v-if="editMode">
                  <font-awesome-icon :icon="['fal', 'trash']" size="sm"></font-awesome-icon>
                </div>
                <div class="iconbutton tsk blueborder10 bluetxt10 mr5" @click="fileEdit(gf)" v-if="editMode">
                  <font-awesome-icon :icon="['fal', 'edit']" size="sm"></font-awesome-icon>
                </div>
                <a class="iconbutton tsk blueborder10 bluetxt10" :href="gf.url" target="_blank">
                  <font-awesome-icon :icon="['fal', 'external-link']" size="sm"></font-awesome-icon>
                </a>
              </div>
              
            </div>
          </section>
        </draggable>
        <!-- file list: end -->

        <!-- people heading -->
        <div class="isFlex blueborder2bottom" v-if="editMode">
          <div class="isFlexGrow txth3">Work roles needed</div>
          <div v-if="editMode"><b-button @click="peopleAddToggle()"><span v-if="showPeopleAdd">Close</span><span v-else>Add Role</span></b-button></div>
        </div>

        <!-- people add: start -->
        <div class="tsk vlgreybg" v-if="showPeopleAdd">
          <div class="txth3">Add Person</div>
          <b-field label="Period resource needed">
            <b-select placeholder="Select..." v-model="peopleNewType">
              <option value="Temp">Temp</option>
              <option value="Freelancer">Freelancer</option>
              <option value="Contractor">Contractor</option>
              <option value="Permanent">Permanent</option>
            </b-select>
          </b-field>
          <b-field label="Work role." message="Ex. Designer, Gardner, Business Coach">
            <b-input v-model="peopleNewRole"></b-input>
          </b-field>
          <b-field label="Hour rate" message="How much does this resource cost per day?">
            <b-input v-model="peopleNewRateDay" type="number"></b-input>
          </b-field>
          <b-field label="Day rate" message="Or how much does this resource cost per hour">
            <b-input v-model="peopleNewRateHour" type="number"></b-input>
          </b-field>
          <div>
            <button class="button" @click="peopleAdd()">Add Role</button>
          </div>
        </div>
        <!-- people add: end -->
        <div class="p11" v-if="Goal.people.length === 0 && editMode">You don't have any roles defined.</div>

        <!-- people list: start -->
        <div v-if="editMode">
          <section v-for="(gl, index) in Goal.people" :index="index" :key="index">
            <div class="isFlex pt5 pb5 mb3 mt3 blueborder1bottom">
              <div class="isFlexGrow pl3 pr5" v-if="gl.edit">
                <b-field label="Period resource needed">
                  <b-select placeholder="Select..." v-model="gl.type">
                    <option value="Temp">Temp</option>
                    <option value="Freelancer">Freelancer</option>
                    <option value="Contractor">Contractor</option>
                    <option value="Permanent">Permanent</option>
                  </b-select>
                </b-field>
                <div><b-input v-model="gl.role" @blur="submitForm(false)"></b-input></div>
                <div>
                  <b-field label="Day rate" message="How much does this resource cost per day?">
                    <b-input v-model="gl.day" @blur="submitForm(false)" type="number"></b-input>
                  </b-field>
                </div>
                <div>
                  <b-field label="Hour rate" message="Or how much does this resource cost per hour">
                    <b-input v-model="gl.hour" @blur="submitForm(false)" type="number"></b-input>
                  </b-field>
                </div>
              </div>
              <div class="isFlexGrow pl3 pr5" v-else>
                <div class="txtbold">{{ gl.role }}</div>
                <div>{{ gl.type }} <span v-if="gl.day != 0">{{ gl.day }}/day</span> <span v-if="gl.hour != 0">{{ gl.hour }}/hour</span></div>
              </div>
              <div class="isFlex">
                <div class="iconbutton tsk redborder red mr5" @click="peopleDelete(gl, index)" v-if="editMode">
                  <font-awesome-icon :icon="['fal', 'trash']" size="sm"></font-awesome-icon>
                </div>
                <div class="iconbutton tsk blueborder10 bluetxt10 mr5" @click="peopleEdit(gl)" v-if="editMode">
                  <font-awesome-icon :icon="['fal', 'edit']" size="sm"></font-awesome-icon>
                </div>
              </div>
            </div>
          </section>
        </div>
        <!-- people list: end -->
        
      </div>
      <!-- resources: End -->
      <!-- more: Start -->
      <div class="tab-item" v-if="tabs.more">
        <div v-if="Goal.blueprintparent !== ''">This Goal is part of a blueprint.</div>
        <div v-else>
          <div class="tskbig vlgreybg">
            <div class="field">
              <b-switch v-model="Goal.blueprint">This is a blueprint</b-switch>
            </div>
            <div v-if="Goal.blueprint">
              <div>
                <b-field
                  label="Available to..."
                  message="Specify who can use this blueprint">
                  <b-select placeholder="Select..." v-model="Goal.blueprintshare">
                    <option value="Private">Me only</option>
                    <option value="Public">Available to others</option>
                  </b-select>
                </b-field>
              </div>
              <div v-if="Goal.blueprintshare !== 'Private'">
                <div class="mt11">
                  <b-field label="Kind of blueprint" >
                    <b-select placeholder="Select..." v-model="Goal.blueprinttype">
                      <option value="Self">Self: A user buys and installs the Goal for themselves</option>
                      <option value="Review">Review: you will review the users responses and leave notes</option>
                      <option value="Lead">Lead: You lead the goal and users join the goal</option>
                    </b-select>
                  </b-field>
                  <div v-if="Goal.blueprinttype === 'Lead'" class="txtsm red">If this is a lead Goal it has to be scheduled on the timeline. The first date on the timeline when you activate the blueprint will be the start date.</div>
                </div>

                <div class="mt11" v-if="Goal.blueprinttype !== 'Self'">
                  <div>
                    <b-field label="How many people are allowed to join the goal?">
                      <b-numberinput v-model="Goal.blueprintmembers" min="1"></b-numberinput>
                    </b-field>
                  </div>
                </div>

                <div class="mt11" v-if="Goal.blueprinttype === 'Lead'">
                  <b-field label="How will you lead this?" >
                    <b-select placeholder="Select..." v-model="Goal.blueprinthandholdtype">
                      <option value="Group">Group: Facebook of LinkedIn group</option>
                      <option value="Whatsapp">Whatsapp</option>
                      <option value="Telegram">Telegram</option>
                      <option value="Twitter">Twitter</option>
                      <option value="Conference">Conference call via a sytem like Zoom or Bluejeans</option>
                    </b-select>
                  </b-field>
                </div>

                <div class="mt11" v-if="Goal.blueprinttype === 'Review'">
                  <b-field label="How will you offer support?" >
                    <b-select placeholder="Select..." v-model="Goal.blueprinthandholdtype">
                      <option value="Group">Group: Facebook of LinkedIn group</option>
                      <option value="Whatsapp">Whatsapp</option>
                      <option value="Telegram">Telegram</option>
                      <option value="Twitter">Twitter</option>
                    </b-select>
                  </b-field>
                </div>

                <div class="mt11" v-if="Goal.blueprinttype !== 'Self'">
                  <b-field v-if="Goal.blueprinthandholdtype === 'Group'" label="Facebook or LinkedIn group url">
                    <b-input v-model="Goal.blueprinthandholdsource" type="url"></b-input>
                  </b-field>
                  <b-field v-if="Goal.blueprinthandholdtype === 'Whatsapp' || Goal.blueprinthandholdtype === 'Telegram'" label="Enter the phone number the user needs to add. Including the country code">
                    <b-input v-model="Goal.blueprinthandholdsource" placeholder="0044074XXXXXXX"></b-input>
                  </b-field>
                  <b-field v-if="Goal.blueprinthandholdtype === 'Twitter'" label="Please enter the twitter handle or hashtag the users have to follow">
                    <b-input v-model="Goal.blueprinthandholdsource" placeholder="@yourhandle"></b-input>
                  </b-field>
                  <b-field v-if="Goal.blueprinthandholdtype === 'Conference'" label="Please specify the url to your conference">
                    <b-input v-model="Goal.blueprinthandholdsource" placeholder=""></b-input>
                  </b-field>
                  <div v-if="Goal.blueprinthandholdtype === 'Conference'" class="txtsm red">Please ensure that you update this url a few hours before the scheduled time.</div>
                </div>

                <div class="mt11">
                  <b-field
                    label="Cost type"
                    message="Please Note: Untill the end of the Corona Lockdown we will only be accepting FREE blueprints">
                    <b-select placeholder="Select..." v-model="Goal.blueprintcosttype">
                      <option value="Free">Free</option>
                      <option value="Paid">One of charge</option>
                      <option value="Monthly">Paid monthly</option>
                    </b-select>
                  </b-field>
                  <b-field v-if="Goal.blueprintcosttype === 'Monthly'" label="What do your users get for the monthly fee" message="Please tell a subscriber what they will get on a monthly basis if they sign up.">
                    <b-input maxlength="200" type="textarea" v-model="Goal.blueprintmonthlytext" rows="3"></b-input>
                  </b-field>
                  <b-field v-if="Goal.blueprintcosttype === 'Monthly'" label="Duration in months">
                    <b-input v-model="Goal.blueprintduration" type="number"></b-input>
                  </b-field>
                  <b-field label="Cost" v-if="Goal.blueprintcosttype !== 'Free'">
                    <b-input v-model="Goal.blueprintcost" type="number" step="any"></b-input>
                  </b-field>
                </div>
                
                
                <div class="mt5 mb5">Status: {{ Goal.blueprintstatus }}</div>
                <div>
                <div class="txtbold" v-if="Goal.blueprintstatus === 'Rejected'">Message from admin</div>
                  <div class="red" v-if="Goal.blueprintstatus === 'Rejected'">{{ Goal.blueprintmessage }}</div>
                </div>
                <div class="buttons mt11">
                  <b-button v-if="Goal.blueprintstatus === 'Draft'" @click="blueprintSubmit('Submitted')">Submit for approval</b-button>
                  <b-button v-if="Goal.blueprintstatus === 'Rejected'" @click="blueprintSubmit('Submitted')">Submit Changes</b-button>
                  <b-button v-if="Goal.blueprintstatus === 'Rejected'" @click="blueprintSubmit('Draft')">Cancel Submission</b-button>
                  <b-button v-if="Goal.blueprintstatus === 'Submitted'" @click="blueprintSubmit('Draft')">Cancel Submission</b-button>
                  <b-button v-if="Goal.blueprintstatus === 'Approved'" @click="blueprintSubmit('Draft')">Remove from Network</b-button>
                </div>
                <b-field v-if="iseditor" label="Message to publisher" message="Only needed if rejected">
                  <b-input maxlength="200" type="textarea" v-model="Goal.blueprintmessage" rows="3"></b-input>
                </b-field>
                <div class="buttons mt11" v-if="iseditor">
                  <b-button @click="blueprintSubmit('Rejected')">Reject</b-button>
                  <b-button @click="blueprintSubmit('Approved')">Approve</b-button>
                </div>
              </div>
            </div>
            <!-- thumb: start -->
            <div>
              <div class="txth5 mt11 mb5">Preview image</div>
              <div>
                <img :src="thumb" alt="" style="width: 300px; height: 158px;" v-if="Goal.blueprintthumb === ''">
                <img :src="Goal.blueprintthumb" alt="" style="width: 300px; height: 158px;" v-else>
              </div>
              <div class="buttons">
                <b-button @click="thumbUpload()">Upload</b-button>
                <b-button @click="thumbRemove()">Remove</b-button>
              </div>
            </div>
            <!-- thumb: end -->
            <!-- image: start -->
            <div>
              <div class="txth5 mt11 mb5">Cover image</div>
              <div>
                <img :src="image" alt=""  v-if="Goal.blueprintimage === ''">
                <img :src="Goal.blueprintimage" alt="" v-else>
              </div>
              <div class="buttons">
                <b-button @click="imageUpload()">Upload</b-button>
                <b-button @click="imageRemove()">Remove</b-button>
              </div>
            </div>
            <!-- image: end -->
            <!-- video: start -->
            <div>
              <b-field label="Use a video as cover image">
                <b-select placeholder="Select a video" v-model="Goal.blueprintvideo">
                  <option value="">Don't use video</option>
                    <optgroup label="From uploads" >
                      <template v-for="(gf, index) in Goal.files" >
                        <option :value="gf.url" v-if="gf.mimetype === 'video/mp4'" :index="index" :key="index">{{ gf.title }}</option>
                      </template>
                    </optgroup>
                </b-select>
              </b-field>

        
            </div>
            <!-- video: end -->
          </div>
        </div>
      </div>
      <!-- more: End -->
      <!-- worklog: start -->
      <div class="tab-item" v-if="tabs.worklog">
        <!-- worklog: start  -->
          <div>
            <div class="tsk bluebg8 white mb9" v-if="wlHasOpen">
              <div>You currently have an active session: {{ wlActive.task }} <span v-if="wlActive.goal !== ''"> in {{wlActive.goal}}</span></div>
            </div>
            <div class="mb11 p9 tsk lgreybg isFlex">
              <div class="isFlexGrow mr5">
                <b-datepicker type="month" placeholder="Click to select..." icon="calendar" v-model="wlmonth" ></b-datepicker>
              </div>
              <div>
                <b-select placeholder="Select person" v-model="wlSperson">
                  <option value="">All people</option>
                  <option value="new" v-for="(gp, index) in Goal.availableto" :key="index">{{gp.username}}</option>
                </b-select>
              </div>
              <div>
                <b-select placeholder="Select a status" v-model="wlSstatus">
                  <option value="">All entries</option>
                  <option value="new">new</option>
                  <option value="rejected">rejected</option>
                  <option value="approved">approved</option>
                  <option value="exported">exported</option>
                  <option value="processed">processed</option>
                  <option value="paid">paid</option>
                </b-select>
              </div>
              <!-- <div>
                <b-select placeholder="Team" v-model="wlTeam" @input="wlChangeTeam">
                  <option
                      v-for="option in wlTeamList"
                      :value="option"
                      :key="option._id">
                      {{ option.firstname }}
                  </option>
                </b-select> 
              </div> -->
              <!-- <div v-if="editAllowTeam">
                <b-select placeholder="Members" v-model="wlTeamMember">
                  <option v-for="option in wlTeamMembers" :value="option" :key="option._id" >{{ option.firstname }}</option>
                </b-select>
              </div> -->
              <div>
                <b-button @click="wlShowMonth">Apply</b-button>
              </div>
            </div>

            <div v-for="(ss, index) in wlRows" :key="ss._id">
              <div class="tsk p9 mb11 vlgreybg isFlex isFlexAlign" v-if="wlSstatus === ss.islatestatus || wlSstatus === ''"  v-bind:class="{ completed: ss.deleted }">
                <div class="mr5">
                  <div class="red mb3" v-if="ss.islate && ss.islatestatus !== 'approved'"><font-awesome-icon :icon="['fal', 'alarm-exclamation']" size="lg"></font-awesome-icon></div>
                  <div v-else>
                    <div v-if="ss.islatestatus === 'approved'"><b-checkbox v-model="ss.selected"></b-checkbox></div>
                    <div v-else><b-checkbox v-model="ss.selected" disabled></b-checkbox></div>
                    
                    
                  </div>
                </div>
                <div class="isFlexGrow">
                  <div>{{ ss.description }}</div>
                  <div class="txtsm">
                    Who: <strong>{{ ss.subname }}</strong> | Duration:
                    <strong>{{ ss.totalhours }} </strong> Hours
                    <strong>{{ ss.totalminutes }} </strong> Minutes <br />From
                    <strong >{{ ss.startdt.dayst }} {{ ss.startdt.monthtxt }} {{ ss.startdt.year }} {{ ss.startdt.hour | numberStr("0", "pre") }}:{{ss.startdt.minute | numberStr("0", "post")}}</strong>
                    to
                    <strong >{{ ss.enddt.dayst }} {{ ss.enddt.monthtxt }} {{ ss.enddt.year }} {{ ss.enddt.hour | numberStr("0", "pre") }}:{{ ss.enddt.minute | numberStr("0", "post") }}</strong>
                    <span v-if="ss.billed"> (Billed)</span>
                  </div>
                  <div class="txtsm" v-if="ss.deleted">
                    <strong>Delete Reason: </strong>{{ ss.deletedreason }}
                  </div>
                  <div class="txtsm" v-if="ss.islate && !ss.deleted">
                    <strong>Late Reason: </strong>{{ ss.islatereason }}
                    <span> ({{ ss.islatestatus }})</span>
                  </div>
                  <div class="txtsm red" v-if="ss.islatestatus === 'rejected'">
                    <strong>Message: </strong>{{ ss.islatestatusmessage }}
                  </div>
                  <div v-if="!ss.deleted" class="isFlex mt3">
                    <div>
                      <b-input placeholder="Message" v-model="ss.islatestatusmessage" size="is-small"></b-input>
                    </div>
                    <div>
                      <b-select placeholder="Select a status" v-model="ss.islatestatus" size="is-small" @input="wlUpdate(ss)">
                        <option value="new">new</option>
                        <option value="rejected">rejected</option>
                        <option value="approved">approved</option>
                        <option value="exported">exported</option>
                        <option value="processed">processed</option>
                        <option value="paid">paid</option>
                      </b-select>
                    </div>
                    <!-- <div><b-button size="is-small" @click="wlUpdate(ss)">Update</b-button></div> -->
                  </div>
                </div>
              </div>
              <div class="tsk p7 mb11 vlgreybg" v-else>
                Tap apply to apply your search...
              </div>
            </div>

            <div class="tsk p7 mb11 vlgreybg" v-if="wlRows.length === 0">
              No sessions logged against the <span class="txtbold bluetxt8">{{wlSstatus}}</span> status.
            </div>

            <div class="mb11 p9 tsk lgreybg isFlex">
              <!-- <div class="mr5">
                <b-button @click="wlEditLog('new', {}, -1)">
                  <font-awesome-icon :icon="['fal', 'plus']" size="lg" ></font-awesome-icon>
                </b-button>
              </div> -->
              <div class="isFlexGrow mr5">
                <b-input v-model="wlFileName"></b-input>
              </div>
              <div class="mr5">
                <b-button @click="wlcsv">CSV</b-button>
              </div>
              <!-- <div>
                <b-button @click="wlbilled">Billed</b-button>
              </div> -->
            </div>
          </div>
          <!-- worklog: end  -->
      </div>
      <!-- worklog: end -->
    </section>

    <footer class="modal-card-foot isFlex" style="padding: 10px;" v-if="!tabs.loading && !editMode">
      <div class="isFlexGrow">
        <b-button @click="$parent.close()">Close</b-button>
        <b-button @click="editMode=true" v-if="!editMode && canEdit">Edit</b-button>
        <b-button @click="installBlue()" v-if="Goal.blueprint">
          Use Blueprint
        </b-button>
      </div>
    </footer>

    <footer class="modal-card-foot isFlex" style="padding: 10px;" v-if="!tabs.loading && editMode"> 
      <div class="isFlexGrow" v-if="Goal._id !== 'new'">
        <b-button @click="goalDelete()" v-if="Goal.deleted && editMode">
          Delete
        </b-button>
        <b-button @click="goalRestore()" v-if="Goal.deleted && editMode">
          Restore
        </b-button>
        <b-button @click="goalArchive()" v-if="!Goal.deleted && editMode">
          Archive
        </b-button>
        <b-button @click="tabsChange('desc');editMode=false" v-if="editMode && canEdit">View</b-button>
      </div>
      <div class="isFlexGrow" v-if="Goal._id === 'new'">
        <b-button @click="$parent.close()">
          Cancel
        </b-button>
      </div>
      <div v-if="Goal._id !== 'new'">
        <b-button @click="$parent.close()">
          Close
        </b-button>
        <b-button @click="submitForm(false)" v-if="editMode">
          Save
        </b-button>
        <b-button @click="submitForm(true)" v-if="editMode">
          Save &amp; Close
        </b-button>
      </div>
      <div v-if="Goal._id === 'new'">
        <b-button @click="submitForm(false)">
          Create Goal
        </b-button>
      </div>
    </footer>
  </div>
</template>

<script>
import Vue from "vue";
import { Compact } from "vue-color";
import axios from "axios";
import array from "lodash/array";
import ObjectID from "bson-objectid";
import store from "../store";
import ModalImageSquare from "@/components/ModalImageSquare.vue";
import ModalVideo from "@/modals/ModalVideo.vue";
import ModalTask from "@/modals/ModalTask.vue";
import { Utils } from "@/mixins/utils.js";
import { dbFunctions } from "@/mixins/dbMixins.js";
import { Schemas } from "@/schemas/all.js";
import draggable from "vuedraggable";
import taskitem from "@/components/task-item.vue";

// Vue.component("task-item", {
//   template: "task-template",
//   props: {
//     item: Object,
//     Active: { type: String, default: '' }
//   },
//   data: function () {
//     return {
//       task: this.item
//     }
//   },
//   methods: {
//     async taskOpenList(task) {
//       let useFull = true;
//       let useCanCancel = false;
//       const inw = document.body.clientWidth;
//       if (inw > 750) { useFull = false; useCanCancel = true; }
//       this.modal = this.$buefy.modal.open({
//         parent: this,
//         component: ModalTask,
//         hasModalCard: true,
//         fullScreen: useFull,
//         canCancel: useCanCancel,
//         width: 700,
//         props: {
//           data: task, // this is the row data that gets sent to the modal
//           callBus: "Modal_Goals_Task" // name of the listner for the data coming in from the modal
//         },
//         events: {
//           taskUpdated: value => {
//             task.completed = value.data.completed;
//           }
//         }
//       });
//     },
//   },
//   template: `<li><div class="txtbold isPointer" :class="{ 'red': Active === task._id, 'tcompleted': task.completed }" @click="taskOpenList(task)">{{task.tasktext}}</div><div class="txtsm">{{task.taskdescription}}</div><ul class="ul" v-if="task.steps.length !== 0"><task-item v-for="(task, index) in task.steps" :key="index" :item="task" :Active="Active"></task-item></ul></li>`
// });

export default {
  name: "ModalGoals",
  mixins: [Utils, dbFunctions, Schemas],
  components: {
    draggable,
    taskitem,
    "compact-picker": Compact
  },
  props: {
    data: Object,
    callBus: String
    // busData: Object
  },
  data() {
    return {
      GoalIn: this.data,
      Goal: {},
      ActiveTask: '',
      Invites: [],
      tabs: {
        loading: true,
        desc: false,
        who: false,
        when: false,
        workflow: false,
        resources: false,
        worklog: false,
        more: false
      },
      wlNewText: "",
      whyNewText: "",
      gbEmail: "",
      gbRole: "Can contribute",
      gbPrefs: [
        { label: "Can do tasks", value: "Can contribute" },
        { label: "Can comment", value: "Can comment" },
        { label: "Can read only", value: "Can read only" },
        { label: "Admin: Can edit goal and make changes to team members", value: "Admin" }
      ],
      DueDate: null,
      wfNewLabel: "",
      ModalWidth: `width: auto`,
      ImageStyle: `background-color: white; margin-left: -20px; margin-top: -20px; width: auto`,
      colors: {
        hex: "#CCCCCC"
      },
      // file uploads
      uploadShow: false,
      file: null,
      message: "",
      fileTitle: "",
      fileDesc: "",
      iseditor: false,
      // links
      showLinksAdd: false,
      linkNewType: "Web page",
      linkNewUrl: "",
      linkNewTitle: "",
      linkNewDesc: "",
      // links
      showPeopleAdd: false,
      peopleNewType: "Temp",
      peopleNewRole: "",
      peopleNewRateDay: 0,
      peopleNewRateHour: 0,
      peopleNewDesc: "",

      canEdit: false,
      editMode: false,

      TreeData: [],
      installData: [],

      videouploads: [],
      videolists: [],

      isDemo: false,

      image: 'https://via.placeholder.com/1280x720',
      thumb: 'https://via.placeholder.com/300x169',

      covervideo: '',
      showVideo: false,

      sub: localStorage.getItem("sub"),

      playerOptions: {
        // videojs options
        width: '300',
        autoplay: true,
        muted: false,
        language: 'en',
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [{
          type: 'video/mp4',
          src: ''
        }],
        poster: this.poster,
      },

      // worklog: start
      wlHasOpen: false,
      wlIsOpen: false,
      wlActive: null,
      wlRows: [],
      wlmonth: new Date(),
      wlFileName: "timelog.csv",
      wlTeamList: [],
      wlTeam: { _id: "all", firstname: "All Teams" },
      wlTeamMembers: [],
      wlTeamMember: {
        _id: '',
        firstname: ''
      },
      wlSession: {},
      wlOpenSession: {},

      wlMLshow: false,
      wlStartDate: null,
      wlStartTime: null,
      wlEndDate: null,
      wlEndTime: null,
      wlMLwhat: '',
      wlMLwhy: '',

      wlSstatus: 'new',
      wlSperson: '',
      // worklog: end

      clients: []
      
    };
  },
  created() {
    this.iseditor = this.editorCheck();
    this.GoalLoad();
    this.loadClients();
  },
  computed: {
    
  },
  methods: {
    async GoalLoad() {
      if (this.GoalIn._id === "new") {
        this.Goal = this.SchemaNew(this.goalSchema);
        this.canEdit = true;
        this.editMode = true;

      } else {
        const ingoal = await this.axiosget(`/goal/${this.GoalIn._id}`);
        this.Goal = ingoal.rows;
        if( this.Goal.blueprintvideo !== '') {
          this.playerOptions.sources[0].src = this.Goal.blueprintvideo;
          this.showVideo = true;
        }
        if (this.Goal.blueprintstatus !== 'Bought') {
          const sub = localStorage.getItem('sub');
          for (const u of this.Goal.availableto) {
            if (u._id === sub) {
              if(u.permission === 'Owner' || u.permission === 'Admin') {
                this.canEdit = true;
              }
            }
          }
          this.isDemo = this.Goal.demo;
          if (this.isDemo) {
            this.canEdit = true;
          }
          if (this.Goal.sub === sub) {
            this.canEdit = true;
            this.isDemo = false;
          }
        }
        for (const av of this.Goal.availableto) {
          if (!av.perhour) {
            av.perhour = 0;
          }
        }
        const data = {
          parentid: this.Goal._id,
          parenttype: "goal",
          deleted: false,
          workflowtype: "ne",
          workflow: "Completed",
          sortby: "childorder",
        };
        const tasks = await this.DataSend("post", "/taskstree", data, "", false);
        this.TreeData = tasks.rows;
      }
      this.setWidth();
      if (this.Goal.goalcolor === "") {
        this.Goal.goalcolor = "#CCCCCC";
      }
      this.colors.hex = this.Goal.goalcolor;
      if (!this.Goal.workflow) {
        this.Goal.workflow = this.workflow;
      }
      if (this.Goal.workflow.length === 0) {
        this.Goal.workflow = this.workflow;
      }
      this.wlLoad();
      this.tabsChange("desc");
      this.gbInvitesGet();
    },

    async loadClients() {
      this.clients = await this.DataGet(`/people/clients`);
    },

    setWidth() {
      const inw = document.body.clientWidth;
      const inh = document.body.clientHeight;
      let newsize = `width: auto`;
      if (inw > 750) {
        const usize = inh - 100;
        newsize = `width: 700px; margin-left: 25px; margin-right: 25px; height: ${usize}px;`;
        this.ImageStyle = `background-color: white; margin-left: -20px; margin-top: -20px; width: 700px;`;
        // newsize = `width: ${usize}px; height: 80%;`;
        this.playerOptions.width = 698;
      } else {
        this.playerOptions.width = inw;
        this.ImageStyle = `background-color: white; margin-left: -20px; margin-top: -20px; width: ${inw}px;`;
      }
      this.ModalWidth = newsize;
    },

    changeColor(value) {
      this.Goal.goalcolor = value.hex;
    },

    // whatlist: start
    wlAdd() {
      if (this.wlNewText !== "") {
        this.Goal.whatlist.push({ text: this.wlNewText });
        this.wlNewText = "";
      }
    },
    wlDelete(index) {
      this.$buefy.dialog.confirm({
        title: "",
        message: "Are you sure you want to <b>delete</b> this bullet point?",
        confirmText: "Delete",
        type: "is-danger",
        hasIcon: true,
        icon: 'exclamation',
        onConfirm: () => {
          this.Goal.whatlist.splice(index, 1);
        }
      });
    },
    // whatlist: end

    // whylist: start
    whyAdd() {
      if (this.whyNewText !== "") {
        this.Goal.whylist.push({ text: this.whyNewText });
        this.whyNewText = "";
      }
    },
    whyDelete(index) {
      this.$buefy.dialog.confirm({
        title: "",
        message: "Are you sure you want to <b>delete</b> this bullet point?",
        confirmText: "Delete",
        type: "is-danger",
        hasIcon: true,
        icon: 'exclamation',
        onConfirm: () => {
          this.Goal.whylist.splice(index, 1);
        }
      });
    },
    // whatlist: end

    // invites: start
    async gbInvitesGet() {
      this.Invites = await this.DataGet(`/invites/${this.Goal._id}`);
    },

    async gbSendInvite() {
      if (this.gbEmail === "") {
        this.$buefy.toast.open("Please enter a valid email address");
        return;
      }
      if(this.isDemo) {
        this.DataDemo();
        return;
      }
      const data = {
        goalid: this.Goal._id,
        permission: this.gbRole,
        email: this.gbEmail,
        goalname: this.Goal.goaltitle
      };
      const sendinv = await this.DataSend("post", "/goal/invite", data);
      this.gbInvitesGet();
    },
    gbUpdate() {},
    gbDelete() {},

    async gbDeleteInvite(row, index) {
      this.$buefy.dialog.confirm({
        title: "",
        message: "Are you sure you want to <b>delete</b> this invite?",
        confirmText: "Delete Invite",
        type: "is-danger",
        hasIcon: true,
        icon: 'exclamation',
        onConfirm: () => {
          this.DataDelete(`/invite/${row._id}`);
          this.Invites.splice(index, 1);
        }
      });
    },

    async gbDeleteBuddy(index) {
      this.$buefy.dialog.confirm({
        title: "",
        message:
          "Are you sure you want to <b>delete</b> this goal buddy / team member?",
        confirmText: "Delete",
        type: "is-danger",
        hasIcon: true,
        icon: 'exclamation',
        onConfirm: () => {
          this.Goal.availableto.splice(index, 1);
        }
      });
    },
    // invites: end

    // workflow: start
    wfAdd() {
      const newrow = {
        value: this.wfNewLabel,
        label: this.wfNewLabel,
        icon: "check"
      };
      this.Goal.workflow.push(newrow);
      this.wfNewLabel = "";
    },
    wfDelete(index) {
      this.$buefy.dialog.confirm({
        title: "",
        message: "Are you sure you want to <b>delete</b> this item?",
        confirmText: "Delete",
        type: "is-danger",
        hasIcon: true,
        icon: 'exclamation',
        onConfirm: () => {
          this.Goal.workflow.splice(index, 1);
        }
      });
    },
    // workflow: end

    tabsChange(tab) {
      this.tabs.loading = false;
      this.tabs.desc = false;
      this.tabs.who = false;
      this.tabs.when = false;
      this.tabs.more = false;
      this.tabs.workflow = false;
      this.tabs.worklog = false;
      this.tabs.resources = false;
      this.tabs[tab] = true;
    },

    async submitForm(close) {
      if (this.DueDate !== null) {
        const inst = this.getDateFull(this.DueDate, "none", "none", 0);
        this.Goal.duedate = inst.db;
        this.Goal.duedatets = inst.tsm;
      } else {
        this.Goal.duedate = "";
        this.Goal.duedatets = 0;
      }
      if (this.Goal.blueprintvideo === '') {
        this.showVideo = false;
      }
      if(this.isDemo) {
        this.DataDemo();
        return;
      }
      if (this.Goal.clientid !== '') {
        const hasrecord = array.findLastIndex(this.clients, { '_id': this.Goal.clientid });
        this.Goal.client = `${this.clients[hasrecord].firstname} ${this.clients[hasrecord].lastname}`;
      } else {
        this.Goal.client = '';
      }
      const upgoal = await this.DataSend("put", "/goal", this.Goal);
      let type = "update";
      if (this.Goal._id === "new") {
        this.Goal._id = upgoal.rows._id;
        this.Goal.assignedto = upgoal.rows.assignedto;
        this.Goal.sub = upgoal.rows.sub;
        this.Goal.subname = upgoal.rows.subname;
        type = "new";
      }
      this.$emit("goalUpdated", { type, data: upgoal.rows });
      if (close) {
        this.$parent.close();
      }
     },

    async goalArchive() {
      if(this.isDemo) {
        this.DataDemo();
        return;
      } 
      this.$buefy.dialog.confirm({
        title: "",
        message:
        "Are you sure you want to <b>Archive</b> this goal and all it's tasks?",
        confirmText: "Archive",
        type: "is-danger",
        hasIcon: true,
        icon: 'exclamation',
        onConfirm: async () => {
          this.Goal.deleted = true;
          const arcgoal = await this.DataDelete(`/goal/archive/${this.Goal._id}`, "row");
          this.$emit("goalUpdated", { type: "update", data: this.Goal });
        }
      });
    },

    async goalRestore() {
      this.$buefy.dialog.confirm({
        title: "",
        message:
        "Are you sure you want to <b>Restore</b> this goal and all it's tasks?",
        confirmText: "Restore",
        type: "is-danger",
        hasIcon: true,
        icon: 'exclamation',
        onConfirm: async () => {
          this.Goal.deleted = false;
          const resgoal = await this.DataDelete(`/goal/restore/${this.Goal._id}`, "row");
          this.$emit("goalUpdated", { type: "update", data: this.Goal });
        }
      });
    },

    async goalDelete() {
      this.$buefy.dialog.confirm({
        title: "",
        message:
        "Are you sure you want to <b>Delete</b> this goal and all it's tasks?",
        confirmText: "Delete",
        type: "is-danger",
        icon: 'exclamation',
        hasIcon: true,
        onConfirm: async () => {
          const delgoal = await this.DataDelete(`/goal/${this.Goal._id}`, "row");
          this.$emit("goalUpdated", { type: "delete", data: delgoal });
          this.$parent.close();
        }
      });
    },

    blueprintSubmit(status) {
      this.Goal.blueprintstatus = status;
      this.submitForm(false);
    },

    // files: start
    uploadToggle() {
      if (this.uploadShow) {
        this.uploadShow = false;
      } else {
        this.uploadShow = true;
      }
    },
    onSelect() {},

    async onSubmit() {
      if (this.file === null) {
        this.$buefy.toast.open({
          message: "Please select a file to upload",
          position: "is-bottom",
          type: "is-danger"
        });
        return;
      }
      if (this.fileTitle === "") {
        this.$buefy.toast.open({
          message: "Please enter a file title",
          position: "is-bottom",
          type: "is-danger"
        });
        return;
      }
      if(this.isDemo) {
        this.DataDemo();
        return;
      }
      const formData = new FormData();
      formData.append("doc", this.file);
      formData.append("goalid", this.Goal._id);
      formData.append("filetitle", this.fileTitle);
      formData.append("filedesc", this.fileDesc);
       try {
        const sendfile = await this.DataSend("post", "/upload/goal", formData, '', true);
        this.Goal.files = [];
        for (const f of sendfile.rows.files) {
          this.Goal.files.push(f);
          this.file = null;
          this.fileDesc = "";
          this.fileDesc = "";
          this.uploadShow = false;
        }
      } catch (error) {
        this.message = "Something went wrong!";
      }
    },

    fileEdit(row) {
      if (row.edit) {
        row.edit = false;
      } else {
        row.edit = true;
      }
    },

    fileDelete(row, index) {
      if(this.isDemo) {
        this.DataDemo();
        return;
      }
      this.$buefy.dialog.confirm({
        title: "",
        message:
        "Are you sure you want to <b>delete</b> this?",
        confirmText: "Delete",
        type: "is-danger",
        hasIcon: true,
        icon: 'exclamation',
        onConfirm: async () => {
          const delfile = await this.DataSend("post", "/filedelete/goal", row);
          this.Goal.files.splice(index, 1);
          this.submitForm(false);
        }
      });
    },

    playVideo(gf, type) {
      let useFull = true;
      let useCanCancel = false;
        const inw = document.body.clientWidth;
        if (inw > 750) { useFull = false; useCanCancel = true; }
      	this.modal = this.$buefy.modal.open({
          parent: this,
          component: ModalVideo,
          hasModalCard: true,
          fullScreen: useFull,
          canCancel: useCanCancel,
          width: 700,
          props: {
            title: gf.title,
            video: gf.url,
            type
          },
          events: {
            dataFromModal: value => {}
          }
        });
    },
    // files: end

    // links: start
    linksAddToggle() {
      if (this.showLinksAdd) {
        this.showLinksAdd = false;
      } else {
        this.showLinksAdd = true;
      }
    },

    linkAdd() {
      const newlink = {
        type: this.linkNewType,
        url: this.linkNewUrl,
        title: this.linkNewTitle,
        description: this.linkNewDesc,
        edit: false
      }
      this.Goal.links.push(newlink);
      this.linkNewType = "Web page";
      this.linkNewUrl = "";
      this.linkNewTitle = "";
      this.linkNewDesc = "";
      this.submitForm(false);
    },

    linkEdit(row) {
      if (row.edit) {
        row.edit = false;
      } else {
        row.edit = true;
      }
    },

    linkDelete(index) {
      this.$buefy.dialog.confirm({
        title: "",
        message: "Are you sure you want to <b>delete</b> this link? This action cannot be undone.",
        confirmText: "Delete",
        type: "is-danger",
        icon: 'exclamation',
        hasIcon: true,
        onConfirm: () => {
          this.Goal.links.splice(index, 1);
          this.submitForm(false);
        }
      });
    },
    // links: end

    // people: start
    peopleAddToggle() {
      if (this.showPeopleAdd) {
        this.showPeopleAdd = false;
      } else {
        this.showPeopleAdd = true;
      }
    },

    peopleAdd() {
      const newperson = {
        type: this.peopleNewType,
        role: this.peopleNewRole,
        day: this.peopleNewRateDay,
        hour: this.peopleNewRateHour,
        edit: false
      }
      this.Goal.people.push(newperson);
      this.peopleNewType = "Temp";
      this.peopleNewRole = "";
      this.peopleNewRateDay = 0;
      this.peopleNewRateHour = 0;
      this.submitForm(false);
    },

    peopleEdit(row) {
      if (row.edit) {
        row.edit = false;
      } else {
        row.edit = true;
      }
    },

    peopleDelete(index) {
      this.$buefy.dialog.confirm({
        title: "",
        message: "Are you sure you want to <b>delete</b> this role? This action cannot be undone.",
        confirmText: "Delete",
        type: "is-danger",
        hasIcon: true,
        icon: 'exclamation',
        onConfirm: () => {
          this.Goal.people.splice(index, 1);
          this.submitForm(false);
        }
      });
    },
    // people: end

    // image start
    thumbUpload() {
      if(this.isDemo) {
        this.DataDemo();
        return;
      }
      this.modalI = this.$buefy.modal.open({
        parent: this,
        component: ModalImageSquare,
        hasModalCard: true,
        props: {
          area: 'goal',
          inGoalId: this.Goal._id,
          inWidth: 300,
          inHeight: 164
        },
        events: {
          imageChanged: value => {
            this.Goal.blueprintthumb = value;
          }
        }
      });
    },
    thumbRemove() {
      if(this.isDemo) {
        this.DataDemo();
        return;
      }
      	this.$buefy.dialog.confirm({
          title: "",
          message:
          "Are you sure you want to <b>remove</b> this? This cannot be undone.",
          confirmText: "Delete",
          type: "is-danger",
          hasIcon: true,
          icon: 'exclamation',
          onConfirm: () => {
            this.Goal.blueprintthumb = '';
          }
        });
    },
    // image image end
    // image start
    imageUpload() {
      if(this.isDemo) {
        this.DataDemo();
        return;
      }
      this.modalI = this.$buefy.modal.open({
        parent: this,
        component: ModalImageSquare,
        hasModalCard: true,
        props: {
          area: 'goal',
          inGoalId: this.Goal._id,
          inWidth: 1280,
          inHeight: 720
        },
        events: {
          imageChanged: value => {
            this.Goal.blueprintimage = value;
          }
        }
      });
    },
    imageRemove() {
      if(this.isDemo) {
        this.DataDemo();
        return;
      }
      	this.$buefy.dialog.confirm({
          title: "",
          message:
          "Are you sure you want to <b>remove</b> this? This cannot be undone.",
          confirmText: "Delete",
          type: "is-danger",
          hasIcon: true,
          icon: 'exclamation',
          onConfirm: () => {
            this.Goal.blueprintimage = '';
          }
        });
    },
    // image image end

    // use blueprint: start
    processChildren(rows, p, newgoalid) {
      for (const t of rows) {
        const nid = ObjectID().str;
        const nr = {
          oldid: t._id,
          newid: nid,
          parentid: p,
          goalid: newgoalid,
          text: t.tasktext
        }
        this.installData.push(nr);
        if (t.steps.length !== 0) {
          this.processChildren(t.steps, nid, newgoalid);
        }
      }
    },

    async installBlue() {
      this.$buefy.dialog.confirm({
        title: "",
        message:
        "Are you sure you want to <b>use</b> this blueprint? An editable copy will be available in your Projects list once installed.",
        confirmText: "Use Blueprint",
        type: "is-danger",
        hasIcon: true,
        icon: 'exclamation',
        onConfirm: async () => {
          const newgoalid = new ObjectID().str;
          this.installData = [];
          const data = {
            oldgoalid: this.Goal._id,
            newgoalid: newgoalid,
            tasks: []
          }
          for (const t of this.TreeData) {
            const id = new ObjectID().str;
            const nr = {
              oldid: t._id,
              newid: id,
              parentid: '',
              goalid: newgoalid,
              text: t.tasktext
            }
            this.installData.push(nr);
            if (t.steps.length !== 0) {
              this.processChildren(t.steps, id, newgoalid);
            }
          }
          data.tasks = this.installData;
          if(this.isDemo) {
            this.DataDemo();
            return;
          }
          const installed = await this.DataSend('post', '/blueprint/use', data);
          this.$buefy.snackbar.open({
              message: 'Blueprint installed and available in your Projects list',
              type: 'is-warning',
              position: 'is-top',
              actionText: 'OK',
              indefinite: true,
              onAction: () => {}
          })
        }
      });
    },
    // use blueprint: end

    // worklog: start
    wlShowMonth() {
      this.wlRows = [];
      this.wlLoad();
    },
    async wlLoad() {
      this.wlRows = [];
      const indate = this.getDateFull(this.wlmonth, "none", "none", 0);

      const data = {
        goalid: this.Goal._id,
        endmonth: indate.monthsingle,
        endyear: indate.year,
        isLeader: this.isLeader,
        person: this.wlSperson,
        status: this.wlSstatus,
      }
      // if (this.wlTeamMember._id !== "all") {
      //   query.$and.push({ sub: this.wlTeamMember._id });
      // }
      const options = {};
      const getses = await this.DataSend('post', '/worklog/goal', data, 'rows', false);
      for (const ss of getses) {
        if (ss.sub === this.sub) {
          if (ss.open) {
            this.wlHasOpen = true;
          }
        }
        ss.selected = false;
        // check if can edit
        ss.canedit = false;
        if (ss.sub === this.sub) {
          ss.canedit = true;
        }
        this.wlRows.push(ss);
      }
    },

    async wlUpdate(row) {
      const updl = await this.DataSend('put', '/worklog/update', row);
    },
    
    wlcsv() {
      const csvarr = [
        [
          "Goal",
          "Task",
          "Person",
          "Hours",
          "Minutes",
          "Rate",
          "Total",
          "From",
          "To",
          "Description",
          "Late",
          "Late reason",
          "Billed",
          "GoalId",
          "TaskId",
          "PersonId"
        ]
      ];
      let hasSelected = false;
      for (const r of this.wlRows) {
        if (r.selected) {
          hasSelected = true;
          const stdt = this.getDateFull(new Date( r.startdt.year, r.startdt.month - 1, r.startdt.day, r.startdt.hour, r.startdt.minute, 0, 0 ), "none", "none", 0);
          const endt = this.getDateFull( new Date( r.enddt.year, r.enddt.month - 1, r.enddt.day, r.enddt.hour, r.enddt.minute, 0, 0 ), "none", "none", 0);
          
          let hrate = 0;
          let mrate = 0;
          const getp = array.findLastIndex(this.Goal.availableto, { '_id': r.sub });

          
          if (getp !== -1) {
            hrate = this.Goal.availableto[getp].perhour;
            mrate = (hrate / 60);
          }

          const htotal = hrate * r.totalhours;
          const mtotal = r.totalminutes * mrate;
          const total = (htotal + mtotal).toFixed(2);
          const nwr = [
            r.goal,
            r.task,
            r.subname,
            r.totalhours,
            r.totalminutes,
            hrate,
            total,
            stdt.dbtm,
            endt.dbtm,
            r.description,
            r.islate,
            r.islatereason,
            r.billed,
            r.goalid,
            r.taskid,
            r.sub
          ];
          csvarr.push(nwr);
        }
      }
      if (!hasSelected) {
        this.$buefy.snackbar.open({
          message: "Select some entries to export",
          position: "is-top"
        });
        return;
      }
      this.exportToCsv(this.wlFileName, csvarr);
    },

    wlbilled() {},

    // worklog: end

  }
};
</script>

<style>
.modallarge {
  max-width: 700px;
  min-width: 700px;
}
.ul {
  list-style-type: circle;
  padding-left: 20px;
}
.tcompleted {
  text-decoration: line-through;
}
</style>
