<template>
  <div class="modal-card" :style="ModalWidth">
    <header class="modal-card-head isFlexSpace" style="padding: 10px;">
      <p class="modal-card-title">{{DataIn.type}}</p>
      <b-button icon-left="times" @click="$parent.close()">Cancel</b-button>
    </header>
    <section class="modal-card-body">
      <div class="tab-item" v-if="loading">
        <b-progress type="is-info"></b-progress>
      </div>
      <div v-if="DataIn.type === 'Subscribe'">
        You are subscribing to the <strong>{{ DataIn.title }}</strong>. 
        <br/>You will be charged to the value of <strong>{{DataIn.currency}} {{DataIn.price}}</strong>* in British pounds (GBP {{DataIn.original}}) untill you cancel.
      </div>
      <div class="mt11 red txtsm">You will be transfered to our billing partner <a href="https://stripe.com/" target="_blank">Stripe</a>. Who will securely take your payment.</div>
      <div style="height: 30px;"></div>
      <stripe-checkout
        ref="checkoutRef"
        :pk="publishableKey"
        :items="items"
        :customerEmail="Me.email"
        :clientReferenceId="Me._id"
        :billingPostalCode="Me.postcode"
        :successUrl="successUrl"
        :cancelUrl="cancelUrl"
      >
        <template slot="checkout-button">
          <b-button @click="checkout" type="is-info">Subscribe and be awesome!</b-button>
        </template>
      </stripe-checkout>
      <div style="height: 30px;"></div>
      <div class="txtsm">* Price might fluxuate based on the currency exchange rate at time of billing, your shoe size and the weather in London.</div>
    </section>
    <!-- <footer class="modal-card-foot isFlex" style="padding: 10px;" v-if="!loading"> 
      <div class="isFlexGrow">
        <b-button @click="$parent.close()">
          Cancel
        </b-button>
      </div>
      <div>
        <b-button @click="checkout()">
          Pay Now
        </b-button>
      </div>
    </footer> -->
  </div>
</template>

<script>
// import { Utils } from "@/mixins/utils.js";
import { Schemas } from "@/schemas/all.js";
import { StripeCheckout } from 'vue-stripe-checkout';
export default {
  name: "ModalPay",
  mixins: [Schemas],
  components: {
    StripeCheckout
  },
  props: {
    data: Object,
    inme: Object,
  },
  data() {
    return {
      ModalWidth: `width: auto`,
      DataIn: this.data,
      Me: this.inme,
      Data: {},
      loading: true,
      publishableKey: 'pk_live_PFIdzrIwZxo3pmFts5tanvm600kjQuVYgC', // pk_test_Ghc9xFyIUpnv5unwpc7ufcYj008hQFnmkr
      items: [],
      successUrl: `${window.location.origin}/${this.$route.name}/success`,
      cancelUrl: `${window.location.origin}/${this.$route.name}/cancel`,
    };
  },
  created() {
    // this.setWidth();
    this.loading = false;
    this.items.push(this.DataIn.item);
  },
  methods: {
   
    checkout () {
      this.$refs.checkoutRef.redirectToCheckout();
    },
    setWidth() {
      const inw = document.body.clientWidth;
      const inh = document.body.clientHeight;
      let newsize = `width: auto`;
      if (inw > 750) {
       const usize = inh - 100;
        newsize = `width: 700px; margin-left: 25px; margin-right: 25px; height: ${usize}px;`;
        // newsize = `width: ${usize}px; height: 80%;`;
      }
      this.ModalWidth = newsize;
    },
    async submitForm(close) {
      const updata = await this.DataSend("put", "/url", this.Data);
      let type = "update";
      if (this.Data._id === "new") {
        this.Data._id = updata.rows._id;
        this.Data.assignedto = updata.rows.assignedto;
        type = "new";
      }
      this.$emit("dataUpdated", { type, data: updata.rows });
      if (close) {
        this.$parent.close();
      }
    },
  }
};
</script>
