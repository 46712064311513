<template>
  <div class="modal-card">
    <header class="modal-card-head" style="padding: 5px 5px;">
      <editor-menu-bar :editor="editor" v-slot="{ commands, isActive }">
        <div class="menubar">
          <div class="iconbutton m3 edbut" @click="commands.bold" :class="{ 'is-active': isActive.bold() }" ><font-awesome-icon :icon="['fal', 'bold']" size="sm" ></font-awesome-icon></div>
          <div class="iconbutton m3 edbut" @click="commands.italic" :class="{ 'is-active': isActive.italic() }"><font-awesome-icon :icon="['fal', 'italic']" size="sm" ></font-awesome-icon></div>
          <div class="iconbutton m3 edbut" @click="commands.strike" :class="{ 'is-active': isActive.strike() }" ><font-awesome-icon :icon="['fal', 'strikethrough']" size="sm" ></font-awesome-icon></div>
          <div class="iconbutton m3 edbut" @click="commands.underline" :class="{ 'is-active': isActive.underline() }" ><font-awesome-icon :icon="['fal', 'underline']" size="sm" ></font-awesome-icon></div>
          <!-- <div class="iconbutton m3 edbut" @click="commands.code" :class="{ 'is-active': isActive.code() }"><font-awesome-icon :icon="['fal', 'code']" size="sm"></font-awesome-icon></div> -->
          <div class="iconbutton m3 edbut" @click="commands.heading({ level: 1 })" :class="{ 'is-active': isActive.heading({ level: 1 }) }" ><font-awesome-icon :icon="['fal', 'h1']" size="sm" ></font-awesome-icon></div>
          <div class="iconbutton m3 edbut" @click="commands.heading({ level: 2 })" :class="{ 'is-active': isActive.heading({ level: 2 }) }" ><font-awesome-icon :icon="['fal', 'h2']" size="sm" ></font-awesome-icon></div>
          <div class="iconbutton m3 edbut" @click="commands.heading({ level: 3 })" :class="{ 'is-active': isActive.heading({ level: 3 }) }" ><font-awesome-icon :icon="['fal', 'h3']" size="sm" ></font-awesome-icon></div>
          <div class="iconbutton m3 edbut" @click="commands.bullet_list" :class="{ 'is-active': isActive.bullet_list() }" ><font-awesome-icon :icon="['fal', 'list-ul']" size="sm" ></font-awesome-icon></div>
          <div class="iconbutton m3 edbut" @click="commands.ordered_list" :class="{ 'is-active': isActive.ordered_list() }" ><font-awesome-icon :icon="['fal', 'list-ol']" size="sm" ></font-awesome-icon></div>
          <div class="iconbutton m3 edbut" @click="commands.todo_list" :class="{ 'is-active': isActive.todo_list() }" ><font-awesome-icon :icon="['fal', 'tasks']" size="sm" ></font-awesome-icon></div>
          <div class="iconbutton m3 edbut" @click="commands.code_block" :class="{ 'is-active': isActive.code_block() }" ><font-awesome-icon :icon="['fal', 'code']" size="sm" ></font-awesome-icon></div>
          <div class="iconbutton m3 edbut" @click="commands.horizontal_rule"><font-awesome-icon :icon="['fal', 'horizontal-rule']" size="sm" ></font-awesome-icon></div>
          <div class="iconbutton m3 edbut" @click="commands.undo"><font-awesome-icon :icon="['fal', 'undo']" size="sm" ></font-awesome-icon></div>
          <div class="iconbutton m3 edbut" @click="commands.redo"><font-awesome-icon :icon="['fal', 'redo']" size="sm" ></font-awesome-icon></div>
        </div>
      </editor-menu-bar>
    </header>
    <section class="modal-card-body">
      <editor-content :editor="editor" />
    </section>
    <footer class="modal-card-foot" style="padding: 10px;">
      <button class="button" type="button" @click="$parent.close()">
        Close
      </button>
      <button class="button" type="button" @click="submitForm()">Save</button>
    </footer>
  </div>
</template>

<script>
// Import the editor
import { EventBus } from "../event-bus.js";
import { Editor, EditorContent, EditorMenuBar } from "tiptap";
import {
  Blockquote, // quote-right
  CodeBlock, // code
  Heading, // h1, h2, h3, h4
  HorizontalRule, // horizontal-rule
  OrderedList, // list-ol
  BulletList, // list-ul
  ListItem, //
  TodoItem,
  TodoList, // tasks
  Bold, // bold
  Code, // code ?
  Italic, // italic
  Link, // link
  Strike, // strikethrough
  Underline, //underline
  History // undo, redo
} from "tiptap-extensions";

export default {
  components: {
    EditorContent,
    EditorMenuBar
  },
  props: {
    usetext: String,
    area: String,
  },
  data() {
    return {
      intext: this.usetext,
      inarea: this.area,
      editor: null
    };
  },
  methods: {
    submitForm() {
      const retdata = {};
      retdata["html"] = this.html;
      retdata["json"] = this.json;
      if( this.inarea === 'task' ) {
        this.$emit('textChanged', retdata);
      }
      if( this.inarea === 'peoplenotes' ) {
        this.$emit('peopleNoteChanged', retdata);
      }
      // this.$parent.close();
    }
  },
  mounted() {
    if (this.intext === "") {
      this.intext = "";
    }
    (this.editor = new Editor({
      extensions: [
        new Blockquote(),
        new BulletList(),
        new CodeBlock(),
        new Heading({ levels: [1, 2, 3] }),
        new HorizontalRule(),
        new ListItem(),
        new OrderedList(),
        new TodoItem({ nested: true }),
        new TodoList(),
        new Link(),
        new Bold(),
        new Code(),
        new Italic(),
        new Strike(),
        new Underline(),
        new History()
      ],
      content: this.intext,
      onUpdate: ({ getJSON, getHTML }) => {
        this.json = getJSON();
        this.html = getHTML();
      }
    })),
      this.editor.focus();
  },
  beforeDestroy() {
    this.editor.destroy();
  }
};
</script>

<style>
.iconbutton.is-active {
  color: red;
}
.menubar {
  display: flex;
  flex-wrap: wrap;
}
.edbut {
  background: white;
  border-radius: 5px;
  margin: 2px;
}

.modal-card-body h1 {
  font-size: 2em;
}
.modal-card-body h2 {
  font-size: 1.7em;
}
.modal-card-body h3 {
  font-size: 1.3em;
}
</style>
