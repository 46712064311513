<template>
  <div class="modal-card" :style="ModalWidth">
    <header class="modal-card-head" style="padding: 10px;">
      <p class="modal-card-title">{{Title}}</p>
    </header>
    <section class="modal-card-body p0">
      <!-- <div class="tab-item" v-if="loading">
        <b-progress type="is-info"></b-progress>
      </div> -->
      <video-player  class="video-player-box"
        ref="videoPlayer"
        :options="playerOptions"
        :playsinline="true"
        customEventName="customstatechangedeventname">
      </video-player>
    </section>
    <footer class="modal-card-foot isFlex" style="padding: 10px;"> 
      <div class="isFlexGrow">
        <b-button @click="$parent.close()">
          Close
        </b-button>
      </div>
      <!-- <div>
        <b-button @click="submitForm(true)">
          Allocate
        </b-button>
      </div> -->
    </footer>
  </div>
</template>

<script>
import { Utils } from "@/mixins/utils.js";
import { Schemas } from "@/schemas/all.js";
export default {
  name: "ModalVideo",
  mixins: [Utils, Schemas],
  props: {
    title: String,
    video: String,
    poster: { type: String, default: 'imgs/videocover.png' },
    type: { type: String, default: 'video/mp4' },
  },
  data() {
    return {
      ModalWidth: `width: auto`,
      Title: this.title,
      Type: this.type,
      loading: true,


      playerOptions: {
        // videojs options
        width: '300',
        autoplay: true,
        muted: false,
        language: 'en',
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [{
          type: this.type,
          src: this.video
        }],
        poster: this.poster,
      }
      
    };
  },
  created() {
    this.setWidth();
  },
  computed: {
    player() {
      return this.$refs.videoPlayer.player
    }
  },
  methods: {
    // listen event
    onPlayerPlay(player) {
      // console.log('player play!', player)
    },
    onPlayerPause(player) {
      // console.log('player pause!', player)
    },
    // ...player event

    // or listen state event
    playerStateChanged(playerCurrentState) {
      // console.log('player current update state', playerCurrentState)
    },

    // player is ready
    playerReadied(player) {
      // you can use it to do something...
      // player.[methods]
    },
    
    setWidth() {
      if(this.Type === 'video/youtube') {
        this.playerOptions.techOrder = [ 'youtube'];
      }
      const inw = document.body.clientWidth;
      const inh = document.body.clientHeight;
      let newsize = `width: auto`;
      if (inw > 750) {
       const usize = inh - 100;
        // newsize = `width: 700px; margin-left: 25px; margin-right: 25px; height: ${usize}px;`;
        newsize = `width: 700px; margin-left: 25px; margin-right: 25px;`;
        // newsize = `width: ${usize}px; height: 80%;`;
        this.playerOptions.width = 700;
      } else {
        this.playerOptions.width = inw;
      }
      this.ModalWidth = newsize;
    },
    async submitForm(close) {
      const updata = await this.DataSend("put", "/url", this.Data);
      let type = "update";
      if (this.Data._id === "new") {
        this.Data._id = updata.rows._id;
        this.Data.assignedto = updata.rows.assignedto;
        type = "new";
      }
      this.$emit("dataUpdated", { type, data: updata.rows });
      if (close) {
        this.$parent.close();
      }
    },
  }
};
</script>
