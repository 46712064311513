export const windowSplit2 = {
  data() {
    return {
      w: {
        width: 0,
        height: 0,
        smallscreen: false,
        mainHead: true,
        mainFoot: false,
        left: true,
        leftHead: true,
        leftHeadSearch: true,
        leftHeadAdd: false,
        leftFoot: true,
        right: false,
        rightView: true,
        rightEdit: false,
        rightNavi: true,
        rightHead: true,
        rightFoot: true,
      }
    }
  },
  methods: {
    handleResize() {
      this.w.width = window.innerWidth;
      this.w.height = window.innerHeight;
      if (this.w.width < 950) {
        this.w.smallscreen = true;
        if (this.w.right) {
          this.w.left = false;
        }
      } else {
        this.w.smallscreen = false;
        if (this.w.right) {
          this.w.left = true;
        }
      }
    },
    openRight() {
      if (this.w.smallscreen) {
        this.w.left = false;
        this.w.right = true;
      } else {
        this.w.right = true;
      }
    },
    openRightAll() {
      this.w.left = false;
        this.w.right = true;
    },
    closeRight() {
      this.w.left = true;
      this.w.right = false;
      this.switchView('rightView');
    },
    switchView(view) {
      this.w.rightView = false;
      this.w.rightEdit = false;
      this.w[view] = true;
    },
  }
}