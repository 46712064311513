// import { EventBus } from "../event-bus.js";
import * as moment from "moment";
import axios from "axios";
import ObjectID from "bson-objectid";
import { ModalProgrammatic as Modal } from 'buefy'
import ModalPay from "@/modals/ModalPay.vue";
// import ModalTask2 from "@/modals/ModalTask.vue";
import { EventBus } from "../event-bus.js";

export const Utils = {
  // components: {
  //   ModalPay
  // },
  data() {
    return {
      // api: 'https://dne.limitless8.com',
      api: 'https://api.dne.life',
      // api: "http://192.168.0.8:3008",
      // api: "http://localhost:3008",
      Me: {},
      ActiveGoalId: '',
      countries: [
        { countryCode: 'GB', value: '44', label: 'UK (+44)' },
        { countryCode: 'US', value: '1', label: 'USA (+1)' },
        { countryCode: 'IN', value: '91', label: 'India (+91)' },
        { countryCode: 'ZA', value: '27', label: 'South Africa (+27)' },
        { countryCode: 'AU', value: '61', label: 'Australia (+61)' },
        { countryCode: 'CA', value: '1', label: 'Canada (+1)' },
        { countryCode: '', value: '', label: '---' },
        { countryCode: 'DZ', value: '213', label: 'Algeria (+213)' },
        { countryCode: 'AD', value: '376', label: 'Andorra (+376)' },
        { countryCode: 'AO', value: '244', label: 'Angola (+244)' },
        { countryCode: 'AI', value: '1264', label: 'Anguilla (+1264)' },
        { countryCode: 'AG', value: '1268', label: 'Antigua & Barbuda (+1268)' },
        { countryCode: 'AR', value: '54', label: 'Argentina (+54)' },
        { countryCode: 'AM', value: '374', label: 'Armenia (+374)' },
        { countryCode: 'AW', value: '297', label: 'Aruba (+297)' },
        { countryCode: 'AU', value: '61', label: 'Australia (+61)' },
        { countryCode: 'AT', value: '43', label: 'Austria (+43)' },
        { countryCode: 'AZ', value: '994', label: 'Azerbaijan (+994)' },
        { countryCode: 'BS', value: '1242', label: 'Bahamas (+1242)' },
        { countryCode: 'BH', value: '973', label: 'Bahrain (+973)' },
        { countryCode: 'BD', value: '880', label: 'Bangladesh (+880)' },
        { countryCode: 'BB', value: '1246', label: 'Barbados (+1246)' },
        { countryCode: 'BY', value: '375', label: 'Belarus (+375)' },
        { countryCode: 'BE', value: '32', label: 'Belgium (+32)' },
        { countryCode: 'BZ', value: '501', label: 'Belize (+501)' },
        { countryCode: 'BJ', value: '229', label: 'Benin (+229)' },
        { countryCode: 'BM', value: '1441', label: 'Bermuda (+1441)' },
        { countryCode: 'BT', value: '975', label: 'Bhutan (+975)' },
        { countryCode: 'BO', value: '591', label: 'Bolivia (+591)' },
        { countryCode: 'BA', value: '387', label: 'Bosnia Herzegovina (+387)' },
        { countryCode: 'BW', value: '267', label: 'Botswana (+267)' },
        { countryCode: 'BR', value: '55', label: 'Brazil (+55)' },
        { countryCode: 'BN', value: '673', label: 'Brunei (+673)' },
        { countryCode: 'BG', value: '359', label: 'Bulgaria (+359)' },
        { countryCode: 'BF', value: '226', label: 'Burkina Faso (+226)' },
        { countryCode: 'BI', value: '257', label: 'Burundi (+257)' },
        { countryCode: 'KH', value: '855', label: 'Cambodia (+855)' },
        { countryCode: 'CM', value: '237', label: 'Cameroon (+237)' },
        { countryCode: 'CA', value: '1', label: 'Canada (+1)' },
        { countryCode: 'CV', value: '238', label: 'Cape Verde Islands (+238)' },
        { countryCode: 'KY', value: '1345', label: 'Cayman Islands (+1345)' },
        { countryCode: 'CF', value: '236', label: 'Central African Republic (+236)' },
        { countryCode: 'CL', value: '56', label: 'Chile (+56)' },
        { countryCode: 'CN', value: '86', label: 'China (+86)' },
        { countryCode: 'CO', value: '57', label: 'Colombia (+57)' },
        { countryCode: 'KM', value: '269', label: 'Comoros (+269)' },
        { countryCode: 'CG', value: '242', label: 'Congo (+242)' },
        { countryCode: 'CK', value: '682', label: 'Cook Islands (+682)' },
        { countryCode: 'CR', value: '506', label: 'Costa Rica (+506)' },
        { countryCode: 'HR', value: '385', label: 'Croatia (+385)' },
        { countryCode: 'CU', value: '53', label: 'Cuba (+53)' },
        { countryCode: 'CW', value: '599', label: 'Curacao (+599)' },
        { countryCode: 'CY', value: '90392', label: 'Cyprus North (+90392)' },
        { countryCode: 'CY', value: '357', label: 'Cyprus South (+357)' },
        { countryCode: 'CZ', value: '42', label: 'Czech Republic (+42)' },
        { countryCode: 'DK', value: '45', label: 'Denmark (+45)' },
        { countryCode: 'DJ', value: '253', label: 'Djibouti (+253)' },
        { countryCode: 'DM', value: '1809', label: 'Dominica (+1809)' },
        { countryCode: 'DO', value: '1809', label: 'Dominican Republic (+1809)' },
        { countryCode: 'EC', value: '593', label: 'Ecuador (+593)' },
        { countryCode: 'EG', value: '20', label: 'Egypt (+20)' },
        { countryCode: 'SV', value: '503', label: 'El Salvador (+503)' },
        { countryCode: 'GQ', value: '240', label: 'Equatorial Guinea (+240)' },
        { countryCode: 'ER', value: '291', label: 'Eritrea (+291)' },
        { countryCode: 'EE', value: '372', label: 'Estonia (+372)' },
        { countryCode: 'ET', value: '251', label: 'Ethiopia (+251)' },
        { countryCode: 'FK', value: '500', label: 'Falkland Islands (+500)' },
        { countryCode: 'FO', value: '298', label: 'Faroe Islands (+298)' },
        { countryCode: 'FJ', value: '679', label: 'Fiji (+679)' },
        { countryCode: 'FI', value: '358', label: 'Finland (+358)' },
        { countryCode: 'FR', value: '33', label: 'France (+33)' },
        { countryCode: 'GF', value: '594', label: 'French Guiana (+594)' },
        { countryCode: 'PF', value: '689', label: 'French Polynesia (+689)' },
        { countryCode: 'GA', value: '241', label: 'Gabon (+241)' },
        { countryCode: 'GM', value: '220', label: 'Gambia (+220)' },
        { countryCode: 'GE', value: '7880', label: 'Georgia (+7880)' },
        { countryCode: 'DE', value: '49', label: 'Germany (+49)' },
        { countryCode: 'GH', value: '233', label: 'Ghana (+233)' },
        { countryCode: 'GI', value: '350', label: 'Gibraltar (+350)' },
        { countryCode: 'GR', value: '30', label: 'Greece (+30)' },
        { countryCode: 'GL', value: '299', label: 'Greenland (+299)' },
        { countryCode: 'GD', value: '1473', label: 'Grenada (+1473)' },
        { countryCode: 'GP', value: '590', label: 'Guadeloupe (+590)' },
        { countryCode: 'GU', value: '671', label: 'Guam (+671)' },
        { countryCode: 'GT', value: '502', label: 'Guatemala (+502)' },
        { countryCode: 'GN', value: '224', label: 'Guinea (+224)' },
        { countryCode: 'GW', value: '245', label: 'Guinea - Bissau (+245)' },
        { countryCode: 'GY', value: '592', label: 'Guyana (+592)' },
        { countryCode: 'HT', value: '509', label: 'Haiti (+509)' },
        { countryCode: 'HN', value: '504', label: 'Honduras (+504)' },
        { countryCode: 'HK', value: '852', label: 'Hong Kong (+852)' },
        { countryCode: 'HU', value: '36', label: 'Hungary (+36)' },
        { countryCode: 'IS', value: '354', label: 'Iceland (+354)' },
        { countryCode: 'IN', value: '91', label: 'India (+91)' },
        { countryCode: 'ID', value: '62', label: 'Indonesia (+62)' },
        { countryCode: 'IR', value: '98', label: 'Iran (+98)' },
        { countryCode: 'IQ', value: '964', label: 'Iraq (+964)' },
        { countryCode: 'IE', value: '353', label: 'Ireland (+353)' },
        { countryCode: 'IL', value: '972', label: 'Israel (+972)' },
        { countryCode: 'IT', value: '39', label: 'Italy (+39)' },
        { countryCode: 'JM', value: '1876', label: 'Jamaica (+1876)' },
        { countryCode: 'JP', value: '81', label: 'Japan (+81)' },
        { countryCode: 'JO', value: '962', label: 'Jordan (+962)' },
        { countryCode: 'KZ', value: '7', label: 'Kazakhstan (+7)' },
        { countryCode: 'KE', value: '254', label: 'Kenya (+254)' },
        { countryCode: 'KI', value: '686', label: 'Kiribati (+686)' },
        { countryCode: 'KP', value: '850', label: 'Korea North (+850)' },
        { countryCode: 'KR', value: '82', label: 'Korea South (+82)' },
        { countryCode: 'KW', value: '965', label: 'Kuwait (+965)' },
        { countryCode: 'KG', value: '996', label: 'Kyrgyzstan (+996)' },
        { countryCode: 'LA', value: '856', label: 'Laos (+856)' },
        { countryCode: 'LV', value: '371', label: 'Latvia (+371)' },
        { countryCode: 'LB', value: '961', label: 'Lebanon (+961)' },
        { countryCode: 'LS', value: '266', label: 'Lesotho (+266)' },
        { countryCode: 'LR', value: '231', label: 'Liberia (+231)' },
        { countryCode: 'LY', value: '218', label: 'Libya (+218)' },
        { countryCode: 'LI', value: '417', label: 'Liechtenstein (+417)' },
        { countryCode: 'LT', value: '370', label: 'Lithuania (+370)' },
        { countryCode: 'LU', value: '352', label: 'Luxembourg (+352)' },
        { countryCode: 'MO', value: '853', label: 'Macao (+853)' },
        { countryCode: 'MK', value: '389', label: 'Macedonia (+389)' },
        { countryCode: 'MG', value: '261', label: 'Madagascar (+261)' },
        { countryCode: 'MW', value: '265', label: 'Malawi (+265)' },
        { countryCode: 'MY', value: '60', label: 'Malaysia (+60)' },
        { countryCode: 'MV', value: '960', label: 'Maldives (+960)' },
        { countryCode: 'ML', value: '223', label: 'Mali (+223)' },
        { countryCode: 'MT', value: '356', label: 'Malta (+356)' },
        { countryCode: 'MH', value: '692', label: 'Marshall Islands (+692)' },
        { countryCode: 'MQ', value: '596', label: 'Martinique (+596)' },
        { countryCode: 'MR', value: '222', label: 'Mauritania (+222)' },
        { countryCode: 'YT', value: '269', label: 'Mayotte (+269)' },
        { countryCode: 'MX', value: '52', label: 'Mexico (+52)' },
        { countryCode: 'FM', value: '691', label: 'Micronesia (+691)' },
        { countryCode: 'MD', value: '373', label: 'Moldova (+373)' },
        { countryCode: 'MC', value: '377', label: 'Monaco (+377)' },
        { countryCode: 'MN', value: '976', label: 'Mongolia (+976)' },
        { countryCode: 'MS', value: '1664', label: 'Montserrat (+1664)' },
        { countryCode: 'MA', value: '212', label: 'Morocco (+212)' },
        { countryCode: 'MZ', value: '258', label: 'Mozambique (+258)' },
        { countryCode: 'MN', value: '95', label: 'Myanmar (+95)' },
        { countryCode: 'NA', value: '264', label: 'Namibia (+264)' },
        { countryCode: 'NR', value: '674', label: 'Nauru (+674)' },
        { countryCode: 'NP', value: '977', label: 'Nepal (+977)' },
        { countryCode: 'NL', value: '31', label: 'Netherlands (+31)' },
        { countryCode: 'NC', value: '687', label: 'New Caledonia (+687)' },
        { countryCode: 'NZ', value: '64', label: 'New Zealand (+64)' },
        { countryCode: 'NI', value: '505', label: 'Nicaragua (+505)' },
        { countryCode: 'NE', value: '227', label: 'Niger (+227)' },
        { countryCode: 'NG', value: '234', label: 'Nigeria (+234)' },
        { countryCode: 'NU', value: '683', label: 'Niue (+683)' },
        { countryCode: 'NF', value: '672', label: 'Norfolk Islands (+672)' },
        { countryCode: 'NP', value: '670', label: 'Northern Marianas (+670)' },
        { countryCode: 'NO', value: '47', label: 'Norway (+47)' },
        { countryCode: 'OM', value: '968', label: 'Oman (+968)' },
        { countryCode: 'PW', value: '680', label: 'Palau (+680)' },
        { countryCode: 'PA', value: '507', label: 'Panama (+507)' },
        { countryCode: 'PG', value: '675', label: 'Papua New Guinea (+675)' },
        { countryCode: 'PY', value: '595', label: 'Paraguay (+595)' },
        { countryCode: 'PE', value: '51', label: 'Peru (+51)' },
        { countryCode: 'PH', value: '63', label: 'Philippines (+63)' },
        { countryCode: 'PL', value: '48', label: 'Poland (+48)' },
        { countryCode: 'PT', value: '351', label: 'Portugal (+351)' },
        { countryCode: 'PR', value: '1787', label: 'Puerto Rico (+1787)' },
        { countryCode: 'QA', value: '974', label: 'Qatar (+974)' },
        { countryCode: 'RE', value: '262', label: 'Reunion (+262)' },
        { countryCode: 'RO', value: '40', label: 'Romania (+40)' },
        { countryCode: 'RU', value: '7', label: 'Russia (+7)' },
        { countryCode: 'RW', value: '250', label: 'Rwanda (+250)' },
        { countryCode: 'SM', value: '378', label: 'San Marino (+378)' },
        { countryCode: 'ST', value: '239', label: 'Sao Tome & Principe (+239)' },
        { countryCode: 'SA', value: '966', label: 'Saudi Arabia (+966)' },
        { countryCode: 'SN', value: '221', label: 'Senegal (+221)' },
        { countryCode: 'CS', value: '381', label: 'Serbia (+381)' },
        { countryCode: 'SC', value: '248', label: 'Seychelles (+248)' },
        { countryCode: 'SL', value: '232', label: 'Sierra Leone (+232)' },
        { countryCode: 'SG', value: '65', label: 'Singapore (+65)' },
        { countryCode: 'SX', value: '1', label: 'Sint Maarten (+1)' },
        { countryCode: 'SK', value: '421', label: 'Slovak Republic (+421)' },
        { countryCode: 'SI', value: '386', label: 'Slovenia (+386)' },
        { countryCode: 'SB', value: '677', label: 'Solomon Islands (+677)' },
        { countryCode: 'SO', value: '252', label: 'Somalia (+252)' },
        { countryCode: 'ZA', value: '27', label: 'South Africa (+27)' },
        { countryCode: 'ES', value: '34', label: 'Spain (+34)' },
        { countryCode: 'LK', value: '94', label: 'Sri Lanka (+94)' },
        { countryCode: 'SH', value: '290', label: 'St. Helena (+290)' },
        { countryCode: 'KN', value: '1869', label: 'St. Kitts (+1869)' },
        { countryCode: 'SC', value: '1758', label: 'St. Lucia (+1758)' },
        { countryCode: 'SD', value: '249', label: 'Sudan (+249)' },
        { countryCode: 'SR', value: '597', label: 'Suriname (+597)' },
        { countryCode: 'SZ', value: '268', label: 'Swaziland (+268)' },
        { countryCode: 'SE', value: '46', label: 'Sweden (+46)' },
        { countryCode: 'CH', value: '41', label: 'Switzerland (+41)' },
        { countryCode: 'SI', value: '963', label: 'Syria (+963)' },
        { countryCode: 'TW', value: '886', label: 'Taiwan (+886)' },
        { countryCode: 'TJ', value: '7', label: 'Tajikstan (+7)' },
        { countryCode: 'TH', value: '66', label: 'Thailand (+66)' },
        { countryCode: 'TG', value: '228', label: 'Togo (+228)' },
        { countryCode: 'TO', value: '676', label: 'Tonga (+676)' },
        { countryCode: 'TT', value: '1868', label: 'Trinidad & Tobago (+1868)' },
        { countryCode: 'TN', value: '216', label: 'Tunisia (+216)' },
        { countryCode: 'TR', value: '90', label: 'Turkey (+90)' },
        { countryCode: 'TM', value: '7', label: 'Turkmenistan (+7)' },
        { countryCode: 'TM', value: '993', label: 'Turkmenistan (+993)' },
        { countryCode: 'TC', value: '1649', label: 'Turks & Caicos Islands (+1649)' },
        { countryCode: 'TV', value: '688', label: 'Tuvalu (+688)' },
        { countryCode: 'UG', value: '256', label: 'Uganda (+256)' },
        { countryCode: 'UA', value: '380', label: 'Ukraine (+380)' },
        { countryCode: 'AE', value: '971', label: 'United Arab Emirates (+971)' },
        { countryCode: 'GB', value: '44', label: 'United Kingdom (+44)' },
        { countryCode: 'US', value: '1', label: 'United States of America (+1)' },
        { countryCode: 'UY', value: '598', label: 'Uruguay (+598)' },
        { countryCode: 'UZ', value: '7', label: 'Uzbekistan (+7)' },
        { countryCode: 'VU', value: '678', label: 'Vanuatu (+678)' },
        { countryCode: 'VA', value: '379', label: 'Vatican City (+379)' },
        { countryCode: 'VE', value: '58', label: 'Venezuela (+58)' },
        { countryCode: 'VN', value: '84', label: 'Vietnam (+84)' },
        { countryCode: 'VG', value: '84', label: 'Virgin Islands - British (+1284)' },
        { countryCode: 'VI', value: '84', label: 'Virgin Islands - US (+1340)' },
        { countryCode: 'WF', value: '681', label: 'Wallis and Futuna (+681)' },
        { countryCode: 'YE', value: '969', label: 'Yemen (North) (+969)' },
        { countryCode: 'YE', value: '967', label: 'Yemen (South) (+967)' },
        { countryCode: 'ZM', value: '260', label: 'Zambia (+260)' },
        { countryCode: 'ZW', value: '263', label: 'Zimbabwe (+263)' },
      ],
    };
  },
  methods: {
    GetMeLocal() {
      const sub = localStorage.getItem("sub");
      const first = localStorage.getItem("firstname");
      const last = localStorage.getItem("lastname");
      const subname = `${first} ${last}`;
      const me = { sub, subname }
      return me;
    },

    async getme() {
      this.Me = await this.DataGet('/me');
    },
  
    DataGet(url, ret = "rows", toast = false) {
      return new Promise(async (resolve) => {
        try {
          const token = localStorage.getItem("token");
          let response = await axios.get(`${this.api}${url}?token=${token}`);
          if (response.data.status === 500) {
            this.$buefy.toast.open({
              message: response.data.message,
              position: "is-bottom",
              type: "is-danger"
            });
          } else {
            if (toast) {
              this.$buefy.toast.open({
                message: response.data.message,
                position: "is-bottom",
                type: 'is-success'
              });
            }
            if (ret !== "") {
              resolve(response.data[ret]);
            } else {
              resolve(response.data);
            }
          }
        } catch (error) {
          this.DataError("DataGet", url, error);
          resolve(null);
        }
      });
    },

    DataGetRaw(url, toast = false) {
      return new Promise(async (resolve) => {
        try {
          const token = localStorage.getItem("token");
          let response = await axios.get(url);
          if (response.data.status === 500) {
            this.$buefy.toast.open({
              message: response.data.message,
              position: "is-bottom",
              type: "is-danger"
            });
          } else {
            if (toast) {
              this.$buefy.toast.open({
                message: response.data.message,
                position: "is-bottom",
                type: 'is-success'
              });
            }
            resolve(response.data);
          }
        } catch (error) {
          this.DataError("DataGetRaw", url, error);
          resolve(null);
        }
      });
    },

    DataDelete(url, ret = "", toast = false) {
      return new Promise(async (resolve) => {
        try {
          const token = localStorage.getItem("token");
          let response = await axios.delete(`${this.api}${url}?token=${token}`);
          if (response.data.status === 500) {
            this.$buefy.toast.open({
              message: response.data.message,
              position: "is-bottom",
              type: "is-danger"
            });
          } else {
            if (toast) {
              this.$buefy.toast.open({
                message: response.data.message,
                position: "is-bottom",
                type: "is-success"
              });
            }
            if (ret !== "") {
              resolve(response.data[ret]);
            } else {
              resolve(response.data);
            }
          }
        } catch (error) {
          this.DataError("DataGet", url, error);
          resolve(null);
        }
      });
    },

    DataSend(method, url, data, ret = "", toast = true) {
      return new Promise(async (resolve) => {
        try {
          const token = localStorage.getItem("token");
          // let response = await axios.post(`${this.api}${url}?token=${token}`, data);
          let response = await axios({
            method,
            url: `${this.api}${url}?token=${token}`,
            data
          });
          if (response.data.status === 500) {
            this.$buefy.toast.open({
              message: response.data.message,
              position: "is-bottom",
              type: "is-danger"
            });
          } else {
            if (toast) {
              this.$buefy.snackbar.open({ message: response.data.message, position: "is-bottom" });
            }
            if (ret !== "") {
              resolve(response.data[ret]);
            } else {
              resolve(response.data);
            }
          }
        } catch (error) {
          this.DataError("DataGet", url, error);
          resolve(null);
        }
      });
    },

    DataDemo() {
      this.$buefy.dialog.confirm({
        title: "",
        message:
        "This is demo content. You can not edit, upload to or delete it. If you do not want to see demo data anymore you can disable all demo content in the Account &amp; Settings section.",
        confirmText: "Go to Accounts & Settings",
        type: "is-danger",
        hasIcon: true,
        icon: 'exclamation',
        onConfirm: () => {
          this.$router.push({ path: `/settings/all` });
        }
      });
    },

    DataError(area, url, error) {
      const message = `Would it be ok if we send the following to support?<br/><br/>
      Function: ${area} <br/>
      End Point: ${url} <br/>
      ${error}`;
      this.$buefy.dialog.confirm({
        title: "An error has occured",
        message,
        cancelText: "Cancel",
        confirmText: "Yes, send",
        type: "is-success",
        icon: 'exclamation',
        onConfirm: () =>
          this.$buefy.toast.open({
            message: "Error sent",
            position: "is-bottom",
            type: "is-danger"
          })
      });
    },

    async checkSubscription() {
      if (localStorage.getItem("sub") === null) {
        return;
      }
      const hassub = await this.DataGet('/subscription', '');
      switch (hassub.message) {
        case 'active2':
          // maybe do some shit here later
          break;
        case 'past_due':
          // past_due
          this.$buefy.dialog.confirm({
            title: "",
            message:
            "There is an error with your subscription. It is past the due date. Please check with your bank. If you need to change your subscription please go to your account to correct it.",
            confirmText: "Go to account",
            type: "is-danger",
            hasIcon: true,
            icon: 'exclamation',
            onConfirm: () => {
              this.$router.push({ path: `/settings/all` });
            }
          });
          break;
      
        default:
          // maybe do some shit here later
          break;
      }
    },

    async getUnreadCount() {
      if (localStorage.getItem("sub") !== null) {
        const unr = await this.DataGet('/notify/unread', '');
        EventBus.$emit('unread_count', unr.rows);
        EventBus.$emit('has_active_task', unr.logs);
      }
    },

    async payNow(product) {
      this.Me = await this.DataGet('/me');
      let useFull = true;
      let useCanCancel = false;
      const inw = document.body.clientWidth;
      if (inw > 750) { useFull = false; useCanCancel = true; }
      this.modalP = Modal.open({
        parent: this,
        component: ModalPay,
        hasModalCard: true,
        fullScreen: useFull,
        canCancel: useCanCancel,
        width: 700,
        props: {
          data: product,
          inme: this.Me
        },
        events: {
          dataFromModal: value => {}
        }
      });
    },

    async getGoalSelect() {

      if (localStorage.getItem("activegoal") === null) {
        localStorage.setItem('activegoal', '');
      }

      this.ActiveGoalId = localStorage.getItem('activegoal');

      this.GoalList = [];

      const glin = await this.DataGet("/goals/select");
      const gl = glin;

      for (const g of gl) {
        // set style
        if (g.goalcolor === "") {
          g.goalcolor = "#CCCCCC";
        }
        const nr = {
          _id: g._id,
          goaltitle: g.goaltitle,
          goalcolor: g.goalcolor
        }
        this.GoalList.push(nr);
      }
      this.GoalList.unshift({
        _id: "",
        goaltitle: "Not associated with a goal",
        goalcolor: "#CCC"
      });
      this.loading = false;
    },

    setActiveGoal() {
      localStorage.setItem('activegoal', this.ActiveGoalId);
    },

    moveEach(arr, old_index, new_index) {
      if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
          arr.push(undefined);
        }
      }
      arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
      this.DataSend('post', '/tasks/sort', arr, '', false);
      return arr; // for testing
    },

    async openTaskSide(task, edit = false) {
      // this.changeTaskSelected(task, index);
      let useFull = true;
      let useCanCancel = false;
      const inw = document.body.clientWidth;
      if (inw > 750) {
        useFull = false;
        useCanCancel = false;
      }
      const that = this;
      this.modalT = Modal.open({
        parent: this,
        component: ModalTask,
        hasModalCard: true,
        fullScreen: useFull,
        canCancel: useCanCancel,
        width: 700,
        props: {
          data: task, // this is the row data that gets sent to the modal
          edit,
          callBus: "Modal_Goals_Task" // name of the listner for the data coming in from the modal
        },
        events: {
          taskUpdated: value => {
            if (value.type === "delete") {
              // delete functions
            } else if (value.type === "new") {
              // new here
            } else {
              
            }
          }
        }
      });
    },

    userCheck() {
      const infirst = localStorage.getItem("firstname");
      const inlast = localStorage.getItem("lastname");
      const user = {
        sub: localStorage.getItem("sub"),
        subname: `${infirst} ${inlast}`,
        admin: localStorage.getItem("isadmin")
      };
      return user;
    },

    editorCheck() {
      let ed = false;
      const ined = localStorage.getItem("isadmin");
      if (ined === 'true') {
        ed = true;
      }
      return ed;
    },

    WindowSize() {
      var dbod = document.body,
      dhtm = document.documentElement;
      const docheight = Math.max( dbod.scrollHeight, dbod.offsetHeight, dhtm.clientHeight, dhtm.scrollHeight, dhtm.offsetHeight );
      const docwidth = Math.max(dbod.scrollWidth, dbod.offsetWidth, dhtm.clientWidth, dhtm.scrollWidth, dhtm.offsetWidth);
      return { docheight, docwidth };
    },

    setEndOfContenteditable(contentEditableElement) {
        var range,selection;
        if(document.createRange)//Firefox, Chrome, Opera, Safari, IE 9+
        {
            range = document.createRange();//Create a range (a range is a like the selection but invisible)
            range.selectNodeContents(contentEditableElement);//Select the entire contents of the element with the range
            range.collapse(false);//collapse the range to the end point. false means collapse to end rather than the start
            selection = window.getSelection();//get the selection object (allows you to change selection)
            selection.removeAllRanges();//remove any selections already made
            selection.addRange(range);//make the range you have just created the visible selection
        }
        else if(document.selection)//IE 8 and lower
        { 
            range = document.body.createTextRange();//Create a range (a range is a like the selection but invisible)
            range.moveToElementText(contentEditableElement);//Select the entire contents of the element with the range
            range.collapse(false);//collapse the range to the end point. false means collapse to end rather than the start
            range.select();//Select the range (make it the visible selection
        }
    },

    ModalOpen(modalname, component, props, events) {
      // ModalOpen(modalname, component, props, events)
      this[modalname] = this.$buefy.modal.open({
        parent: this,
        component: component,
        hasModalCard: true,
        props: props,
        events: events
      });
      // 'newRowAdded': value => {
      //   this.Locations.push(value);
      // }
      // 'newRowAdded': value => { this.Locations.push(value); }
    },
    SchemaNew(schema) {
      return JSON.parse(JSON.stringify(schema));
    },
    getInitials(txt) {
      var initials = txt.match(/\b\w/g) || [];
      initials = (
        (initials.shift() || "") + (initials.pop() || "")
      ).toUpperCase();
      return initials;
    },
    calcDay(mins, hoursday) {
      const dailymins = hoursday * 60;
      
    },
    escapeSpecialChars(text) {
      return text
        .replace(/[\\]/g, "\\\\")
        // eslint-disable-next-line no-useless-escape
        .replace(/[\"]/g, '\\"')
        .replace(/[\/]/g, "\\/")
        .replace(/[\b]/g, "\\b")
        .replace(/[\f]/g, "\\f")
        .replace(/[\n]/g, "\\n")
        .replace(/[\r]/g, "\\r")
        .replace(/[\t]/g, "\\t");
    },
    validateEmail(email) {
      // eslint-disable-next-line no-useless-escape
      var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    getUUID(asString) {
      const id = new ObjectID();
      let idout = id;
      if (asString) {
        idout = id.str;
      }
      return idout;
    },
    getTS() {
      const newTS = moment().unix();
      return newTS;
    },
    getDateFull(indate, method, units, amount) {
      let newdt = moment(indate);
      let newutc = moment(indate).utc();
      switch (method) {
        case "add":
          newdt = moment(indate).add(amount, units);
          newutc = moment(indate)
            .add(amount, units)
            .utc();
          break;
        case "subtract":
          newdt = moment(indate).subtract(amount, units);
          newutc = moment(indate)
            .subtract(amount, units)
            .utc();
          break;

        default:
          newdt = moment(indate);
          newutc = moment(indate).utc();
          break;
      }
      //  const newst = moment(indate).startOf('day');
      // timestamp from start of day
      const tsdate = new Date(indate);
      const dtst = tsdate.setHours(0, 0, 0, 0);
      const endate = new Date(indate);
      const dtend = endate.setHours(23, 59, 59, 999);
      const dtout = {
        dtraw: newdt.format(),
        dtutc: newutc.format(),
        ts: newdt.unix(),
        tsm: newdt.valueOf(),
        tsms: dtst.valueOf(),
        tsme: dtend.valueOf(),
        db: newdt.format("YYYY-MM-DD"),
        dbtm: newdt.format("YYYY-MM-DD HH:mm:ss"),
        // year
        year: newdt.format("YYYY") * 1,
        yearshort: newdt.format("YY") * 1,
        // quarter
        quarter: newdt.format("Q") * 1,
        // month
        month: newdt.format("MM"),
        monthsingle: newdt.format("M") * 1,
        monthshort: newdt.format("MMM"),
        monthlong: newdt.format("MMMM"),
        // day
        day: newdt.format("DD"),
        daysingle: newdt.format("D") * 1,
        dayst: newdt.format("Do"), // 1st, 2nd
        dayofyear: newdt.format("DDD"),
        // week
        week: newdt.format("w") * 1,
        dayofweek: newdt.format("d") * 1,
        dayofweektxtshort: newdt.format("ddd"), // Mon, Tue
        dayofweektxtlong: newdt.format("dddd"), // Monday, Tuesday
        // time
        hour: newdt.format("HH"),
        hoursingle: newdt.format("HH") * 1,
        minute: newdt.format("mm"),
        minutesingle: newdt.format("m") * 1,
        second: newdt.format("ss"),
        secondsingle: newdt.format("ss") * 1,
        milli: newdt.format("SSS")
      };
      return dtout;
    },

    addDays(date, days) {
      var nwdt = new Date(date);
      nwdt.setDate(nwdt.getDate() + days);
      const dayno = nwdt.getDay();
      let retobj = {
        dt: nwdt,
        year: nwdt.getFullYear(),
        month: nwdt.getMonth() + 1,
        day: nwdt.getDate(),
        dayno: nwdt.getDay(),
        daytext: ""
      };
      if (dayno === 0) {
        retobj.daytext = "sunday";
      }
      if (dayno === 1) {
        retobj.daytext = "monday";
      }
      if (dayno === 2) {
        retobj.daytext = "tuesday";
      }
      if (dayno === 3) {
        retobj.daytext = "wednesday";
      }
      if (dayno === 4) {
        retobj.daytext = "thursday";
      }
      if (dayno === 5) {
        retobj.daytext = "friday";
      }
      if (dayno === 6) {
        retobj.daytext = "saturday";
      }
      return retobj;
    },

    assignValueToFields(fields, data) {
      for (const f of fields) {
        switch (f.type) {
          case "time":
            f.hour = data[f.field].hour;
            f.minutes = data[f.field].minutes;
            f.timefull = data[f.field].timefull;
            break;
          case "duration":
            f.hour = data[f.field].hour;
            f.minutes = data[f.field].minutes;
            f.blocks = data[f.field].blocks;
            break;
          case "heading":
            // f.value = data[f.field];
            break;
          default:
            f.value = data[f.field];
            break;
        }
        if (f.conditionkey) {
          if (fields[f.conditionkey].value === f.conditionvalue) {
            f.show = true;
          } else {
            f.show = false;
          }
        }
      }
      return fields;
    },

    assignFieldsToValue(indata, newrow) {
      for (const row of indata) {
        switch (row.type) {
          case "time":
            newrow[row.field].hour = row.hour;
            newrow[row.field].minutes = row.minutes;
            newrow[row.field].timefull = row.timefull;
            break;
          case "duration":
            newrow[row.field].hour = row.hour;
            newrow[row.field].minutes = row.minutes;
            newrow[row.field].blocks = row.blocks;
            break;

          default:
            newrow[row.field] = row.value;
            break;
        }
      }
      return newrow;
    },

    secondsToHours(inbound) {
      var sec_num = parseInt(inbound, 10); // don't forget the second param
      var hours = Math.floor(sec_num / 3600);
      var minutes = Math.floor((sec_num - hours * 3600) / 60);
      var seconds = sec_num - hours * 3600 - minutes * 60;

      // if (hours   < 10) {hours   = "0"+hours;}
      // if (minutes < 10) {minutes = "0"+minutes;}
      // if (seconds < 10) {seconds = "0"+seconds;}
      // return hours+':'+minutes+':'+seconds;
      return { hours, minutes, seconds };
    },

    exportToCsv(filename, rows) {
      var processRow = function(row) {
        var finalVal = "";
        for (var j = 0; j < row.length; j++) {
          var innerValue = row[j] === null ? "" : row[j].toString();
          if (row[j] instanceof Date) {
            innerValue = row[j].toLocaleString();
          }
          var result = innerValue.replace(/"/g, '""');
          if (result.search(/("|,|\n)/g) >= 0) result = '"' + result + '"';
          if (j > 0) finalVal += ",";
          finalVal += result;
        }
        return finalVal + "\n";
      };

      var csvFile = "";
      for (var i = 0; i < rows.length; i++) {
        csvFile += processRow(rows[i]);
      }

      var blob = new Blob([csvFile], { type: "text/csv;charset=utf-8;" });
      if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, filename);
      } else {
        var link = document.createElement("a");
        if (link.download !== undefined) {
          // feature detection
          // Browsers that support HTML5 download attribute
          var url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", filename);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    },

    txtformat(value) {
      return value.replace(/\n/g, "<br />");
    },

    copyObj(obj) {
      const newobj = JSON.parse(JSON.stringify(obj));
      return newobj;
    },

    uuidSM(pre,post) {
      const nu = (+new Date()).toString(36);
      return pre + nu + post;
    },

    scrollToBot(id, adjustment) {
      var objDiv = document.getElementById(id);
      let scrollHeightIn = objDiv.scrollHeight + adjustment;
      objDiv.scrollTop = scrollHeightIn;
    },
    dateFormatted(value) {
      const outdate = moment(value).format("D MMMM YYYY");
      return outdate;
    },

  },
  filters: {
    
    displaydate(value, f) {
      const outdate = moment(value).format(f);
      return outdate;
    },
    displaydateTS(value, f) {
      const outdate = moment.unix(value).format(f);
      return outdate;
    },
    displaydateMY(value) {
      const outdate = moment(value).format("MMMM YYYY");
      return outdate;
    },
    displaydateFull(value) {
      const outdate = moment(value).format("D MMMM YYYY");
      return outdate;
    },
    displaydateFullTm(value) {
      const outdate = moment(value).format("D MMMM YYYY H:m");
      return outdate;
    },
    decimalStr(value) {
      let newno = value.toFixed(2);
      return newno;
    },
    numberStr(value, pd, pos) {
      let newno = value.toString();
      if (pos === "pre") {
        if (newno.length === 1) {
          newno = `${pd}${value}`;
        }
      }
      if (pos === "post") {
        if (newno.length === 1) {
          newno = `${value}${pd}`;
        }
      }
      return newno;
    },
    shorten(value, amount=200) {
      let newnval = value.substring(0,amount);
      
      return newnval;
    },
  },
};