import Vue from "vue";
import VueRouter from 'vue-router'
import Home from "./views/Home.vue";
// import Auth from './views/Auth';
import Login from './views/Login';
import Signup from './views/Signup';

const routes = [
  {
    path: '/',
    name: 'home', component: Home,
    meta: { requiresAuth: true }
  },
  {
    path: "/tasks",
    name: "tasks",
    component: () => import("./views/Tasks.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/teams",
    name: "teams",
    component: () => import("./views/Teams.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/people",
    name: "people",
    component: () => import("./views/People.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/goals",
    name: "goals",
    component: () => import("./views/Goals.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/work",
    name: "work",
    component: () => import("./views/Work.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/blueprints",
    name: "blueprints",
    component: () => import("./views/Blueprints.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/mindmaps",
    name: "mindmaps",
    component: () => import("./views/Mindmaps.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/trees",
    name: "trees",
    component: () => import("./views/Trees.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/peed",
    name: "peed",
    component: () => import("./views/Peed.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/docs",
    name: "docs",
    component: () => import("./views/Docs.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/notes",
    name: "notes",
    component: () => import("./views/Notes.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/compare",
    name: "compare",
    component: () => import("./views/Compare.vue"),
    meta: { requiresAuth: true }
  },
  // {
  //   path: "/settings",
  //   name: "settings",
  //   component: () => import("./views/Settings.vue"),
  //   meta: { requiresAuth: true }
  // },
  {
    path: "/settings/:page",
    name: "settings",
    component: () => import("./views/Settings.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/:area/:id",
    name: "profile",
    component: () => import("./views/Profile.vue"),
    // meta: { requiresAuth: true }
  },
  {
    path: "/account",
    name: "account",
    component: () => import("./views/Account.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/inbox",
    name: "inbox",
    component: () => import("./views/Inbox.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/network",
    name: "network",
    component: () => import("./views/Network.vue"),
    // meta: { requiresAuth: true }
  },
  {
    path: "/share/:area/:id",
    name: "share",
    component: () => import("./views/Share.vue"),
    // meta: { requiresAuth: true }
  },
  { path: '/login', name: "login", component: Login },
  { path: '/signup', name: "signup", component: Signup },
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
})

router.beforeResolve((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    let user;
    if (localStorage.getItem("token") === null) {
      next({
        path: '/login'
      });
    } else {
      next();
    }
  }
  next()
})

export default router

// export default new Router({
//   mode: "history",
//   base: process.env.BASE_URL,
//   routes: [
//     {
//       path: "/",
//       name: "home",
//       component: Home
//     },
//     {
//       path: "/about",
//       name: "about",
//       // route level code-splitting
//       // this generates a separate chunk (about.[hash].js) for this route
//       // which is lazy-loaded when the route is visited.
//       component: () =>
//         import(/* webpackChunkName: "about" */ "./views/About.vue")
//     },
//     {
//       path: "/auth",
//       name: "auth",
//       component: () =>
//         import(/* webpackChunkName: "about" */ "./views/Auth.vue")
//     },
//   ]
// });
